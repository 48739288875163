/* ------------------------------------------------------------------------------
@name: ReportSales
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";

// --- utilities
import { DataTable, FormatDate, Currency } from "utilities";

const ReportSales = (() => {
	// handleRunTableSalesPeriode
	const handleRunTableSalesPeriode = () => {
		const dataSetting = {
			selector: "js-report-sales-periode",
			url: API_URL.salesPeriodeGet,
			method: "POST",
			data: (d) => {
				const _date = $("#dateRange").val();
				const _startDate = FormatDate.range(_date).startDate;
				const _endDate = FormatDate.range(_date).endDate;
				return $.extend({}, d, {
					startDate: _startDate,
					endDate: _endDate,
				});
			},
		};

		const filterSetting = [
			{
				id: "dateRange",
				event: "change",
			},
		];

		const columnSetting = [
			{
				targets: [0, 6, 4],
				className: "text-center",
			},
			{
				targets: [2, 3, 5],
				className: "text-right",
			},
		];

		DataTable.custom(dataSetting, filterSetting, columnSetting);
	};

	// handleRunTableSalesPeriode
	const handleRunTableSalesPatient = () => {
		const dataSetting = {
			selector: "js-report-sales-patient",
			url: API_URL.salesPatientGet,
			method: "POST",
			data: (d) => {
				const _date = $("#dateRange").val();
				const _startDate = FormatDate.range(_date).startDate;
				const _endDate = FormatDate.range(_date).endDate;
				return $.extend({}, d, {
					startDate: _startDate,
					endDate: _endDate,
				});
			},
		};

		const filterSetting = [
			{
				id: "dateRange",
				event: "change",
			},
		];

		const columnSetting = [
			{
				targets: [0, 5, 7],
				className: "text-center",
			},
			{
				targets: [3, 4, 6],
				className: "text-right",
			},
		];

		DataTable.custom(dataSetting, filterSetting, columnSetting);
	};

	// handleSummarySalesPeriode
	const handleSummarySalesPeriode = () => {
		const _date = $("#dateRange").val();
		const _startDate = FormatDate.range(_date).startDate;
		const _endDate = FormatDate.range(_date).endDate;
		$.ajax({
			url: API_URL.salesSummaryGet,
			method: "POST",
			dataType: "JSON",
			data: {
				startDate: _startDate,
				endDate: _endDate,
			},
			beforeSend: function () {
				$(".js-summary-sales-periode").find(".card-box").addClass("skeleton");
			},
			success: (response) => {
				const _data = response.data;

				$(".js-total-omset").text(
					_data.omset ? Currency.format_rp(_data.omset) : 0
				);
				$(".js-total-hpp").text(_data.hpp ? Currency.format_rp(_data.hpp) : 0);
				$(".js-total-profit").text(
					_data.profit ? Currency.format_rp(_data.profit) : 0
				);
				$(".js-total-transaction").text(
					_data.transaction ? _data.transaction : 0
				);

				$(".js-summary-sales-periode")
					.find(".card-box")
					.removeClass("skeleton");
			},
		});
	};

	// handleFilterDate
	const handleFilterDate = () => {
		$("body").on("change", ".js-filter-sales", () => {
			handleSummarySalesPeriode();
		});
	};

	// init
	const init = () => {
		if ($(".js-report-sales-periode").length) {
			handleRunTableSalesPeriode();
		}
		if ($(".js-report-sales-patient").length) {
			handleRunTableSalesPatient();
		}
		if ($(".js-filter-sales").length) {
			handleFilterDate();
		}
		if ($(".js-summary-sales").length) {
			handleSummarySalesPeriode();
		}
	};

	return {
		init,
	};
})();

export default ReportSales;
