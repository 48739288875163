/* ------------------------------------------------------------------------------
@name: DatePickerInput
@description: DatePickerInput Activate
--------------------------------------------------------------------------------- */

// --- DatePickerInput
const DatePickerInput = (() => {
	// handlRunDatePicker
	const handlRunDatePicker = () => {
		// datepicker default
		$(".js-date-picker").val(moment().format("D/MM/YYYY"));
		$(".js-date-picker").datepicker({
			format: "dd/mm/yyyy",
			autoclose: true,
			todayHighlight: true,
		});
	};

	const handleTimePicker = () => {
		$(".js-timepicker").timepicker({
			showMeridian: false,
			icons: {
				up: "mdi mdi-chevron-up",
				down: "mdi mdi-chevron-down",
			},
		});
	};

	// handldeDateRengePicker
	const handldeDateRengePicker = () => {
		let _days = 29;
		if ($(".js-date-range-picker").attr("data-range") !== undefined) {
			_days = $(".js-date-range-picker").attr("data-range");
		}
		const start = moment().subtract(_days, "days");
		const end = moment();

		const cb = (start, end) => {
			$(".js-date-range-picker").val(
				start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY")
			);
		};

		$(".js-date-range-picker").daterangepicker(
			{
				// timePicker: true,
				startDate: start,
				endDate: end,
				locale: {
					format: "DD/MM/YYYY",
					separator: " - ",
				},
				ranges: {
					"Hari ini": [moment(), moment()],
					Kemarin: [moment().subtract(1, "days"), moment().subtract(1, "days")],
					"7 hari terakhir": [moment().subtract(6, "days"), moment()],
					"30 hari terakhir": [moment().subtract(29, "days"), moment()],
					"Bulan ini": [moment().startOf("month"), moment().endOf("month")],
					"Bulan lalu": [
						moment().subtract(1, "month").startOf("month"),
						moment().subtract(1, "month").endOf("month"),
					],
					"Tahun ini": [moment().startOf("year"), moment().endOf("year")],
					"Tahun lalu": [
						moment().subtract(1, "year").startOf("year"),
						moment().subtract(1, "year").endOf("year"),
					],
				},
			},
			cb
		);

		cb(start, end);
		var _dateRange = $(".js-date-range-picker").val();
		$(".js-date-range-picker").attr("data-date", _dateRange);
	};

	const init = () => {
		if (
			$(".js-date-picker").length ||
			$(".js-date-range-picker").length ||
			$(".js-timepicker").length
		) {
			handlRunDatePicker();
			handldeDateRengePicker();
			handleTimePicker();
		}
	};

	// --- return
	return {
		init,
	};
})();

export default DatePickerInput;
