/* ------------------------------------------------------------------------------
@name: Region
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";

// --- utilities
import { HttpRequest } from "utilities";

const Region = (() => {
	// handleGetProvince
	const handleGetProvince = () => {
		const data = {
			url: API_URL.province,
			method: "POST",
		};
		const response = HttpRequest.custom(data);
		if (response.code === 200) {
			let content = "<option>Pilih Provinsi</option>";
			if (response.data.length) {
				$.each(response.data, (i, v) => {
					content += `<option value="${v.provinceId}">${v.name}</option>`;
				});
			}
			$(".js-region-province").html(content);
		}
	};

	// handleSelectProvince
	const handleSelectProvince = () => {
		$(".js-region-province").on("change", (e) => {
			const _this = $(e.currentTarget);
			let provinceId = _this.val();
			handleGetCity(provinceId);
		});
	};

	// handleGetCity
	const handleGetCity = (provinceId) => {
		let formData = new FormData();
		formData.append("provinceId", provinceId);
		const data = {
			url: API_URL.city,
			method: "POST",
			data: formData,
		};

		const response = HttpRequest.custom(data);
		if (response.code === 200) {
			let content = "<option>Pilih Kota</option>";
			if (response.data.length) {
				$.each(response.data, (i, v) => {
					content += `<option value="${v.cityId}">${v.name}</option>`;
				});
			}
			$(".js-region-city").html(content);
		}
	};

	// handleSelectCity
	const handleSelectCity = () => {
		$(".js-region-city").on("change", (e) => {
			const _this = $(e.currentTarget);
			let cityId = _this.val();
			handleGetDistrict(cityId);
		});
	};

	// handleGetDistrict
	const handleGetDistrict = (cityId) => {
		let formData = new FormData();
		formData.append("cityId", cityId);
		const data = {
			url: API_URL.district,
			method: "POST",
			data: formData,
		};

		const response = HttpRequest.custom(data);
		if (response.code === 200) {
			let content = "<option>Pilih Kecamatan</option>";
			if (response.data.length) {
				$.each(response.data, (i, v) => {
					content += `<option value="${v.districtId}">${v.name}</option>`;
				});
			}
			$(".js-region-district").html(content);
		}
	};

	// handleSelectDistrict
	const handleSelectDistrict = () => {
		$(".js-region-district").on("change", (e) => {
			const _this = $(e.currentTarget);
			let districtId = _this.val();
			handleGetVillage(districtId);
		});
	};

	// handleGetVillage
	const handleGetVillage = (districtId) => {
		let formData = new FormData();
		formData.append("districtId", districtId);
		const data = {
			url: API_URL.village,
			method: "POST",
			data: formData,
		};

		const response = HttpRequest.custom(data);
		if (response.code === 200) {
			let content = "<option>Pilih Desa</option>";
			if (response.data.length) {
				$.each(response.data, (i, v) => {
					content += `<option value="${v.villageId}">${v.name}</option>`;
				});
			}
			$(".js-region-village").html(content);
		}
	};

	// init
	const init = () => {
		if (
			$(".js-region-province").length ||
			$(".js-region-city").length ||
			$(".js-region-district").length
		) {
			handleGetProvince();
			handleSelectProvince();
			handleSelectCity();
			handleSelectDistrict();
		}
	};

	return {
		init,
	};
})();

export default Region;
