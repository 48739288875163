/* ------------------------------------------------------------------------------
@name: Account
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";

// --- utilities
import { DataTable, Form, HttpRequest } from "utilities";

// Form Validation
const ElementSelector = [
	{
		id: "accountId",
	},
	{
		id: "name",
		validation: {
			required: true,
			email: true,
		},
	},
	{
		id: "email",
		validation: {
			required: true,
			email: true,
			invalid: true,
		},
	},
	{
		id: "phone",
		validation: {
			required: true,
			phone: true,
			invalid: true,
		},
	},
	{
		id: "role",
		type: "select",
		validation: {
			required: true,
		},
	},
	{
		id: "password",
		validation: {
			required: true,
			minimum: true,
			minimumChar: 5,
		},
	},
	{
		id: "photo",
		type: "file",
	},
	{
		id: "status",
		type: "checkbox",
	},
];

const ElementEvents = ["input", "blur"];

const Account = (() => {
	// Handle Run DataTable
	const handleRunDataTable = () => {
		const dataSetting = {
			selector: "js-data-account",
			url: API_URL.accountGet,
			method: "POST",
		};

		const columnSetting = [
			{
				targets: 1,
				className: "text-center",
				render: (dataSetting) => {
					return `<span class="user-avatar">
										<img class="user-avatar__img" src="${dataSetting}" />
									</span>`;
				},
			},
			{
				targets: 5,
				className: "text-center",
			},
			{
				targets: 6,
				className: "text-center",
			},
			{
				targets: 7,
				className: "text-center",
				render: (dataSetting) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Edit" class="btn btn-icon waves-effect btn-primary btn-trans js-edit-data" data-id="${dataSetting}"><i class="mdi mdi-pencil-outline"></i></button>
					<button type="button" data-toggle="tooltip" data-placement="left" title="Delete" class="btn btn-icon waves-effect btn-danger btn-trans js-delete-data" data-id="${dataSetting}"><i class="mdi mdi-trash-can-outline"></i></button>`;
				},
			},
		];

		DataTable.default(dataSetting, columnSetting);
	};

	// Handle Run Validation
	const handleRunValidation = () => {
		Form.validation(ElementEvents, ElementSelector);
	};

	// Handle Click Validation
	const handleClickValidation = () => {
		$('button[type="submit"]').on("click", (e) => {
			$.each(ElementSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".error").length === 0) {
				handlePostData();
			}
			e.preventDefault();
		});
	};

	// handleEmptyInput
	const handleEmptyInput = () => {
		$('button[data-dismiss="modal"]').on("click", (e) => {
			Form.emptyData(ElementSelector);
		});
	};

	// handlePostData
	const handlePostData = () => {
		const formData = Form.dataColletion(ElementSelector);
		let apiUrl = API_URL.accountAdd;
		if ($("#accountId").val().length !== 0) {
			apiUrl = API_URL.accountEdit;
		}

		const data = {
			url: apiUrl,
			method: "POST",
			data: formData,
		};

		HttpRequest.default(data);
		Form.emptyData(ElementSelector);
	};

	const handleGetFormData = () => {
		$("body").on("click", ".js-edit-data", (e) => {
			const _this = $(e.currentTarget);
			let formData = new FormData();
			const accountId = _this.attr("data-id");
			formData.append("accountId", accountId);

			const data = {
				url: API_URL.accountGet,
				method: "POST",
				data: formData,
				elementSelector: ElementSelector,
			};

			Form.getFormData(data);
		});
	};

	// handleDeleteData
	const handleDeleteData = () => {
		$("body").on("click", ".js-delete-data", (e) => {
			const _this = $(e.currentTarget);
			let formData = new FormData();
			const accountId = _this.attr("data-id");
			formData.append("accountId", accountId);

			const data = {
				url: API_URL.accountDelete,
				method: "POST",
				data: formData,
			};

			Form.deleteData(data);
		});
	};

	// init
	const init = () => {
		if ($(".js-data-account").length) {
			handleRunDataTable();
			handleDeleteData();
			handleGetFormData();
		}
		if ($(".js-form-account").length) {
			handleRunValidation();
			handleClickValidation();
			handleEmptyInput();
		}
	};

	return {
		init,
	};
})();

export default Account;
