/* ------------------------------------------------------------------------------
@name: FinancialRecord
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";

// --- utilities
import { DataTable, Form, HttpRequest, FormatDate } from "utilities";

// Form Validation
const ElementSelector = [
	{
		id: "financialRecordId",
	},
	{
		id: "financialRecordCode",
		validation: {
			required: true,
			selectOption: true,
		},
	},
	{
		id: "financialRecordDate",
		validation: {
			required: true,
			selectOption: true,
		},
	},
	{
		id: "type",
		validation: {
			required: true,
			selectOption: true,
		},
	},
	{
		id: "category",
		validation: {
			required: true,
		},
	},
	{
		id: "total",
		validation: {
			required: true,
		},
	},
	{
		id: "note",
	},
];

const ElementEvents = ["input", "blur"];

const FinancialRecord = (() => {
	// Handle Run DataTable
	const handleRunDataTable = () => {
		const dataSetting = {
			selector: "js-data-financial-record",
			url: API_URL.financialRecordGet,
			method: "POST",
			data: (d) => {
				const _date = $("#dateRange").val();
				const _startDate = FormatDate.range(_date).startDate;
				const _endDate = FormatDate.range(_date).endDate;

				return $.extend({}, d, {
					startDate: _startDate,
					endDate: _endDate,
					type: $("#financialType").val(),
				});
			},
		};

		const columnSetting = [
			{
				targets: [0, 6],
				className: "text-center",
			},
			{
				targets: 1,
				render: (data) => {
					return `<p class="mb-0">${data.id}</p><p class="mb-0 text-muted">${data.date}</p>`;
				},
			},
			{
				targets: 6,
				className: "text-center",
				render: function (data) {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Edit" class="btn btn-icon waves-effect btn-primary btn-trans js-edit-data" data-id="${data.id}" data-code="${data.code}"><i class="mdi mdi-pencil-outline"></i></button>
					<button type="button" data-toggle="tooltip" data-placement="left" title="Delete" class="btn btn-icon waves-effect btn-danger btn-trans js-delete-data" data-id="${data.id}" data-code="${data.code}"><i class="mdi mdi-trash-can-outline"></i></button>`;
				},
			},
		];

		const filterSetting = [
			{
				id: "dateRange",
				event: "change",
			},
			{
				id: "financialType",
				event: "change",
			},
			{
				id: "search",
				event: "keyup",
			},
		];

		const sortSetting = {
			id: "sort",
			event: "change",
		};

		DataTable.custom(dataSetting, filterSetting, columnSetting, sortSetting);
	};

	// Handle Run Validation
	const handleRunValidation = () => {
		Form.validation(ElementEvents, ElementSelector);
	};

	// Handle Click Validation
	const handleClickValidation = () => {
		$('.js-form-financial button[type="submit"]').on("click", (e) => {
			$.each(ElementSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".error").length === 0) {
				handlePostData();
			}
			e.preventDefault();
		});
	};

	// handleEmptyInput
	const handleEmptyInput = () => {
		$('button[data-dismiss="modal"]').on("click", (e) => {
			Form.emptyData(ElementSelector);
		});
	};
	const handleShowModal = () => {
		$('button[data-target="#modalFinancialRecord"]').on("click", (e) => {
			$(".js-date-picker").val(moment().format("D/MM/YYYY"));
			handleGetCodeTransaction();
		});
	};

	// handlePostData
	const handlePostData = () => {
		const financialDate = FormatDate.dash($("#financialRecordDate").val());
		let formData = Form.dataColletion(ElementSelector);
		formData.append("financialRecordDate", financialDate);

		let apiUrl = API_URL.financialRecordAdd;
		if ($("#financialRecordId").val().length !== 0) {
			apiUrl = API_URL.financialRecordEdit;
		}

		const data = {
			url: apiUrl,
			method: "POST",
			data: formData,
		};

		HttpRequest.default(data);
		Form.emptyData(ElementSelector);
	};

	const handleGetFormData = () => {
		$("body").on("click", ".js-edit-data", (e) => {
			const formData = new FormData();
			const _this = $(e.currentTarget);
			const financialRecordId = _this.attr("data-id");
			const financialRecordCode = _this.attr("data-code");
			formData.append("financialRecordId", financialRecordId);
			formData.append("financialRecordCode", financialRecordCode);

			const data = {
				url: API_URL.financialRecordGet,
				method: "POST",
				data: formData,
				elementSelector: ElementSelector,
			};

			Form.getFormData(data);
		});
	};

	// handleDeleteData
	const handleDeleteData = () => {
		$("body").on("click", ".js-delete-data", (e) => {
			const formData = new FormData();
			const _this = $(e.currentTarget);
			const financialRecordId = _this.attr("data-id");
			const financialRecordCode = _this.attr("data-code");
			formData.append("financialRecordId", financialRecordId);
			formData.append("financialRecordCode", financialRecordCode);

			const data = {
				url: API_URL.financialRecordDelete,
				method: "POST",
				data: formData,
			};

			Form.deleteData(data);
		});
	};

	// handleGetCodeTransaction
	const handleGetCodeTransaction = () => {
		const financialRecordDate = FormatDate.dash(
			$("#financialRecordDate").val()
		);

		$.ajax({
			url: API_URL.financialRecordCode,
			method: "POST",
			dataType: "JSON",
			data: {
				financialRecordDate: financialRecordDate,
			},
			success: (response) => {
				const _data = response.data;
				$("#financialRecordCode").val(_data);
				$("#financialRecordCode").attr("value", _data);
			},
		});
	};

	// handleChangeCategory
	const handleChangeCategory = () => {
		$(".js-change-financial-category").on("change", (e) => {
			handleGetCategory();
		});
	};

	// handleGetCategory
	const handleGetCategory = () => {
		const _type = $("#type").find(":selected").val();

		$.ajax({
			url: API_URL.financialCategoryGet,
			method: "POST",
			dataType: "JSON",
			data: {
				type: _type,
			},
			success: (response) => {
				const _data = response.data;
				let _content = `<li class="form-dropdown-item"><span class="form-dropdown-select" data-value="">Pilih Kategori</span></li>`;
				$.each(_data, (i, v) => {
					_content += `<li class="form-dropdown-item">
												<span class="form-dropdown-select" data-value="${v.name}">${v.name}</span>
												<button type="button" class="form-dropdown-remove" data-id="${v.categoryId}">
													<i class="mdi mdi-trash-can-outline"></i>
												</button>
											</li>`;
				});
				$(".js-financial-category").html(_content);

				let _contentST = `<option value="0">Pilih Kategori</option>`;
				$.each(_data, (i, v) => {
					_contentST += `<option value="${v.name}">${v.name}</option>`;
				});

				$("#category").html(_contentST);
			},
		});
	};

	// handleAddCategory
	const handleAddCategory = () => {
		$("body").on("click", ".js-financial-category .form-dropdown-btn", (e) => {
			const _type = $("#type").find(":selected").val();
			let formData = new FormData();
			formData.append("type", _type);
			formData.append("category", $("#category").val());

			const data = {
				url: API_URL.financialCategoryAdd,
				method: "POST",
				data: formData,
			};

			const beforeSend = () => {
				const _loader = `<div class="spinner-border text-custom m-2" role="status"><span class="sr-only">Loading...</span></div>`;
				$(".js-financial-category .form-dropdown-notif").html(_loader);
			};

			const response = HttpRequest.custom(data, beforeSend);

			if (response.code === 200) {
				$(".js-financial-category .form-dropdown-notif").remove();
				$(".js-financial-category")
					.parent()
					.find(".form-dropdown-input")
					.val("");

				handleGetCategory();
			} else {
				SweetAlert.config(response.message, "error");
			}
		});
	};

	// handleDeleteCategory
	const handleDeleteCategory = () => {
		$("body").on(
			"click",
			".js-financial-category .form-dropdown-remove",
			(e) => {
				const _this = $(e.currentTarget);
				const categoryId = _this.attr("data-id");
				let formData = new FormData();
				formData.append("categoryId", categoryId);

				const data = {
					url: API_URL.financialCategoryDelete,
					method: "POST",
					data: formData,
				};

				const response = HttpRequest.custom(data);

				if (response.code === 200) {
					$(".js-financial-category")
						.parent()
						.find(".form-dropdown-input")
						.val("");

					handleGetCategory();
				} else {
					SweetAlert.config(response.message, "error");
				}
			}
		);
	};

	// init
	const init = () => {
		if ($(".js-data-financial-record").length) {
			handleRunDataTable();
			handleDeleteData();
			handleGetFormData();
			handleShowModal();
		}
		if ($(".js-form-financial").length) {
			handleRunValidation();
			handleClickValidation();
			handleEmptyInput();
			handleGetCategory();
			handleAddCategory();
			handleDeleteCategory();
			handleChangeCategory();
		}
	};

	return {
		init,
	};
})();

export default FinancialRecord;
