/* ------------------------------------------------------------------------------
@name: EditableTable
@description: EditableTable Activate
--------------------------------------------------------------------------------- */

const EditableTable = (() => {
	// Handle Run DataTable
	const handleRunTable = () => {
		$(".js-editable-table").editableTableWidget();
	};

	// init
	const init = () => {
		handleRunTable();
	};

	return {
		init,
	};
})();

export default EditableTable;
