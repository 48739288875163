/* ------------------------------------------------------------------------------
@name: Account
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";

// --- utilities
import { Form, HttpRequest } from "utilities";

// Form Validation
const ChangePasswordSelector = [
	{
		id: "profileId",
	},
	{
		id: "password",
		validation: {
			required: true,
			minimum: true,
			minimumChar: 5,
		},
	},
	{
		id: "confirm_password",
		validation: {
			required: true,
			confirmPassword: true,
		},
	},
];

const ElementSelector = [
	{
		id: "accountId",
	},
	{
		id: "name",
		validation: {
			required: true,
			email: true,
		},
	},
	{
		id: "email",
		validation: {
			required: true,
			email: true,
			invalid: true,
		},
	},
	{
		id: "phone",
		validation: {
			required: true,
			phone: true,
			invalid: true,
		},
	},
	{
		id: "photo",
		type: "file",
	},
];

const ElementEvents = ["input", "blur"];

const Profile = (() => {
	// Handle Run Validation
	const handleRunValidation = () => {
		Form.validation(ElementEvents, ElementSelector);
	};

	// Handle Click Validation
	const handleClickValidation = () => {
		$('.js-form-profile button[type="submit"]').on("click", (e) => {
			$.each(ElementSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".error").length === 0) {
				handlePostData();
			}
			e.preventDefault();
		});
	};

	// Handle Run change Password
	const handleRunChangePassword = () => {
		Form.validation(ElementEvents, ChangePasswordSelector);
	};

	// Handle Click change Password
	const handleClickChangePassword = () => {
		$('.js-form-change-password button[type="submit"]').on("click", (e) => {
			$.each(ChangePasswordSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".error").length === 0) {
				handlePostChangePassword();
			}
			e.preventDefault();
		});
	};

	// handlePostData
	const handlePostData = () => {
		const formData = Form.dataColletion(ElementSelector);
		const apiUrl = API_URL.profileEdit;

		const data = {
			url: apiUrl,
			method: "POST",
			data: formData,
		};

		HttpRequest.default(data);
		handleGetProfile();
	};

	// handlePostChangePassword
	const handlePostChangePassword = () => {
		const formData = Form.dataColletion(ChangePasswordSelector);
		const apiUrl = API_URL.changePassword;

		const data = {
			url: apiUrl,
			method: "POST",
			data: formData,
		};

		HttpRequest.default(data);
	};

	// handleGetProfile
	const handleGetProfile = () => {
		const _accountId = $(".js-profile").attr("data-id");
		$.ajax({
			url: API_URL.accountGet,
			method: "POST",
			dataType: "JSON",
			data: {
				accountId: _accountId,
			},
			success: (response) => {
				const _data = response.data;
				let _status = `<span data-toggle="tooltip" data-placement="left" title="Aktif" class="badge badge-success"><i class="mdi mdi-power"></i></span>`;
				if (_status === "0") {
					_status = `<span data-toggle="tooltip" data-placement="left" title="Aktif" class="badge badge-danger"><i class="mdi mdi-power"></i></span>`;
				}
				$(".js-photo").attr("src", _data.photo);
				$(".js-name").text(_data.name);
				$(".js-email").text(_data.email);
				$(".js-phone").text(_data.phone);
				$(".js-createdDate").text(_data.createdDate);
				$(".js-status").html(_status);
			},
		});
	};

	const handleGetFormData = () => {
		$(".js-edit-profile").on("click", (e) => {
			let formData = new FormData();
			const _this = $(e.currentTarget);
			const _accountId = _this.attr("data-id");
			formData.append("accountId", _accountId);

			const data = {
				url: API_URL.accountGet,
				method: "POST",
				data: formData,
				elementSelector: ElementSelector,
			};

			Form.getFormData(data);
		});
	};

	// init
	const init = () => {
		if ($(".js-profile").length) {
			handleGetProfile();
			handleGetFormData();
		}
		if ($(".js-form-change-password").length) {
			handleRunChangePassword();
			handleClickChangePassword();
		}
		if ($(".js-form-profile").length) {
			handleRunValidation();
			handleClickValidation();
		}
	};

	return {
		init,
	};
})();

export default Profile;
