/* ------------------------------------------------------------------------------
@name: Dashboard
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL, WEB_URL } from "variables";

// --- utilities
import { FormatDate } from "utilities";

const Dashboard = (() => {
	const handleFilterDate = () => {
		$("body").on("change", ".js-filter-date", () => {
			handleGetDataSummary();
			handleGetDataDiagnosa();
		});
	};

	const handleGetDataSummary = () => {
		const _date = $("#dateRange").val();
		const _startDate = FormatDate.range(_date).startDate;
		const _endDate = FormatDate.range(_date).endDate;
		$.ajax({
			url: API_URL.dashboardGet,
			method: "POST",
			dataType: "JSON",
			data: {
				startDate: _startDate,
				endDate: _endDate,
			},
			beforeSend: function () {
				$(".card-box").addClass("skeleton");
			},
			success: (response) => {
				const _data = response.data;
				const _summary = _data.summary;
				const _chart = _data.chart;
				const _destination = _data.destination;
				const _inventory = _data.inventory;

				$(".js-total-registred").text(
					_summary.totalRegistred ? _summary.totalRegistred : 0
				);
				$(".js-total-notserved").text(
					_summary.totalNotServed ? _summary.totalNotServed : 0
				);
				$(".js-total-served").text(
					_summary.totalServed ? _summary.totalServed : 0
				);

				handleRunChart(_chart);
				handleGetDataDestination(_destination);
				handleGetDataInventory(_inventory);

				$(".card-box").removeClass("skeleton");
			},
		});
	};

	const handleRunChart = (response) => {
		$(".js-chart-not-found").find(".dashboard-not-found").remove();
		$("#morris-line").empty();

		let _convertData = [];
		let _totalData = 0;
		$.each(response[0], (key, val) => {
			_totalData += val;
			_convertData.push({
				y: key,
				a: parseFloat(val),
			});
		});

		if (_totalData > 0) {
			//creating Stacked chart
			Morris.Line({
				element: "morris-line",
				data: _convertData,
				xkey: "y",
				ykeys: ["a"],
				stacked: true,
				behaveLikeLine: true,
				labels: ["Kunjungan"],
				lineWidth: "3px",
				resize: true,
				parseTime: false,
				hideHover: "auto",
				pointSize: 0,
				gridLineColor: "#e2e2e2",
				lineColors: ["#5f9e5f"],
				preUnits: "",
			});
		} else {
			const content = `<div class="dashboard-not-found">
                        <p><i class="mdi mdi-close-circle-outline"></i> Tidak ada data yang ditampilkan</p>
                      </div>`;

			$(".js-chart-not-found").html(content);
		}
	};

	const handleGetDataDiagnosa = () => {
		const _date = $("#dateRange").val();
		const _startDate = FormatDate.range(_date).startDate;
		const _endDate = FormatDate.range(_date).endDate;

		$.ajax({
			url: API_URL.countDiagnosis,
			method: "POST",
			dataType: "JSON",
			data: {
				startDate: _startDate,
				endDate: _endDate,
			},
			beforeSend: function () {
				$(".card-box").addClass("skeleton");
			},
			success: (response) => {
				const _data = response.data;

				let _diagnosa = "";
				if (_data.length) {
					let _diagnosaItem = "";
					$.each(_data, (i, v) => {
						_diagnosaItem += `<tr>
																	<td class="text-center">${i + 1}</td>
																	<td>${v.diagnosis}</td>
																</tr>`;
					});

					_diagnosa = `<table class="table">
														<thead>
														<tr>
															<th width="50" class="text-center">No</th>
															<th>Diagnosa</th>
														</tr>
														</thead>
														<tbody>
															${_diagnosaItem}
														</tbody>
													</table>`;
				} else {
					_diagnosa = `<div class="dashboard-not-found">
														<p><i class="mdi mdi-close-circle-outline"></i> Tidak ada data yang ditampilkan</p>
													</div>`;
				}

				$(".card-box").removeClass("skeleton");

				$(".js-list-diagnosis").html(_diagnosa);
			},
		});
	};

	const handleGetDataDestination = (response) => {
		let _destination = "";
		if (response.length) {
			let _destinationItem = "";
			$.each(response, (i, v) => {
				_destinationItem += `<tr>
																<td class="text-center">${i + 1}</td>
																<td>${v.destination}</td>
																<td class="text-right">${parseInt(v.total)}</td>
															</tr>`;
			});

			_destination = `<table class="table">
												<thead>
												<tr>
													<th width="50" class="text-center">No</th>
													<th>Nama Poli</th>
													<th class="text-right">Kunjungan</th>
												</tr>
												</thead>
												<tbody>
													${_destinationItem}
												</tbody>
											</table>`;
		} else {
			_destination = `<div class="dashboard-not-found">
												<p><i class="mdi mdi-close-circle-outline"></i> Tidak ada data yang ditampilkan</p>
											</div>`;
		}

		$(".js-destination").html(_destination);
	};

	const handleGetDataInventory = (response) => {
		if (response.outStock !== 0 || response.limitStock !== 0) {
			let _outInventory = "";
			let _limitInventory = "";
			if (response.outStock > 0) {
				_outInventory = `<div class="col-lg-12">
														<div class="alert alert-danger alert-dismissable">
															<button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
															Stok ${response.outStock} Inventori habis
															<a href="${WEB_URL.medicine}" class="alert-link pl-2">Lihat stok</a>
														</div>
													</div>`;
			}
			if (response.limitStock > 0) {
				_limitInventory = `<div class="col-lg-12">
														<div class="alert alert-warning alert-dismissable">
															<button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
															Stok ${response.limitStock} Inventori segera habis. 
															<a href="${WEB_URL.medicine}" class="alert-link pl-2">Lihat stok</a>
														</div>
													</div>`;
			}

			const _inventory = _outInventory + _limitInventory;

			$(".js-dashboard-inventory").html(_inventory);
		} else {
			$(".js-dashboard-inventory").find("div").remove();
		}
	};

	// init
	const init = () => {
		if ($(".js-dashboard-chart").length) {
			handleGetDataSummary();
		}
		if ($(".js-filter-date").length) {
			handleFilterDate();
		}
		if ($(".js-list-diagnosis").length) {
			handleGetDataDiagnosa();
		}
	};

	return {
		init,
	};
})();

export default Dashboard;
