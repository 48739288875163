/* ------------------------------------------------------------------------------
@name: DataTable
--------------------------------------------------------------------------------- */

const DataTable = (() => {
	// handleRunDataTableDefault
	const handleRunDataTableDefault = (
		dataSetting,
		columnSetting,
		columnVisbleSetting
	) => {
		// --- datatable setting
		const tableSetting = {
			processing: true,
			serverSide: true,
			responsive: true,
			autoWidth: false,
			stateSave: true,
			// dom: "Bfrtip",
			// buttons: ["copy", "excel", "pdf"],
			ajax: {
				url: dataSetting.url,
				type: dataSetting.method,
				data: dataSetting ? dataSetting.data : "",
			},
			columnDefs: columnSetting,
		};

		let table = $("." + dataSetting.selector).DataTable(tableSetting);

		// --- setting datatable button
		// table
		// 	.buttons()
		// 	.container()
		// 	.appendTo("#datatable-buttons_wrapper .col-md-6:eq(0)");

		// --- setting visibility column
		if (columnVisbleSetting !== undefined) {
			table
				.columns(columnVisbleSetting.target)
				.visible(columnVisbleSetting.visble);
		}
	};

	// handleRunDataTableCustom
	const handleRunDataTableCustom = (
		dataSetting,
		filterSetting,
		columnSetting,
		sortSetting = 0,
		columnVisbleSetting = 0
	) => {
		// --- datatable setting
		const tableSetting = {
			bLengthChange: false,
			processing: true,
			serverSide: true,
			responsive: true,
			autoWidth: false,
			stateSave: true,
			// dom: "Btipr",
			// buttons: ["copy", "excel", "pdf"],
			ajax: {
				url: dataSetting.url,
				type: dataSetting.method,
				data: dataSetting ? dataSetting.data : "",
			},
			columnDefs: columnSetting,
			processing: true,
			language: {
				processing: '<div class="loader"></div>',
			},
		};

		let table = $("." + dataSetting.selector).DataTable(tableSetting);

		// --- setting datatable button
		// table.buttons().container().appendTo(".js-show-datatable-button");
		// $(".js-show-datatable-button").find(".dt-buttons:nth-child(1)").hide();

		// --- filter setting
		$.each(filterSetting, (i, v) => {
			if (v.event === "change") {
				$("#" + v.id).on(v.event, (e) => {
					table.draw();
				});
			} else {
				$("#" + v.id).on(v.event, (e) => {
					let value = $(e.currentTarget).val();
					table.search(value).draw();
				});
			}
		});

		// --- sort setting
		if (sortSetting !== 0) {
			$("#" + sortSetting.id).on(sortSetting.event, (e) => {
				let value = $(e.currentTarget).val();
				table.page.len(value).draw();
			});
		}

		// --- setting visibility column
		if (columnVisbleSetting !== 0) {
			table
				.columns(columnVisbleSetting.target)
				.visible(columnVisbleSetting.visble);
		}
	};

	return {
		default: handleRunDataTableDefault,
		custom: handleRunDataTableCustom,
	};
})();

export default DataTable;
