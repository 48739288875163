/* ------------------------------------------------------------------------------
@name: CustomSelect
@description: CustomSelect Activate
--------------------------------------------------------------------------------- */

const CustomSelect = (() => {
	// Handle Run CustomSelect
	const handleRunCustomSelect = () => {
		$(".js-modal-custom-select").each((i, e) => {
			const _this = $(e);
			const _parent = _this.attr("id");
			$(`#${_parent} .js-select2`).select2({
				dropdownParent: $("#" + _parent),
			});
		});
	};

	// init
	const init = () => {
		if ($(".js-select2").length) {
			handleRunCustomSelect();
		}
	};

	return {
		init,
	};
})();

export default CustomSelect;
