/* ------------------------------------------------------------------------------
@name: ProfileFaskes
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";

// --- utilities
import { Form, HttpRequest } from "utilities";

const ElementSelector = [
	{
		id: "profileId",
	},
	{
		id: "logoFaskes",
		type: "file",
	},
	{
		id: "logoCity",
		type: "file",
	},
	{
		id: "organizationName",
		validation: {
			required: true,
		},
	},
	{
		id: "organizationType",
		validation: {
			required: true,
		},
	},
	{
		id: "phone",
		validation: {
			required: true,
			phone: true,
			invalid: true,
		},
	},
	{
		id: "email",
		validation: {
			required: true,
			email: true,
			invalid: true,
		},
	},
	{
		id: "website",
		validation: {
			required: true,
		},
	},
	{
		id: "province",
		type: "custom-select-input",
	},
	{
		id: "city",
		type: "custom-select-input",
	},
	{
		id: "district",
		type: "custom-select-input",
	},
	{
		id: "village",
		type: "custom-select-input",
	},
	{
		id: "cityName",
		validation: {
			required: true,
		},
	},
	{
		id: "postalCode",
	},
	{
		id: "latitude",
	},
	{
		id: "longitude",
	},
	{
		id: "address",
		validation: {
			required: true,
		},
	},
];

const ElementEvents = ["input", "blur"];

const ProfileFaskes = (() => {
	// Handle Run Validation
	const handleRunValidation = () => {
		Form.validation(ElementEvents, ElementSelector);
	};

	// Handle Click Validation
	const handleClickValidation = () => {
		$('.js-form-profile-faskes button[type="submit"]').on("click", (e) => {
			$.each(ElementSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".error").length === 0) {
				handlePostData();
			}
			e.preventDefault();
		});
	};

	// handlePostData
	const handlePostData = () => {
		const formData = Form.dataColletion(ElementSelector);
		let apiUrl = API_URL.profileFaskesAdd;
		if ($("#profileId").val().length !== 0) {
			apiUrl = API_URL.profileFaskesEdit;
		}

		const data = {
			url: apiUrl,
			method: "POST",
			data: formData,
		};

		HttpRequest.default(data);
		handleGetProfile();
	};

	// handleEmptyInput
	const handleEmptyInput = () => {
		$('.js-form-profile-faskes button[data-dismiss="modal"]').on(
			"click",
			(e) => {
				Form.emptyData(ElementSelector);
			}
		);
	};

	// handleGetProfile
	const handleGetProfile = () => {
		$.ajax({
			url: API_URL.profileFaskes,
			method: "POST",
			dataType: "JSON",
			success: (response) => {
				const _data = response.data;
				$(".js-organizationName").text(
					_data.organizationName !== null ? _data.organizationName : "-"
				);
				$(".js-organizationType").text(
					_data.organizationType !== null ? _data.organizationType : "-"
				);
				$(".js-logo-faskes").attr("src", _data.logoFaskes);
				$(".js-logo-city").attr("src", _data.logoCity);
				$(".js-phone").text(_data.phone !== null ? _data.phone : "-");
				$(".js-email").text(_data.email !== null ? _data.email : "-");
				$(".js-address").text(_data.address !== null ? _data.address : "-");
				$(".js-website").text(_data.website !== null ? _data.website : "-");
				$(".js-address").text(_data.address !== null ? _data.address : "-");
				$(".js-city").text(_data.cityName !== null ? _data.cityName : "-");
			},
		});
	};

	const handleGetFormData = () => {
		$(".js-edit-profile-faskes").on("click", (e) => {
			let formData = new FormData();

			const data = {
				url: API_URL.profileFaskes,
				method: "POST",
				data: formData,
				elementSelector: ElementSelector,
			};

			Form.getFormData(data);
		});
	};

	// init
	const init = () => {
		if ($(".js-form-profile-faskes").length) {
			handleRunValidation();
			handleClickValidation();
			handleEmptyInput();
		}
		if ($(".js-profile-faskes").length) {
			handleGetProfile();
			handleGetFormData();
		}
	};

	return {
		init,
	};
})();

export default ProfileFaskes;
