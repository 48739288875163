/* ------------------------------------------------------------------------------
@name: Services
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";

// --- utilities
import { DataTable, Form, HttpRequest, SweetAlert } from "utilities";

// Form Validation
const ElementSelector = [
	{
		id: "serviceId",
	},
	{
		id: "name",
		validation: {
			required: true,
			email: true,
		},
	},
	{
		id: "category",
		validation: {
			required: true,
		},
	},
	{
		id: "sellPrice",
		validation: {
			required: true,
		},
	},
	{
		id: "discount",
	},
	{
		id: "status",
		type: "checkbox",
	},
];

const ElementEvents = ["input", "blur"];

const Services = (() => {
	// Handle Run DataTable
	const handleRunDataTable = () => {
		const dataSetting = {
			selector: "js-data-services",
			url: API_URL.servicesGet,
			method: "POST",
		};

		const columnSetting = [
			{
				targets: [0, 4, 5],
				className: "text-center",
			},
			{
				targets: 3,
				className: "text-right",
			},
			{
				targets: 5,
				render: (dataSetting) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Edit" class="btn btn-icon waves-effect btn-primary btn-trans js-edit-data" data-id="${dataSetting}"><i class="mdi mdi-pencil-outline"></i></button>
					<button type="button" data-toggle="tooltip" data-placement="left" title="Delete" class="btn btn-icon waves-effect btn-danger btn-trans js-delete-data" data-id="${dataSetting}"><i class="mdi mdi-trash-can-outline"></i></button>`;
				},
			},
		];

		DataTable.default(dataSetting, columnSetting);
	};

	// Handle Run Validation
	const handleRunValidation = () => {
		Form.validation(ElementEvents, ElementSelector);
	};

	// Handle Click Validation
	const handleClickValidation = () => {
		$('button[type="submit"]').on("click", (e) => {
			$.each(ElementSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".error").length === 0) {
				handlePostData();
			}
			e.preventDefault();
		});
	};

	// handleEmptyInput
	const handleEmptyInput = () => {
		$('button[data-dismiss="modal"]').on("click", (e) => {
			Form.emptyData(ElementSelector);
		});
	};

	// handlePostData
	const handlePostData = () => {
		const formData = Form.dataColletion(ElementSelector);
		let apiUrl = API_URL.servicesAdd;
		if ($("#serviceId").val().length !== 0) {
			apiUrl = API_URL.servicesEdit;
		}

		const data = {
			url: apiUrl,
			method: "POST",
			data: formData,
		};

		HttpRequest.default(data);
		Form.emptyData(ElementSelector);
	};

	const handleGetFormData = () => {
		$("body").on("click", ".js-edit-data", (e) => {
			const _this = $(e.currentTarget);
			let formData = new FormData();
			const serviceId = _this.attr("data-id");
			formData.append("serviceId", serviceId);

			const data = {
				url: API_URL.servicesGet,
				method: "POST",
				data: formData,
				elementSelector: ElementSelector,
			};

			Form.getFormData(data);
		});
	};

	// handleDeleteData
	const handleDeleteData = () => {
		$("body").on("click", ".js-delete-data", (e) => {
			const _this = $(e.currentTarget);
			let formData = new FormData();
			const serviceId = _this.attr("data-id");
			formData.append("serviceId", serviceId);

			const data = {
				url: API_URL.servicesDelete,
				method: "POST",
				data: formData,
			};

			Form.deleteData(data);
		});
	};

	// handleGetCategory
	const handleGetCategory = () => {
		$.ajax({
			url: API_URL.medicineCategoryGet,
			method: "POST",
			dataType: "JSON",
			data: {
				type: "2",
			},
			success: (response) => {
				const _data = response.data;
				let _content = `<li class="form-dropdown-item"><span class="form-dropdown-select" data-value="">Pilih kategori</span></li>`;

				$.each(_data, (i, v) => {
					_content += `<li class="form-dropdown-item">
						<span class="form-dropdown-select" data-value="${v.name}">${v.name}</span>
						<button type="button" class="form-dropdown-remove" data-id="${v.categoryId}">
							<i class="mdi mdi-trash-can-outline"></i>
						</button>
					</li>`;
				});
				$(".js-service-category").html(_content);
			},
		});
	};

	// handlePostCategory
	const handlePostCategory = () => {
		$("body").on("click", ".js-service-category .form-dropdown-btn", (e) => {
			let formData = new FormData();
			formData.append("type", "2");
			formData.append("category", $("#category").val());

			const data = {
				url: API_URL.medicineCategoryAdd,
				method: "POST",
				data: formData,
			};

			const beforeSend = () => {
				const _loader = `<div class="spinner-border text-custom m-2" role="status"><span class="sr-only">Loading...</span></div>`;
				$(".js-service-category .form-dropdown-notif").html(_loader);
			};

			const response = HttpRequest.custom(data, beforeSend);

			if (response.code === 200) {
				$(".js-service-category .form-dropdown-notif").remove();
				$(".js-service-category").parent().find(".form-dropdown-input").val("");
				handleGetCategory();
			} else {
				SweetAlert.config(response.message, "error");
			}
		});
	};

	// handleDeleteCategory
	const handleDeleteCategory = () => {
		$("body").on("click", ".js-service-category .form-dropdown-remove", (e) => {
			const _this = $(e.currentTarget);
			const categoryId = _this.attr("data-id");
			let formData = new FormData();
			formData.append("categoryId", categoryId);

			const data = {
				url: API_URL.medicineCategoryDelete,
				method: "POST",
				data: formData,
			};

			const response = HttpRequest.custom(data);

			if (response.code === 200) {
				$(".js-service-category").parent().find(".form-dropdown-input").val("");
				handleGetCategory();
			} else {
				SweetAlert.config(response.message, "error");
			}
		});
	};

	// init
	const init = () => {
		if ($(".js-data-services").length) {
			handleRunDataTable();
			handleDeleteData();
			handleGetFormData();
		}
		if ($(".js-form-services").length) {
			handleRunValidation();
			handleClickValidation();
			handleEmptyInput();
		}
		if ($(".js-service-category").length) {
			handleGetCategory();
			handlePostCategory();
			handleDeleteCategory();
		}
	};

	return {
		init,
	};
})();

export default Services;
