/* ------------------------------------------------------------------------------
@name: InputDropdown
@description: InputDropdown
--------------------------------------------------------------------------------- */

// --- InputDropdown
const InputDropdown = (() => {
	// -- handleClickDropdown
	const handleClickDropdown = () => {
		$("body").on("click", ".form-dropdown-input", (e) => {
			const _this = $(e.currentTarget);
			const _parent = _this.parents(".form-dropdown");
			let _content = `<li class="form-dropdown-notif"><p class="mb-0 pt-2 pb-2">Data tidak ditemukan!</p></li>`;

			if (_parent.hasClass("form-dropdown-add")) {
				_content = `<li class="form-dropdown-notif">
												<button type="button" class="btn btn-block btn-custom waves-effect waves-light form-dropdown-btn">Tambah <i class="mdi mdi-plus-circle"></i></button>
											</li>`;
			}

			$(".form-dropdown").removeClass("form-dropdown-show");
			_parent.addClass("form-dropdown-show");

			if (_parent.find(".form-dropdown-list .form-dropdown-notif").length) {
				_parent.find(".form-dropdown-list .form-dropdown-notif").remove();
			} else {
				_parent.find(".form-dropdown-list").append(_content);
			}
		});

		$("body").on("click", () => {
			if ($(".form-dropdown").hasClass("form-dropdown-show")) {
				$(".form-dropdown").removeClass("form-dropdown-show");
				$(".form-dropdown .form-dropdown-notif").remove();
			}
		});

		$("body").on("click", ".form-dropdown", (e) => {
			e.stopPropagation();
		});
	};

	// -- handleSelectDropdown
	const handleSelectDropdown = () => {
		$("body").on("click", ".form-dropdown-select", (e) => {
			const _this = $(e.currentTarget);
			const _val = _this.attr("data-value");
			const _parent = _this.parents(".form-dropdown");
			_parent.find(".form-dropdown-input").val(_val);
			_parent.removeClass("form-dropdown-show");
			_parent.find(".error").removeClass("error");
			_parent.removeClass("error");
		});
	};

	// -- handleInputDropdown
	const handleInputDropdown = () => {
		$("body").on("input", ".form-dropdown-input", (e) => {
			const _this = $(e.currentTarget);
			const _val = _this.val();
			const _parent = _this.parents(".form-dropdown");
			const _dataSearch = _parent.find(
				".form-dropdown-list .form-dropdown-item"
			);
			let _dataSearched = 0;

			// hide popup result item
			_parent.find(".form-dropdown-item").hide();

			// hide popup result item
			$.each(_dataSearch, (i, v) => {
				if (v.innerText.toLowerCase().indexOf(_val.toLowerCase()) > -1) {
					_parent.find(".form-dropdown-item").eq(i).show();
					_dataSearched++;
				}
			});

			// show popup result notif
			if (_dataSearched === 0) {
				_parent.find(".form-dropdown-notif").show();
			} else {
				_parent.find(".form-dropdown-notif").hide();
			}
		});
	};

	// --- init
	const init = () => {
		if ($(".form-dropdown").length) {
			handleClickDropdown();
			handleSelectDropdown();
			handleInputDropdown();
		}
	};

	// --- return
	return {
		init,
	};
})();

export default InputDropdown;
