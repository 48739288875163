/* ------------------------------------------------------------------------------
@name: Patient
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";

// --- utilities
import { DataTable, Form, HttpRequest, SweetAlert } from "utilities";

// Form Validation
const ElementSelector = [
	{
		id: "patientId",
	},
	{
		id: "noMedicalRecord",
		validation: {
			required: true,
		},
	},
	{
		id: "oldMedicalRecord",
	},
	{
		id: "name",
		validation: {
			required: true,
		},
	},
	{
		id: "email",
	},
	{
		id: "phone",
		validation: {
			required: true,
			phone: true,
			invalid: true,
		},
	},
	{
		id: "gender",
		validation: {
			required: true,
			selectOption: true,
		},
	},
	{
		id: "birthPlace",
		validation: {
			required: true,
		},
	},
	{
		id: "birthDate",
		validation: {
			required: true,
		},
	},
	{
		id: "nik",
	},
	{
		id: "motherName",
	},
	{
		id: "kkNumber",
	},
	{
		id: "kkName",
	},
	{
		id: "assurance",
		validation: {
			required: true,
			selectOption: true,
		},
	},
	{
		id: "assuranceNumber",
	},
	{
		id: "religion",
		validation: {
			selectOption: true,
		},
	},
	{
		id: "martialStatus",
		validation: {
			selectOption: true,
		},
	},
	{
		id: "lastEducation",
		validation: {
			selectOption: true,
		},
	},
	{
		id: "occupation",
		validation: {
			selectOption: true,
		},
	},
	{
		id: "language",
	},
	{
		id: "ethnic",
	},
	{
		id: "citizenshipStatus",
		validation: {
			required: true,
			selectOption: true,
		},
	},
	{
		id: "province",
		type: "custom-select-input",
		validation: {
			required: true,
		},
	},
	{
		id: "city",
		type: "custom-select-input",
		validation: {
			required: true,
		},
	},
	{
		id: "district",
		type: "custom-select-input",
		validation: {
			required: true,
		},
	},
	{
		id: "village",
		type: "custom-select-input",
		validation: {
			required: true,
		},
	},
	{
		id: "rt",
		validation: {
			required: true,
		},
	},
	{
		id: "rw",
		validation: {
			required: true,
		},
	},
	{
		id: "postalCode",
	},
	{
		id: "status",
		validation: {
			required: true,
			selectOption: true,
		},
	},
	{
		id: "address",
		validation: {
			required: true,
		},
	},
	{
		id: "cityName",
		validation: {
			required: true,
		},
	},
];

const ElementEvents = ["input", "blur"];

const Patient = (() => {
	// Handle Run DataTable
	const handleRunDataTable = () => {
		const dataSetting = {
			selector: "js-data-patient",
			url: API_URL.patientGet,
			method: "POST",
		};

		const columnSetting = [
			{
				targets: [0, 7],
				className: "text-center",
			},
			{
				targets: 1,
				render: (data) => {
					return `<p class="mb-0">${data.name}</p><p class="mb-0 text-muted">${data.id}</p>`;
				},
			},
			{
				targets: 2,
				render: (data) => {
					return `<p class="mb-0">${data.gender}</p><p class="mb-0 text-muted">${data.age}</p>`;
				},
			},
			{
				targets: 3,
				render: (data) => {
					return `<p class="mb-0">${data.nik}</p><p class="mb-0 text-muted">${data.bpjs}</p>`;
				},
			},
			{
				targets: 7,
				render: (data) => {
					// let btnSync = data.syncStatus === "0" ? "sync" : "check";
					// let btnTrigger = data.syncStatus === "0" ? "js-sync-data" : "";
					// <button type="button" data-toggle="tooltip" data-placement="left" title="Sync" class="btn btn-icon waves-effect btn-success btn-trans ${btnTrigger}" data-id="${data.patientId}"><i class="mdi mdi-${btnSync}"></i></button>

					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Edit" class="btn btn-icon waves-effect btn-primary btn-trans js-edit-data" data-id="${data.patientId}"><i class="mdi mdi-pencil-outline"></i></button>
					<button type="button" data-toggle="tooltip" data-placement="left" title="Delete" class="btn btn-icon waves-effect btn-danger btn-trans js-delete-data" data-id="${data.patientId}"><i class="mdi mdi-trash-can-outline"></i></button>`;
				},
			},
		];

		const columnVisbleSetting = {
			target: [3, 6],
			visble: true,
		};

		DataTable.default(dataSetting, columnSetting, columnVisbleSetting);
	};

	// Handle Run Validation
	const handleRunValidation = () => {
		Form.validation(ElementEvents, ElementSelector);
	};

	// Handle Click Validation
	const handleClickValidation = () => {
		$('.js-form-patient button[type="submit"]').on("click", (e) => {
			$.each(ElementSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".error").length === 0) {
				handlePostData();
			} else {
				SweetAlert.config("Pengisian data belum lengkap!", "warning");
			}

			e.preventDefault();
		});
	};

	// handleEmptyInput
	const handleEmptyInput = () => {
		$('#modalPatient button[data-dismiss="modal"]').on("click", (e) => {
			Form.emptyData(ElementSelector);
		});
	};

	// handlePostData
	const handlePostData = () => {
		const formData = Form.dataColletion(ElementSelector);
		let apiUrl = API_URL.patientAdd;
		if ($("#patientId").val().length !== 0) {
			apiUrl = API_URL.patientEdit;
		}

		const data = {
			url: apiUrl,
			method: "POST",
			data: formData,
		};

		if ($(".js-form-registration").length) {
			const response = HttpRequest.custom(data);
			const code = response.code;
			const rData = response.data;
			const message = response.message;

			if (code === 200) {
				Form.emptyData(ElementSelector);

				const _parent = $(".js-form-registration");
				_parent.find("#patientId").val(rData.patientId);
				_parent.find("#noMedicalRecord1").val(rData.noMedicalRecord);
				_parent.find("#nik1").val(rData.nik);
				_parent.find("#name1").val(rData.name);
				_parent.find("#address1").val(rData.address);
				_parent.find("#birthDate1").val(rData.birthDate);
				_parent.find("#gender1").val(rData.gender);
				_parent.find("#assurance1").val(rData.assurance);

				$("#modalPatient").modal("hide");
			} else {
				SweetAlert.config(message, "error");
			}
		} else {
			HttpRequest.default(data, ".js-data-patient", ElementSelector);
		}
	};

	const handleGetFormData = () => {
		$("body").on("click", ".js-edit-data", (e) => {
			let formData = new FormData();
			const _this = $(e.currentTarget);
			const patientId = _this.attr("data-id");
			formData.append("patientId", patientId);

			const data = {
				url: API_URL.patientGet,
				method: "POST",
				data: formData,
				elementSelector: ElementSelector,
			};

			Form.getFormData(data);
		});
	};

	// handleDeleteData
	const handleDeleteData = () => {
		$("body").on("click", ".js-delete-data", (e) => {
			let formData = new FormData();
			const _this = $(e.currentTarget);
			const patientId = _this.attr("data-id");
			formData.append("patientId", patientId);

			const data = {
				url: API_URL.patientDelete,
				method: "POST",
				data: formData,
			};

			Form.deleteData(data);
		});
	};

	// handleSyncData
	const handleSyncData = () => {
		$("body").on("click", ".js-sync-data", function (e) {
			swal({
				title: "Apakah Anda yakin?",
				text: "Pastikan data yang dikirim benar!",
				type: "warning",
				showCancelButton: true,
				confirmButtonText: "Ya, Kirim",
				cancelButtonText: "Batal",
				confirmButtonClass: "btn btn-success",
				cancelButtonClass: "btn btn-danger m-l-10",
				buttonsStyling: false,
			}).then(
				() => {
					// Run API Delete Data
					var formData = new FormData();
					const patientId = $(this).attr("data-id");
					formData.append("patientId", patientId);

					const data = {
						url: API_URL.patientSync,
						method: "POST",
						data: formData,
					};

					const response = HttpRequest.custom(data);
					if (response.code === 200) {
						SweetAlert.config(response.message, "success");
					} else {
						SweetAlert.config(response.message, "warning");
					}
				},
				(dismiss) => {
					if (dismiss === "cancel") {
						swal("Batal", "Data Anda tidak akan dikirm :)", "error");
					}
				}
			);
		});
	};

	// handleGetAssurance
	const handleGetAssurance = () => {
		$.ajax({
			url: API_URL.assuranceGet,
			method: "POST",
			dataType: "JSON",
			success: function (response) {
				const _data = response.data;
				let _content = "<option value='0'>Pilih Asuransi & Jaminan</option>";
				$.each(_data, function (i, v) {
					_content += `<option value="${v.assuranceId}">${v.name}</option>`;
				});

				$(".js-assurance-list").html(_content);
			},
		});
	};

	// handleGetNoMedicalRecord
	const handleGetNoMedicalRecord = () => {
		$('button[data-target="#modalPatient"]').on("click", (e) => {
			$.ajax({
				url: API_URL.patientCode,
				method: "POST",
				dataType: "JSON",
				success: (response) => {
					const _data = response.data;
					$("#noMedicalRecord").val(_data);
					$("#noMedicalRecord").attr("value", _data);
				},
			});

			let assurance = $(".js-assurance-select").find("option:selected").text();
			if ($("input").hasClass("js-assurance-select")) {
				if (assurance.toLowerCase() !== "umum") {
					$("#assuranceNumber").removeAttr("disabled");
				} else {
					$("#assuranceNumber").attr("disabled", "disabled");
				}
			}
		});
	};

	const handleSelectAssurance = () => {
		$("body").on("change", ".js-assurance-select", (e) => {
			let _option = $(e.currentTarget).find("option:selected");
			let _assurance = _option.text();

			if (_assurance.toLowerCase() !== "umum") {
				$("#assuranceNumber").removeAttr("disabled");
			} else {
				$("#assuranceNumber").attr("disabled", "disabled");
			}
		});
	};

	// init
	const init = () => {
		if ($(".js-data-patient").length) {
			handleRunDataTable();
			handleDeleteData();
			handleGetFormData();
			handleSyncData();
		}
		if ($(".js-form-patient").length) {
			handleRunValidation();
			handleClickValidation();
			handleEmptyInput();
			handleGetNoMedicalRecord();
		}
		if ($(".js-assurance-list").length) {
			handleGetAssurance();
		}
		if ($(".js-assurance-select").length) {
			handleSelectAssurance();
		}
	};

	return {
		init,
	};
})();

export default Patient;
