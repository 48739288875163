/* ------------------------------------------------------------------------------
@name: Registration
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL, WHITESPACE } from "variables";

// --- utilities
import {
	DataTable,
	Form,
	HttpRequest,
	FormatDate,
	SweetAlert,
} from "utilities";

// Form Validation
const ElementSelector = [
	{
		id: "registrationId",
	},
	{
		id: "registrationCode",
	},
	{
		id: "registrationDate",
		validation: {
			required: true,
		},
	},
	{
		id: "patientId",
	},
	{
		id: "noMedicalRecord1",
		alias: "noMedicalRecord",
	},
	{
		id: "nik1",
	},
	{
		id: "name1",
	},
	{
		id: "address1",
	},
	{
		id: "birthDate1",
	},
	{
		id: "gender1",
	},
	{
		id: "assurance1",
	},
	{
		id: "assuranceNumber1",
	},
	{
		id: "destination",
		validation: {
			required: true,
		},
	},
	{
		id: "room",
	},
];

const ElementEvents = ["input", "blur"];

const Registration = (() => {
	// Handle Run DataTable
	const handleRunDataTable = () => {
		const dataSetting = {
			selector: "js-data-registration",
			url: API_URL.registrationGet,
			method: "POST",
			data: (d) => {
				const _date = $("#dateRange").val();
				const _startDate = FormatDate.range(_date).startDate;
				const _endDate = FormatDate.range(_date).endDate;
				return $.extend({}, d, {
					startDate: _startDate,
					endDate: _endDate,
				});
			},
		};

		const filterSetting = [
			{
				id: "dateRange",
				event: "change",
			},
			{
				id: "search",
				event: "keyup",
			},
		];

		const columnSetting = [
			{
				targets: [0, 6, 7],
				className: "text-center",
			},
			{
				targets: 1,
				render: (data) => {
					return `<p class="mb-0">${data.id}</p><p class="mb-0 text-muted">${data.time}</p>`;
				},
			},
			{
				targets: 2,
				render: (data) => {
					return `<p class="mb-0">${data.name}</p><p class="mb-0 text-muted">${data.id}</p>`;
				},
			},
			{
				targets: 3,
				render: (data) => {
					return `<p class="mb-0">${data.gender}</p><p class="mb-0 text-muted">${data.birth}</p>`;
				},
			},
			{
				targets: 7,
				render: (data) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Edit" class="btn btn-icon waves-effect btn-primary btn-trans js-edit-data" data-id="${data.registrationId}" data-status="${data.status}"><i class="mdi mdi-pencil-outline"></i></button>
					<button type="button" class="btn btn-icon waves-effect btn-purple btn-trans" data-toggle="modal" data-target="#modalRecipe"><i class="mdi mdi-printer"></i></button>
					<button type="button" data-toggle="tooltip" data-placement="left" title="Delete" class="btn btn-icon waves-effect btn-danger btn-trans js-delete-data" data-id="${data.registrationId}"><i class="mdi mdi-trash-can-outline"></i></button>`;
				},
			},
		];

		const sortSetting = {
			id: "sort",
			event: "change",
		};

		const columnVisbleSetting = {
			target: [6],
			visble: true,
		};

		DataTable.custom(
			dataSetting,
			filterSetting,
			columnSetting,
			sortSetting,
			columnVisbleSetting
		);
	};

	// handleRunDataPatient
	const handleRunDataPatient = () => {
		const dataSetting = {
			selector: "js-data-patient-search",
			url: API_URL.patientGet,
			method: "POST",
		};

		const columnSetting = [
			{
				targets: [0],
				className: "text-center",
			},
			{
				targets: 1,
				render: (data) => {
					return `<p class="mb-0">${data.name}</p><p class="mb-0 text-muted">${data.id}</p>`;
				},
			},
			{
				targets: 2,
				render: (data) => {
					return `<p class="mb-0">${data.gender}</p><p class="mb-0 text-muted">${data.age}</p>`;
				},
			},
			{
				targets: 3,
				render: (data) => {
					return `<p class="mb-0">${data.nik}</p><p class="mb-0 text-muted">${data.bpjs}</p>`;
				},
			},
			{
				targets: 7,
				render: (data) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Select" class="btn btn-icon waves-effect btn-success btn-trans js-select-patient" data-id="${data.patientId}" data-rme="${data.noMedicalRecord}" data-nik="${data.nik}" data-name="${data.name}" data-address="${data.address}" data-birthDate="${data.birthDate}" data-gender="${data.gender}" data-assurance="${data.assurance}" data-assurancenum="${data.assuranceNumber}" data-age="${data.age}" data-status="${data.status}"><i class="mdi mdi-plus"></i></button>`;
				},
			},
		];

		const columnVisbleSetting = {
			target: [6],
			visble: false,
		};

		DataTable.default(dataSetting, columnSetting, columnVisbleSetting);
	};

	// Handle Run Validation
	const handleRunValidation = () => {
		Form.validation(ElementEvents, ElementSelector);
	};

	const handleSelectPatient = () => {
		$("body").on("click", ".js-select-patient", (e) => {
			const _this = $(e.currentTarget);
			const _parent = $(".js-form-registration");
			const patientId = _this.attr("data-id");
			const noMedicalRecord = _this.attr("data-rme");
			const nik = _this.attr("data-nik");
			const name = _this.attr("data-name");
			const address = _this.attr("data-address");
			const birthDate = _this.attr("data-birthDate");
			const gender = _this.attr("data-gender");
			const assurance = _this.attr("data-assurance");
			const assuranceNumber =
				_this.attr("data-assurancenum") !== "null"
					? _this.attr("data-assurancenum")
					: "-";

			_parent.find("#patientId").val(patientId);
			_parent.find("#noMedicalRecord1").val(noMedicalRecord);
			_parent.find("#nik1").val(nik);
			_parent.find("#name1").val(name);
			_parent.find("#address1").val(address);
			_parent.find("#birthDate1").val(birthDate);
			_parent.find("#gender1").val(gender);
			_parent.find("#assurance1").val(assurance);
			_parent.find("#assuranceNumber1").val(assuranceNumber);

			$("#modalSearchPatient").modal("hide");
		});
	};

	// handleGetPolyclinic
	const handleGetPolyclinic = () => {
		$.ajax({
			url: API_URL.polyclinicGet,
			method: "POST",
			dataType: "JSON",
			success: (response) => {
				const _data = response.data;
				let _content = '<option value="0">Pilih Poli klinik</option>';

				$.each(_data, (i, v) => {
					_content += `<option value="${v.polyclinicId}">${v.name}</option>`;
				});
				$(".js-polyclinic-list").prepend(_content);
			},
		});
	};

	// handleSelectPolyclinic
	const handleSelectPolyclinic = () => {
		$(".js-polyclinic-list").on("change", (e) => {
			const _val = $("select#destination option:selected").text();
			if (_val === "Rawat Inap") {
				$("#room").removeAttr("disabled");
			} else {
				$("#room").attr("disabled", "disabled");
			}
		});
	};

	// Handle Click Validation
	const handleClickValidation = () => {
		$('#modalRegistration button[type="submit"]').on("click", (e) => {
			$.each(ElementSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".js-form-registration").find(".error").length === 0) {
				handlePostData();
			}
			e.preventDefault();
		});
	};

	// handleEmptyInput
	const handleEmptyInput = () => {
		$('#modalRegistration button[data-dismiss="modal"]').on("click", (e) => {
			Form.emptyData(ElementSelector);
		});
	};

	// handlePostData
	const handlePostData = () => {
		if (!WHITESPACE.test($("#noMedicalRecord1").val())) {
			if ($("#destination").val() !== "0") {
				const formData = Form.dataColletion(ElementSelector);
				let apiUrl = API_URL.registrationAdd;
				if ($("#registrationId").val().length !== 0) {
					apiUrl = API_URL.registrationEdit;
				}

				const data = {
					url: apiUrl,
					method: "POST",
					data: formData,
				};

				HttpRequest.default(data, "#dataTable", ElementSelector);
			} else {
				SweetAlert.config("Poli Klinik masih kosong!", "warning");
			}
		} else {
			SweetAlert.config("Data Pasien masih kosong!", "warning");
		}
	};

	const handleGetFormData = () => {
		$("body").on("click", ".js-edit-data", (e) => {
			let formData = new FormData();
			const _this = $(e.currentTarget);
			const registrationId = _this.attr("data-id");
			const status = _this.attr("data-status");

			if (status !== "Pendaftaran") {
				SweetAlert.config(
					"Data tidak bisa diubah karena pasien telah melakukan pemeriksaan",
					"warning"
				);
			} else {
				formData.append("registrationId", registrationId);
				const data = {
					url: API_URL.registrationGet,
					method: "POST",
					data: formData,
					elementSelector: ElementSelector,
				};

				const formShow = "#modalRegistration";
				Form.getFormData(data, formShow);
			}
		});
	};

	// handleDeleteData
	const handleDeleteData = () => {
		$("body").on("click", ".js-delete-data", (e) => {
			let formData = new FormData();
			const _this = $(e.currentTarget);
			const registrationId = _this.attr("data-id");
			formData.append("registrationId", registrationId);

			const data = {
				url: API_URL.registrationDelete,
				method: "POST",
				data: formData,
			};

			Form.deleteData(data);
		});
	};

	// handleGetCodeRegistration
	const handleGetCodeRegistration = () => {
		$('button[data-target="#modalRegistration"]').on("click", (e) => {
			$("#room").val("0");
			$(".js-date-picker").val(moment().format("D/MM/YYYY"));
			$.ajax({
				url: API_URL.registrationCode,
				method: "POST",
				dataType: "JSON",
				success: (response) => {
					const _data = response.data;
					$("#registrationCode").val(_data);
					$("#registrationCode").attr("value", _data);
				},
			});
		});
	};

	// handleGetCodeRegistration
	const handleGetCodePatient = () => {
		$(".js-new-patient").on("click", (e) => {
			$.ajax({
				url: API_URL.patientCode,
				method: "POST",
				dataType: "JSON",
				success: (response) => {
					const _data = response.data;
					$("#noMedicalRecord").val(_data);
					$("#noMedicalRecord").attr("value", _data);
				},
			});
		});
	};

	// init
	const init = () => {
		if ($(".js-data-registration").length) {
			handleRunDataTable();
			handleDeleteData();
			handleGetFormData();
		}
		if ($(".js-form-registration").length) {
			handleRunValidation();
			handleClickValidation();
			handleEmptyInput();
			handleSelectPatient();
			handleGetCodeRegistration();
			handleGetCodePatient();
		}
		if ($(".js-data-patient-search").length) {
			handleRunDataPatient();
		}
		if ($(".js-polyclinic-list").length) {
			handleGetPolyclinic();
			handleSelectPolyclinic();
		}
	};

	return {
		init,
	};
})();

export default Registration;
