// --- utilities
import {
	Scrolllable,
	BrowserCheck,
	Dropify,
	Modal,
	CustomSelect,
	ChangeFormatNumber,
	FormWizard,
	DatePickerInput,
	FullScreen,
	ButtonEvent,
	EditableTable,
} from "utilities";

// --- components
import {
	Patient,
	Medicine,
	Account,
	Login,
	Header,
	Assurance,
	Services,
	PolyClinic,
	MedicalPersonel,
	InputDropdown,
	MedicineCategory,
	MedicineUnit,
	SatuSehat,
	SSLocation,
	SSEncounter,
	SSObservation,
	StockIn,
	Registration,
	StockOut,
	Profile,
	Examination,
	Region,
	Pharmacy,
	ProfileFaskes,
	Dashboard,
	AccountLogin,
	Register,
	Laboratorium,
	FinancialRecord,
	ReportExam,
	ReportSales,
	ReportCashFlow,
	ReportCashNetprofit,
} from "components";

// --- App
const App = (() => {
	// --- run transition
	const runTransition = () => {
		$("body").removeClass("hold-transition");
	};

	// --- show site content
	const showSiteContent = () => {
		$(".js-main-site").removeClass("main-site--hide");
		// --- disable scroll
		Scrolllable.enable();
	};

	// --- ready
	const ready = () => {
		(($) => {
			// --- disable scroll
			Scrolllable.disable();
			// --- Global
			runTransition();
			showSiteContent();
			BrowserCheck.init();
			Modal.init();
			Dropify.init();
			CustomSelect.init();
			ChangeFormatNumber.init();
			FormWizard.init();
			DatePickerInput.init();
			FullScreen.init();
			ButtonEvent.init();
			EditableTable.init();
			// --- Project
			Patient.init();
			Medicine.init();
			Account.init();
			Login.init();
			Header.init();
			Assurance.init();
			Services.init();
			PolyClinic.init();
			MedicalPersonel.init();
			InputDropdown.init();
			MedicineCategory.init();
			MedicineUnit.init();
			SatuSehat.init();
			SSLocation.init();
			SSEncounter.init();
			SSObservation.init();
			StockIn.init();
			Registration.init();
			StockOut.init();
			Profile.init();
			Examination.init();
			Region.init();
			Pharmacy.init();
			ProfileFaskes.init();
			Dashboard.init();
			AccountLogin.init();
			Register.init();
			Laboratorium.init();
			FinancialRecord.init();
			ReportExam.init();
			ReportSales.init();
			ReportCashFlow.init();
			ReportCashNetprofit.init();
		})(jQuery);
	};

	// --- load
	const load = () => {
		(($) => {
			$(window).on("load", () => {});
		})(jQuery);
	};

	// --- init
	const init = () => {
		load();
		ready();
	};

	// --- return
	return {
		init,
	};
})();

// ---  run main js
App.init();
