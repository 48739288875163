/* ------------------------------------------------------------------------------
@name: Login
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL, WEB_URL } from "variables";

// --- utilities
import { Form, Session, HttpRequest, SweetAlert, DataTable } from "utilities";

// Form Validation
const ElementSelector = [
	{
		id: "email",
		validation: {
			required: true,
			email: true,
			invalid: true,
		},
	},
	{
		id: "password",
		validation: {
			required: true,
			minimum: true,
			minimumChar: 5,
		},
	},
];

const ElementEvents = ["input", "blur"];

const AccountLogin = (() => {
	// Handle Run DataTable
	const handleRunDataTable = () => {
		const dataSetting = {
			selector: "js-account-login",
			url: API_URL.accountLogin,
			method: "POST",
		};

		const columnSetting = [
			{
				targets: 2,
				className: "text-center",
				render: (dataSetting) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Edit" class="btn btn-icon waves-effect btn-primary btn-trans js-edit-data" data-id="${dataSetting}"><i class="mdi mdi-pencil-outline"></i></button>`;
				},
			},
		];

		DataTable.default(dataSetting, columnSetting);
	};

	// Handle Run Validation
	const handleRunValidation = () => {
		Form.validation(ElementEvents, ElementSelector);
	};

	// Handle Click Validation
	const handleClickValidation = () => {
		$('.js-auth-login button[type="submit"]').on("click", (e) => {
			e.preventDefault();

			$.each(ElementSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".error").length === 0) {
				handlePostData();
			}
		});
	};

	const handlePostData = () => {
		const email = $(".js-auth-login").find("#email").val();
		const password = $(".js-auth-login").find("#password").val();
		let formData = new FormData();
		formData.append("email", email);
		formData.append("password", password);

		const data = {
			url: API_URL.login,
			method: "POST",
			data: formData,
		};

		let response = HttpRequest.custom(data);
		if (response.code === 200) {
			Session.set("userData", JSON.stringify(response.data));
			// SatuSehat.auth();
			location.href = WEB_URL.accountLogin;
			// $(".js-auth-login button[type='submit']").html(`Masuk`);
		} else {
			SweetAlert.config(response.message, "error");
		}
	};

	// initx
	const init = () => {
		// if ($(".js-auth-login").length) {
		// 	handleClickValidation();
		// 	handleRunValidation();
		// }
		if ($(".js-account-login").length) {
			handleRunDataTable();
		}
	};

	return {
		init,
	};
})();

export default AccountLogin;
