/* ------------------------------------------------------------------------------
@name: Components Index
@description: Indexing all components
--------------------------------------------------------------------------------- */

import Patient from "./Patient";
import Medicine from "./Medicine";
import Account from "./Account";
import Login from "./Login";
import Header from "./Header";
import Assurance from "./Assurance";
import Services from "./Services";
import PolyClinic from "./PolyClinic";
import MedicalPersonel from "./MedicalPersonel";
import InputDropdown from "./InputDropdown";
import MedicineCategory from "./MedicineCategory";
import MedicineUnit from "./MedicineUnit";
import SatuSehat from "./SatuSehat";
import SSLocation from "./SSLocation";
import SSEncounter from "./SSEncounter";
import SSObservation from "./SSObservation";
import StockIn from "./StockIn";
import Registration from "./Registration";
import StockOut from "./StockOut";
import Profile from "./Profile";
import Examination from "./Examination";
import Region from "./Region";
import Pharmacy from "./Pharmacy";
import ProfileFaskes from "./ProfileFaskes";
import Dashboard from "./Dashboard";
import AccountLogin from "./AccountLogin";
import Register from "./Register";
import Laboratorium from "./Laboratorium";
import FinancialRecord from "./FinancialRecord";
import ReportExam from "./ReportExam";
import ReportSales from "./ReportSales";
import ReportCashFlow from "./ReportCashFlow";
import ReportCashNetprofit from "./ReportCashNetprofit";

export {
	Patient,
	Medicine,
	Account,
	Login,
	Header,
	Assurance,
	Services,
	PolyClinic,
	MedicalPersonel,
	InputDropdown,
	MedicineCategory,
	MedicineUnit,
	SatuSehat,
	SSLocation,
	SSEncounter,
	SSObservation,
	StockIn,
	Registration,
	StockOut,
	Profile,
	Examination,
	Region,
	Pharmacy,
	ProfileFaskes,
	Dashboard,
	AccountLogin,
	Register,
	Laboratorium,
	FinancialRecord,
	ReportExam,
	ReportSales,
	ReportCashFlow,
	ReportCashNetprofit,
};
