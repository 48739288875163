/* ------------------------------------------------------------------------------
@name: SSObservation

@description: SSObservation
 Activate
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";

// --- utilities
import { DataTable, FormatDate, SweetAlert } from "utilities";

const SSObservation = (() => {
	// Handle Run DataTable
	const handleRunDataTable = () => {
		const dataSetting = {
			selector: "js-ss-observation",
			url: API_URL.ssObservationGet,
			method: "POST",
			data: (d) => {
				const _date = $("#dateRange").val();
				const _startDate = FormatDate.range(_date).startDate;
				const _endDate = FormatDate.range(_date).endDate;
				return $.extend({}, d, {
					startDate: _startDate,
					endDate: _endDate,
				});
			},
		};

		const filterSetting = [
			{
				id: "dateRange",
				event: "change",
			},
			{
				id: "search",
				event: "keyup",
			},
		];

		const columnSetting = [
			{
				targets: [0, 5],
				className: "text-center",
			},
			{
				targets: 2,
				render: (data) => {
					return `<p class="mb-0">${data.name}</p><p class="mb-0 text-muted">${data.id}</p>`;
				},
			},
			{
				targets: 3,
				render: (data) => {
					return `<p class="mb-0">${data.name}</p><p class="mb-0 text-muted">${data.polyclinic}</p>`;
				},
			},
			{
				targets: 5,
				render: (registrationId) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Sync" class="btn btn-icon waves-effect btn-primary btn-trans js-sync-observation" data-id="${registrationId}"><i class="mdi mdi-sync"></i></button>`;
				},
			},
		];

		const sortSetting = {
			id: "sort",
			event: "change",
		};

		DataTable.custom(dataSetting, filterSetting, columnSetting, sortSetting);
	};

	const syncObservation = () => {
		$("body").on("click", ".js-sync-observation", (e) => {
			const _this = $(e.currentTarget);
			// const registrationCode = _this.attr("data-id");
			SweetAlert.config("Feature on progres", "warning");
		});
	};

	// init
	const init = () => {
		if ($(".js-ss-observation").length || $(".js-sync-observation").length) {
			handleRunDataTable();
			syncObservation();
		}
	};

	return {
		init,
	};
})();

export default SSObservation;
