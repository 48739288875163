/* ------------------------------------------------------------------------------
@name: Utilities Index
@description: Indexing all utilities
--------------------------------------------------------------------------------- */

import isOS from "./isOS";
import BrowserCheck from "./BrowserCheck";
import Scrolllable from "./Scrolllable";
import DataTable from "./DataTable";
import Form from "./Form";
import Dropify from "./Dropify";
import Modal from "./Modal";
import SweetAlert from "./SweetAlert";
import HttpRequest from "./HttpRequest";
import Session from "./Session";
import CustomSelect from "./CustomSelect";
import Currency from "./Currency";
import ChangeFormatNumber from "./ChangeFormatNumber";
import FormWizard from "./FormWizard";
import DatePickerInput from "./DatePickerInput";
import Toastr from "./Toastr";
import FullScreen from "./FullScreen";
import ButtonEvent from "./ButtonEvent";
import EditableTable from "./EditableTable";
import FormatDate from "./FormatDate";

export {
	isOS,
	BrowserCheck,
	Scrolllable,
	DataTable,
	Form,
	Dropify,
	Modal,
	SweetAlert,
	HttpRequest,
	Session,
	CustomSelect,
	Currency,
	ChangeFormatNumber,
	FormWizard,
	DatePickerInput,
	Toastr,
	FullScreen,
	ButtonEvent,
	EditableTable,
	FormatDate,
};
