/* ------------------------------------------------------------------------------
@name: MedicalPersonel
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL, WHITESPACE } from "variables";

// --- utilities
import { DataTable, Form, HttpRequest, SweetAlert } from "utilities";

// Form Validation
const ElementSelector = [
	{
		id: "medicalPersonelId",
	},
	{
		id: "name",
		validation: {
			required: true,
		},
	},
	{
		id: "nik",
		validation: {
			required: true,
		},
	},
	{
		id: "gender",
		validation: {
			required: true,
			selectOption: true,
		},
	},
	{
		id: "medicalPersonelCode",
		validation: {
			required: true,
		},
	},
	{
		id: "satuSehatCode",
	},
	{
		id: "role",
		validation: {
			required: true,
			selectOption: true,
		},
	},
	{
		id: "email",
		validation: {
			required: true,
			email: true,
			invalid: true,
		},
	},
	{
		id: "phone",
		validation: {
			required: true,
			phone: true,
			invalid: true,
		},
	},
	{
		id: "photo",
		type: "file",
	},
	{
		id: "province",
		type: "custom-select-input",
	},
	{
		id: "city",
		type: "custom-select-input",
	},
	{
		id: "district",
		type: "custom-select-input",
	},
	{
		id: "village",
		type: "custom-select-input",
	},
	{
		id: "address",
		validation: {
			required: true,
		},
	},
	{
		id: "status",
		type: "checkbox",
	},
];

const NakesSelector = [
	{
		id: "search",
		validation: {
			required: true,
		},
	},
	{
		id: "nakesCode",
	},
	{
		id: "fullName",
	},
];

const ElementEvents = ["input", "blur"];

const MedicalPersonel = (() => {
	// Handle Run DataTable
	const handleRunDataTable = () => {
		const dataSetting = {
			selector: "js-data-medical-personel",
			url: API_URL.medicalPersonelGet,
			method: "POST",
		};

		const columnSetting = [
			{
				targets: [0, 1, 6, 7],
				className: "text-center",
			},
			{
				targets: 1,
				render: (dataSetting) => {
					return `<span class="user-avatar">
										<img class="user-avatar__img" src="${dataSetting}" />
									</span>`;
				},
			},
			{
				targets: 7,
				render: (dataSetting) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Edit" class="btn btn-icon waves-effect btn-primary btn-trans js-edit-data" data-id="${dataSetting}"><i class="mdi mdi-pencil-outline"></i></button>
					<button type="button" data-toggle="tooltip" data-placement="left" title="Delete" class="btn btn-icon waves-effect btn-danger btn-trans js-delete-data" data-id="${dataSetting}"><i class="mdi mdi-trash-can-outline"></i></button>`;
				},
			},
		];

		DataTable.default(dataSetting, columnSetting);
	};

	// Handle Run Validation
	const handleRunValidation = () => {
		Form.validation(ElementEvents, ElementSelector);
	};

	// Handle Click Validation
	const handleClickValidation = () => {
		$('.js-form-medical-personel button[type="submit"]').on("click", (e) => {
			$.each(ElementSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".js-form-medical-personel").find(".error").length === 0) {
				handlePostData();
			}
			e.preventDefault();
		});
	};

	// handleEmptyInput
	const handleEmptyInput = () => {
		$('.js-form-medical-personel button[data-dismiss="modal"]').on(
			"click",
			(e) => {
				Form.emptyData(ElementSelector);
			}
		);

		$('.js-form-nakes button[data-dismiss="modal"]').on("click", (e) => {
			Form.emptyData(NakesSelector);
		});
	};

	// handlePostData
	const handlePostData = () => {
		const formData = Form.dataColletion(ElementSelector);
		let apiUrl = API_URL.medicalPersonelAdd;
		if ($("#medicalPersonelId").val().length !== 0) {
			apiUrl = API_URL.medicalPersonelEdit;
		}

		const data = {
			url: apiUrl,
			method: "POST",
			data: formData,
		};

		HttpRequest.default(data);
		Form.emptyData(ElementSelector);
	};

	// handleGetFormData
	const handleGetFormData = () => {
		$("body").on("click", ".js-edit-data", (e) => {
			const formData = new FormData();
			const _this = $(e.currentTarget);
			const medicalPersonelId = _this.attr("data-id");
			formData.append("medicalPersonelId", medicalPersonelId);

			const data = {
				url: API_URL.medicalPersonelGet,
				method: "POST",
				data: formData,
				elementSelector: ElementSelector,
			};

			const modalShow = $("#modalMedicalPersonel");

			Form.getFormData(data, modalShow);
		});
	};

	// handleDeleteData
	const handleDeleteData = () => {
		$("body").on("click", ".js-delete-data", (e) => {
			const formData = new FormData();
			const _this = $(e.currentTarget);
			const medicalPersonelId = _this.attr("data-id");
			formData.append("medicalPersonelId", medicalPersonelId);

			const data = {
				url: API_URL.medicalPersonelDelete,
				method: "POST",
				data: formData,
			};

			Form.deleteData(data);
		});
	};

	// handleGetCodeMedicalPersonel
	const handleGetCodeMedicalPersonel = () => {
		$('button[data-target="#modalMedicalPersonel"]').on("click", (e) => {
			$.ajax({
				url: API_URL.medicalPersonelCode,
				method: "POST",
				dataType: "JSON",
				success: (response) => {
					var _data = response.data;
					$("#medicalPersonelCode").val(_data);
					$("#medicalPersonelCode").attr("value", _data);
				},
			});
		});
	};

	// handleGetNakes
	const handleGetNakes = () => {
		Form.validation(ElementEvents, NakesSelector);

		$(".js-search-nakes").on("click", (e) => {
			$.each(NakesSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".js-form-nakes").find(".error").length === 0) {
				const _val = $("#search").val();
				$.ajax({
					url: API_URL.medicalPersonelNik,
					method: "POST",
					dataType: "JSON",
					data: {
						nik: _val,
					},
					success: (response) => {
						if (response[0] === 200) {
							const _data = response[1].entry[0].resource;
							let _gender = "Laki - Laki";
							if (_data.gender !== "female") {
								_gender = "Wanita";
							}
							$("#nakesCode").val(_data.id);
							$("#fullName").val(_data.name[0].text);
							$("#genderNakes").val(_gender);
						} else {
							SweetAlert.config(response[1]["fault"]["faultstring"], "error");
						}
					},
				});
			}
		});
	};

	// handleSelectNakes
	const handleSelectNakes = () => {
		$('.js-form-nakes button[type="submit"]').on("click", (e) => {
			if ($(".js-form-nakes").find(".error").length === 0) {
				const _nakesCode = $("#nakesCode").val();
				$("#satuSehatCode").val(_nakesCode);
				$("#modalSearchNakes").modal("hide");
				$(".modal-backdrop").removeAttr("style");
				Form.emptyData(NakesSelector);
			}
		});
	};

	// init
	const init = () => {
		if ($(".js-data-medical-personel").length) {
			handleRunDataTable();
			handleDeleteData();
			handleGetFormData();
			handleGetCodeMedicalPersonel();
		}
		if ($(".js-form-medical-personel").length) {
			handleRunValidation();
			handleClickValidation();
			handleEmptyInput();
		}
		if ($(".js-form-nakes").length) {
			handleGetNakes();
			handleSelectNakes();
		}
	};

	return {
		init,
	};
})();

export default MedicalPersonel;
