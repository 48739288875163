/* ------------------------------------------------------------------------------
@name: Examination
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL, WHITESPACE } from "variables";

// --- utilities
import {
	DataTable,
	Form,
	SweetAlert,
	Toastr,
	FormatDate,
	HttpRequest,
	Currency,
} from "utilities";

// Form Validation
const ElementSelector = [
	{
		id: "examinationId",
	},
	{
		id: "registrationId",
	},
	{
		id: "noMedicalRecord",
	},
	{
		id: "registrationCode",
		validation: {
			required: true,
		},
	},
	{
		id: "registrationDate",
		validation: {
			required: true,
		},
	},
	{
		id: "noMedicalRecord",
		validation: {
			required: true,
		},
	},
	{
		id: "name",
	},
	{
		id: "address",
	},
	{
		id: "gender",
	},
	{
		id: "assurance",
	},
	{
		id: "statusPatient",
	},
	{
		id: "examinationDate",
	},
	{
		id: "examineDate",
	},
	{
		id: "historyAnamnesa",
	},
	{
		id: "historyDiagnosa",
	},
	{
		id: "genderType",
	},
	{
		id: "age",
	},
];

const ElementCheckUp = [
	{
		id: "nurseId",
		dataValueId: true,
	},
	{
		id: "intervensi",
	},
	{
		id: "implementasi",
	},
	{
		id: "evaluasi",
	},
	{
		id: "diagnosisNurse",
	},
	{
		id: "doctorId",
		dataValueId: true,
	},
	{
		id: "anamnesa",
	},
	{
		id: "anamnesis",
	},
	{
		id: "diagnosa",
	},
	{
		id: "diagnosis",
	},
	{
		id: "diagnosisDetail",
	},
	{
		id: "awareness",
		validation: {
			selectOption: true,
		},
	},
	{
		id: "sistole",
	},
	{
		id: "diastole",
	},
	{
		id: "bb",
	},
	{
		id: "tb",
	},
	{
		id: "lp",
	},
	{
		id: "sh",
	},
	{
		id: "nd",
	},
	{
		id: "rp",
	},
	{
		id: "lila",
	},
	{
		id: "tfu",
	},
	{
		id: "djj",
	},
	{
		id: "press",
	},
	{
		id: "imt",
	},
	{
		id: "konseling",
	},
	{
		id: "finalResult",
		validation: {
			selectOption: true,
		},
	},
	{
		id: "referType",
		validation: {
			selectOption: true,
		},
	},
	{
		id: "referDestination",
		dataValueId: true,
		validation: {
			required: true,
		},
	},
];

const ArraySelector = [...ElementSelector, ...ElementCheckUp];

const ElementEvents = ["input", "blur"];
const ElementModal = [
	"#modalExamination",
	"#modalExaminationNurse",
	"#modalExaminationLab",
];

const Examination = (() => {
	// Handle Run DataTable
	const handleRunDataTable = () => {
		const _url = window.location.href;
		const _arrUrl = _url.split("/");
		const _destination = _arrUrl.slice(-1).pop();
		const _destinationName = _arrUrl.slice(Math.max(_arrUrl.length - 2, 0));

		const dataSetting = {
			selector: "js-data-examination",
			url: API_URL.examinationGet,
			method: "POST",
			data: (d) => {
				const _status = $("#status").val();
				const _date = $("#dateRange").val();
				const _startDate = FormatDate.range(_date).startDate;
				const _endDate = FormatDate.range(_date).endDate;
				return $.extend({}, d, {
					startDate: _startDate,
					endDate: _endDate,
					destination: _destination,
					status: _status,
				});
			},
		};

		const filterSetting = [
			{
				id: "dateRange",
				event: "change",
			},
			{
				id: "status",
				event: "change",
			},
			{
				id: "search",
				event: "keyup",
			},
		];

		const columnSetting = [
			{
				targets: [0, 5, 6],
				className: "text-center",
			},
			{
				targets: 1,
				render: (data) => {
					const examination =
						_destinationName[0] !== "laboratorium"
							? "js-examination-check"
							: "js-examination-add";

					return `<button type="button" class="btn btn-custom btn-rounded w-md waves-effect waves-light m-b-5 p-0 pr-2 pl-2 ${examination}" data-destination="${_destinationName[0]}" data-id="${data.registrationId}">${data.registrationCode}</button>`;
				},
			},
			{
				targets: 2,
				render: (data) => {
					return `<p class="mb-0">${data.name}</p><p class="mb-0 text-muted">${data.id}</p>`;
				},
			},
			{
				targets: 3,
				render: (data) => {
					return `<p class="mb-0">${data.gender}</p><p class="mb-0 text-muted">${data.birth}</p>`;
				},
			},
			{
				targets: 6,
				render: (dataSetting) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Edit" class="btn btn-icon waves-effect btn-primary btn-trans js-edit-data" data-id="${dataSetting}"><i class="mdi mdi-pencil-outline"></i></button>`;
				},
			},
		];

		const sortSetting = {
			id: "sort",
			event: "change",
		};

		DataTable.custom(dataSetting, filterSetting, columnSetting, sortSetting);
	};

	// Handle Run Validation
	const handleRunValidation = () => {
		Form.validation(ElementEvents, ElementSelector);
	};

	// Handle Click Validation
	const handleClickValidation = () => {
		$('.js-form-examination button[type="submit"]').on("click", (e) => {
			let _parent = "modalExamination";
			if ($("#modalExaminationNurse").hasClass("show")) {
				_parent = "modalExaminationNurse";
			} else if ($("#modalExaminationLab").hasClass("show")) {
				_parent = "modalExaminationLab";
			}

			$.each(ElementSelector, (i, v) => {
				$("#" + _parent)
					.find("#" + v.id)
					.blur();
			});

			if ($("#" + _parent).find(".error").length === 0) {
				handlePostData(_parent);
			}
			e.preventDefault();
		});
	};

	// handlePostData
	const handlePostData = (parent) => {
		const _parent = $("#" + parent);
		const _url = window.location.href;
		const _destinationUrl = _url.split("/");
		const _examinationId = _parent.find("#examinationId").val();
		const _registrationId = _parent.find("#registrationId").val();
		const _destination = _destinationUrl.slice(-1).pop();
		const _noMedicalRecord = _parent.find("#noMedicalRecord").val();
		const _nurseId = _parent.find("#nurseId").attr("data-id");
		const _intervensi = _parent.find("#intervensi").val();
		const _examinationNurse = _parent.find("#examinationNurse").val();
		const _implementasi = _parent.find("#implementasi").val();
		const _evaluasi = _parent.find("#evaluasi").val();
		const _diagnosisNurse = _parent.find("#diagnosisNurse").val();
		const _doctorId = _parent.find("#doctorId").attr("data-id");
		const _anamnesis = _parent.find(".js-anamnesis-desc").val();
		const _diagnosis = _parent.find("#diagnosis").val();
		const _konseling = _parent.find("#konseling").val();
		const _finalResult = _parent.find("#finalResult").val();
		const _referType = _parent.find("#referType").val();
		const _referDestination = _parent.find("#referDestination").attr("data-id");

		const _awareness =
			_parent.find("#awareness").val() !== undefined
				? _parent.find("#awareness").val()
				: "";
		const _sistole =
			_parent.find("#sistole").val() !== undefined
				? _parent.find("#sistole").val()
				: "";
		const _diastole =
			_parent.find("#diastole").val() !== undefined
				? _parent.find("#diastole").val()
				: "";
		const _bb =
			_parent.find("#bb").val() !== undefined ? _parent.find("#bb").val() : "";
		const _tb =
			_parent.find("#tb").val() !== undefined ? _parent.find("#tb").val() : "";
		const _imt =
			_parent.find("#imt").val() !== undefined
				? _parent.find("#imt").val()
				: "";
		const _lp =
			_parent.find("#lp").val() !== undefined ? _parent.find("#lp").val() : "";
		const _sh =
			_parent.find("#sh").val() !== undefined ? _parent.find("#sh").val() : "";
		const _nd =
			_parent.find("#nd").val() !== undefined ? _parent.find("#nd").val() : "";
		const _rp =
			_parent.find("#rp").val() !== undefined ? _parent.find("#rp").val() : "";
		const _lila =
			_parent.find("lila").val() !== undefined
				? _parent.find("#lila").val()
				: "";
		const _tfu =
			_parent.find("tfu").val() !== undefined ? _parent.find("#tfu").val() : "";
		const _djj =
			_parent.find("djj").val() !== undefined ? _parent.find("#djj").val() : "";
		const _press =
			_parent.find("press").val() !== undefined
				? _parent.find("#press").val()
				: "";

		// add physical exam
		const _physicalExamination = {
			awareness: !WHITESPACE.test(_awareness) ? _awareness : 0,
			sistole: !WHITESPACE.test(_sistole) ? _sistole : 0,
			diastole: !WHITESPACE.test(_diastole) ? _diastole : 0,
			bb: !WHITESPACE.test(_bb) ? _bb : 0,
			tb: !WHITESPACE.test(_tb) ? _tb : 0,
			imt: !WHITESPACE.test(_imt) ? _imt : 0,
			lp: !WHITESPACE.test(_lp) ? _lp : 0,
			sh: !WHITESPACE.test(_sh) ? _sh : 0,
			nd: !WHITESPACE.test(_nd) ? _nd : 0,
			rp: !WHITESPACE.test(_rp) ? _rp : 0,
			lila: !WHITESPACE.test(_lila) ? _lila : 0,
			tfu: !WHITESPACE.test(_tfu) ? _tfu : 0,
			djj: !WHITESPACE.test(_djj) ? _djj : 0,
			press: !WHITESPACE.test(_press) ? _press : 0,
		};

		// add medical treatment
		let _medicalTreatment = [];
		if (
			!_parent.find(".js-medical-treatment").find("tr").hasClass("row-notif")
		) {
			_parent
				.find(".js-medical-treatment")
				.find("tr")
				.each((i, e) => {
					const _this = $(e),
						_medicineId = _this.attr("data-id"),
						_unit = _this.attr("data-unit"),
						_qty = _this.find(".js-total-qty").val(),
						_purchase = _this.attr("data-purchase"),
						_price = _this.attr("data-price"),
						_dosis = _this.find(".js-treatment-dosis").val();

					_medicalTreatment[i] = {
						medicineId: _medicineId,
						unit: _unit,
						qty: _qty,
						purchasePrice: Currency.remove_rp(_purchase),
						price: Currency.remove_rp(_price),
						dosis: _dosis,
					};
				});
		}

		// laboratorium treatment
		let _labTreatment = [];
		if (!_parent.find(".js-treatment-lab").find("tr").hasClass("row-notif")) {
			_parent
				.find(".js-treatment-lab")
				.find("tr")
				.each((i, e) => {
					const _this = $(e),
						_medicineId = _this.attr("data-lab"),
						_result = _this.find(".js-result-lab").val();

					_labTreatment[i] = {
						medicineId: _medicineId,
						result: _result,
					};
				});
		}

		// add odontogram
		let _odontogram = {};
		if (_parent.find(".js-odontogram").length) {
			_parent
				.find(".js-odontogram")
				.find(".odontogram-list")
				.each((i, e) => {
					const _this = $(e);
					const _parent = _this.parent();
					const _kuadranKey = _parent.find(".js-kuadran-list").attr("data-id");
					const _blockKey = _parent.find(".js-block-list").attr("data-id");
					const _dataKuadran = _parent.find(".js-kuadran-list input:checked");
					const _dataBlock = _parent.find(".js-block-list input:checked");
					let _kuadranVal = "";
					_dataKuadran.each((iK, eK) => {
						const _this = $(eK);
						const _val = _this.val();
						_kuadranVal += _val + ",";
					});

					let _blockVal = "";
					_dataBlock.each((iB, eB) => {
						const _this = $(eB);
						const _val = _this.val();
						_blockVal += _val + ",";
					});
					_odontogram[_kuadranKey] = _kuadranVal.slice(0, -1);
					_odontogram[_blockKey] = _blockVal.slice(0, -1);
				});
		}

		// data post
		const _data = {
			examinationId: _examinationId,
			registrationId: _registrationId,
			destination: _destination,
			noMedicalRecord: _noMedicalRecord,
			nurseId: _nurseId !== "" ? _nurseId : 0,
			examinationNurse: _examinationNurse,
			intervensi: _intervensi,
			implementasi: _implementasi,
			evaluasi: _evaluasi,
			diagnosisNurse: _diagnosisNurse,
			doctorId: _doctorId !== "" ? _doctorId : 0,
			anamnesis: _anamnesis,
			diagnosis: _diagnosis,
			konseling: _konseling,
			finalResult:
				parent !== "modalExaminationNurse" ? _finalResult : "Pendaftaran",
			referType: _referType,
			referDestination: _referDestination,
			physicalExamination: _physicalExamination,
			medicalTreatment: _medicalTreatment,
			odontogram: _odontogram,
			_labTreatment,
		};

		// api url
		let apiUrl = API_URL.examinationAdd;
		if (parent !== "modalExaminationNurse") {
			if (_examinationId.length !== 0) {
				apiUrl = API_URL.examinationEdit;
			}
		}

		if (parent !== "modalExaminationNurse") {
			validationPostDefault(_parent, apiUrl, _data, parent);
		} else {
			validationPostNurse(_parent, apiUrl, _data);
		}
	};

	// validationPostDefault
	const validationPostDefault = (_parent, _api, _data, modal) => {
		let _finalResult = _parent.find("#tabFinalResult").find("select").val();
		let modalExaminationLab = modal === "modalExaminationLab" ? true : false;

		if (_parent.find("#doctorId").val() !== "") {
			if (
				!WHITESPACE.test(_parent.find("#tabSubjective").find("textarea").val())
			) {
				if (
					!WHITESPACE.test(_parent.find("#tabObjective").find("input").val())
				) {
					if (
						!WHITESPACE.test(
							_parent.find("#tabAssesment").find("textarea").val()
						)
					) {
						if (
							!WHITESPACE.test(
								_parent.find("#tabKonseling").find("textarea").val()
							)
						) {
							if (_finalResult !== null || _finalResult !== "0") {
								$.ajax({
									url: _api,
									method: "POST",
									dataType: "JSON",
									data: _data,
									success: (response) => {
										let status = "success";
										if (response.code !== 200) {
											status = "error";
										}
										$(".modal").modal("hide");
										Form.emptyData(ElementCheckUp);
										SweetAlert.config(response.message, status);
										$(".js-data-examination").DataTable().ajax.reload();
									},
								});
							} else {
								SweetAlert.config("Hasil Akhir belum diisi!", "warning");
							}
						} else {
							SweetAlert.config("Konseling belum diisi!", "warning");
						}
					} else {
						SweetAlert.config("Assesment belum diisi!", "warning");
					}
				} else {
					SweetAlert.config("Objective belum diisi!", "warning");
				}
			} else {
				SweetAlert.config("Subjective belum diisi!", "warning");
			}
		} else {
			SweetAlert.config("Petugas Kesahatan belum diisi!", "warning");
		}
	};

	// validationPostNurse
	const validationPostNurse = (_parent, _api, _data) => {
		if (_parent.find("#nurseId").val() !== "") {
			if (!WHITESPACE.test(_parent.find(".js-anamnesis-desc").val())) {
				if (
					!WHITESPACE.test(
						_parent.find(".js-physical-exam").find("input").val()
					)
				) {
					$.ajax({
						url: _api,
						method: "POST",
						dataType: "JSON",
						data: _data,
						success: (response) => {
							let status = "success";
							if (response.code !== 200) {
								status = "error";
							}
							$(".modal").modal("hide");
							Form.emptyData(ElementCheckUp);
							SweetAlert.config(response.message, status);
							$(".js-data-examination").DataTable().ajax.reload();
						},
					});
				} else {
					SweetAlert.config("Pemeriksaan fisik belum diisi!", "warning");
				}
			} else {
				SweetAlert.config("Anamnesis belum diisi!", "warning");
			}
		} else {
			SweetAlert.config("Petugas Kesahatan belum diisi!", "warning");
		}
	};

	// handleGetSingleData
	const handleGetSingleData = () => {
		$("body").on("click", ".js-edit-data", (e) => {
			let formData = new FormData();
			const _this = $(e.currentTarget);
			const _registrationId = _this.attr("data-id");
			formData.append("registrationId", _registrationId);
			formData.append("method", "edit");

			const data = {
				url: API_URL.examinationGet,
				method: "POST",
				data: formData,
				elementSelector: ArraySelector,
			};

			const formShow = "examination";

			const response = Form.getFormData(data, formShow);

			handleGetMedicalTreatment(response);
			if ($(".js-odontogram").length) {
				handleGetOdontogram(response);
			}

			$(".js-medical-record").attr("data-id", $("#noMedicalRecord").val());

			if (!$('.nav-link[href="#tabSubjective"]').hasClass("active")) {
				$(".nav-link").removeClass("active");
				$('.nav-link[href="#tabSubjective"]').addClass("active");
				$(".tab-pane").removeClass("active show");
				$("#tabSubjective").addClass("active show");
			}

			if (response.data.finalResult === "Pasien Pulang") {
				$("#referType").attr("disabled", "disabled");
				$("#referDestination").attr("disabled", "disabled");
			} else {
				$("body").find("#referType").removeAttr("disabled");
				$("body").find("#referDestination").removeAttr("disabled");
			}
		});
	};

	// handleDeleteData
	const handleDeleteData = () => {
		$("body").on("click", ".js-delete-data", (e) => {
			const _this = $(e.currentTarget);
			const _registrationId = _this.attr("data-id");
			let formData = new FormData();
			formData.append("registrationId", _registrationId);

			const data = {
				url: API_URL.examinationDelete,
				method: "POST",
				data: formData,
			};

			Form.deleteData(data);
		});
	};

	// handleEmptyInput
	const handleEmptyInput = () => {
		$('button[data-confirm-close="modal"]').on("click", (e) => {
			Form.emptyData(ElementCheckUp, true);
			$(".js-medical-record").removeAttr("data-id");
		});
	};

	const handleCheckExamination = () => {
		$("body").on("click", ".js-examination-check", (e) => {
			const _this = $(e.currentTarget);
			const _registrationId = _this.attr("data-id");
			$("#modalCheckExamination")
				.find("button")
				.attr("data-id", _registrationId);
			$("#modalCheckExamination").modal("show");
		});
	};

	// handleGetFormData
	const handleGetFormData = () => {
		$("body").on("click", ".js-examination-add", (e) => {
			const _this = $(e.currentTarget);
			const _registrationId = _this.attr("data-id");
			const _content = _this.attr("data-content");
			const _destination = _this.attr("data-destination");
			$("#modalCheckExamination").modal("hide");

			let formData = new FormData();
			formData.append("registrationId", _registrationId);

			let formShow = "";
			if (_content === undefined && _destination === "laboratorium") {
				formShow = "#modalExaminationLab";
			} else if (_content === "nurse") {
				formData.append("method", "nurse");
				formShow = "#modalExaminationNurse";
			} else if (_content === "doctor") {
				formData.append("method", "doctor");
				formShow = "#modalExamination";
			}

			const data = {
				url: API_URL.examinationGet,
				method: "POST",
				data: formData,
				elementSelector: ArraySelector,
			};

			const response = Form.getFormData(data, formShow);

			handleGetMedicalTreatment(response);

			$(formShow)
				.find(".js-medical-record")
				.attr("data-id", $(formShow).find("#noMedicalRecord").val());

			if (
				!$(formShow).find('.nav-link[href="#tabSubjective"]').hasClass("active")
			) {
				$(formShow).find(".nav-link").removeClass("active");
				$(formShow).find('.nav-link[href="#tabSubjective"]').addClass("active");
				$(formShow).find(".tab-pane").removeClass("active show");
				$(formShow).find("#tabSubjective").addClass("active show");
			}

			if ($(formShow).find("#finalResult").val() == "Pasien Pulang") {
				$(formShow).find("#referType").attr("disabled", "disabled");
				$(formShow).find("#referDestination").attr("disabled", "disabled");
			} else if (!WHITESPACE.test($(formShow).find("#finalResult").val())) {
				$(formShow).find("#finalResult").val("Pasien Pulang");
				$(formShow).find("#referType").attr("disabled", "disabled");
				$(formShow).find("#referDestination").val("");
				$(formShow).find("#referDestination").attr("disabled", "disabled");
			} else {
				$(formShow).find("#referType").removeAttr("disabled");
				$(formShow).find("#referDestination").removeAttr("disabled");
			}

			if (
				!$(formShow)
					.find('.nav-link[href="#tabSubjectiveLab"]')
					.hasClass("active")
			) {
				$(formShow)
					.find('.nav-link[href="#tabSubjectiveLab"]')
					.removeClass("active");
				$(formShow)
					.find('.nav-link[href="#tabSubjectiveLab"]')
					.addClass("active");
				$(formShow)
					.find('.tab-pane[id="#tabSubjectiveLab"]')
					.removeClass("active show");
				$(formShow).find("#tabSubjectiveLab").addClass("active show");
			}
		});
	};

	// handleGetMedicalPersonal
	const handleGetMedicalPersonal = (search = 0) => {
		$.ajax({
			url: API_URL.medicalPersonelGet,
			method: "POST",
			dataType: "JSON",
			data: {
				search: search,
			},
			success: (response) => {
				const _data = response.data;
				// data doctor
				const _doctor = $(_data).filter((idx) => {
					return _data[idx].role === "1";
				});

				let _contentDoctor = `<li class="form-dropdown-item">
					<span class="form-dropdown-select" data-value="">Pilih Dokter</span>
				</li>`;
				$.each(_doctor, (i, v) => {
					_contentDoctor += `<li class="form-dropdown-item">
															<span class="form-dropdown-select" data-value="${v.name}" data-id="${v.medicalPersonelId}">
																${v.name}
																<br/>
																<span class="text-muted">${v.medicalPersonelCode}</span>
															</span>
														</li>`;
				});

				$.each(ElementModal, (i, v) => {
					$(v).find(".js-doctor-list").html(_contentDoctor);
				});

				// data nurse
				const _nurse = $(_data).filter((idx) => {
					return _data[idx].role !== "1";
				});

				let _contentNurse = `<li class="form-dropdown-item">
					<span class="form-dropdown-select" data-value="">Pilih Bidan/Perawat</span>
				</li>`;

				$.each(_nurse, (i, v) => {
					let position = "Dokter";
					switch (v.role) {
						case "2":
							position = "Perawat";
							break;
						case "3":
							position = "Bidan";
							break;
						default:
							position = "Dokter";
					}

					_contentNurse += `<li class="form-dropdown-item">
															<span class="form-dropdown-select" data-value="${v.name}" data-id="${v.medicalPersonelId}">
																${v.name}
																<br/>
																<span class="text-muted">${v.medicalPersonelCode} - ${position}</span>
															</span>
														</li>`;
				});

				$.each(ElementModal, (i, v) => {
					$(v).find(".js-nurse-list").html(_contentNurse);
				});
			},
		});
	};

	// handleSelectMedicalPersonel
	const handleSelectMedicalPersonel = () => {
		$("body").on("click", ".js-mp-list .form-dropdown-select", (e) => {
			const _this = $(e.currentTarget);
			const _val = _this.attr("data-value");
			const _id = _this.attr("data-id");
			const _parent = _this.parents(".form-dropdown");

			_parent.find(".js-mp-input").val(_val);
			_parent.find(".js-mp-input").attr("data-id", _id);
			_parent.find(".js-reset-dropdown").show();
		});

		$(".js-mp-input").on("input", (e) => {
			const _this = $(e.currentTarget);
			const _parent = _this.parent(".form-dropdown");
			const _val = _this.val();
			handleGetMedicalPersonal(_val);

			if ($(".js-mp-input").val().length > 0) {
				_parent.find(".js-reset-dropdown").show();
			} else {
				_parent.find(".js-reset-dropdown").hide();
			}
		});

		$(".js-reset-dropdown").on("click", (e) => {
			const _this = $(e.currentTarget);
			const _parent = _this.parents(".form-dropdown");
			_parent.find("input").val("");
			_parent.find(".js-reset-dropdown").hide();
			_parent.find("input").focus();

			handleGetMedicalPersonal();
		});
	};

	// handleGetLabSender
	const handleGetLabSender = (search = 0) => {
		$.ajax({
			url: API_URL.labSenderGet,
			method: "POST",
			dataType: "JSON",
			data: {
				search: search,
			},
			success: (response) => {
				const _data = response.data;
				const _parent = $("#modalExaminationLab");
				let _content = `<li class="form-dropdown-item">
					<span class="form-dropdown-select" data-value="">Pilih pengirim</span>
				</li>`;

				$.each(_data, (i, v) => {
					_content += `<li class="form-dropdown-item">
												<span class="form-dropdown-select" data-value="${v.name}">${v.name}</span>
												<button type="button" class="form-dropdown-remove" data-id="${v.labSenderId}">
													<i class="mdi mdi-trash-can-outline"></i>
												</button>
											</li>`;
				});

				_parent.find(".js-lab-sender-list").html(_content);
			},
		});
	};

	// handleAddLabSender
	const handleAddLabSender = () => {
		$("body").on("click", ".js-lab-sender-list .form-dropdown-btn", (e) => {
			let formData = new FormData();
			formData.append("labSenderName", $("#labSender").val());

			const data = {
				url: API_URL.labSenderAdd,
				method: "POST",
				data: formData,
			};

			const beforeSend = () => {
				const _loader = `<div class="spinner-border text-custom m-2" role="status"><span class="sr-only">Loading...</span></div>`;
				$(".js-lab-sender-list .form-dropdown-notif").html(_loader);
			};

			const response = HttpRequest.custom(data, beforeSend);

			if (response.code === 200) {
				$(".js-lab-sender-list .form-dropdown-notif").remove();
				$(".js-lab-sender-list").parent().find(".form-dropdown-input").val("");
				handleGetLabSender();
			} else {
				SweetAlert.config(response.message, "error");
			}
		});
	};

	// handleDeleteLabSender
	const handleDeleteLabSender = () => {
		$("body").on("click", ".js-lab-sender-list .form-dropdown-remove", (e) => {
			const _this = $(e.currentTarget);
			const labSenderId = _this.attr("data-id");
			let formData = new FormData();
			formData.append("labSenderId", labSenderId);

			const data = {
				url: API_URL.labSenderDelete,
				method: "POST",
				data: formData,
			};

			const response = HttpRequest.custom(data);

			if (response.code === 200) {
				$(".js-lab-sender-list").parent().find(".form-dropdown-input").val("");
				handleGetLabSender();
			} else {
				SweetAlert.config(response.message, "error");
			}
		});
	};

	// handleGetAnamnesis
	const handleGetAnamnesis = (search = 0) => {
		$.ajax({
			url: API_URL.anamnesisGet,
			method: "POST",
			dataType: "JSON",
			data: {
				search: search,
			},
			success: (response) => {
				const _data = response.data;
				let _content = `<li class="form-dropdown-item"><span class="form-dropdown-select" data-value="0">Pilih Anamnesa</span></li>`;
				if (_data.length > 0) {
					$.each(_data, (i, v) => {
						_content += `<li class="form-dropdown-item">
													<span class="form-dropdown-select" data-value="${v.anamnesis}" data-content="${v.anamnesis}">${v.anamnesis}</span>
													<button type="button" class="form-dropdown-remove" data-id="${v.id}"><i class="mdi mdi-trash-can-outline"></i></button>
												</li>`;
					});
				} else {
					_content = `<li class="form-dropdown-notif d-block">
												<button type="button" class="btn btn-block btn-custom waves-effect waves-light form-dropdown-btn">Tambah <i class="mdi mdi-plus-circle"></i></button>
											</li>`;
				}

				$(".js-anamnesis-list").html(_content);
			},
		});
	};

	// handleGetDiagnosis
	const handleGetDiagnosis = (search = null) => {
		$.ajax({
			url: API_URL.diagnosis,
			method: "GET",
			dataType: "JSON",
			data: {
				search: search,
				limit: 10,
			},
			beforeSend: function () {
				var _loader = `<li class="form-dropdown-item text-center p-2"><span class="custom-loader"><span></span><span></span><span></span><span></span></span> Memuat ....</li>`;

				$(".js-diagnosis-list").html(_loader);
			},
			success: (response) => {
				const _data = response.data;
				let _content = `<li class="form-dropdown-item">
				<span class="form-dropdown-select" data-value="0">Pilih Diagnosa</span>
				</li>`;

				if (response.code === 200) {
					$.each(_data, (i, v) => {
						_content += `<li class="form-dropdown-item">
							<span class="form-dropdown-select" data-value="${v.icdCode}" data-content="${v.icdCode} - ${v.nameEn}">${v.icdCode} - ${v.nameEn}</span>
						</li>`;
					});
				}

				$(".js-diagnosis-list").html(_content);
			},
			error: (response) => {
				SweetAlert.config(response.message, "error");
				$(".js-button-loader").attr("disabled", false);
				$(".js-button-loader").html(
					`<i class="mdi mdi-content-save-outline"></i> Simpan`
				);
				return response;
			},
			error: (error) => {
				$(".js-diagnosis-list").html(
					`<li class="form-dropdown-notif d-block p-3">Data not found!</li>`
				);
			},
		});
	};

	// handleSelectAnamnesis
	const handleSelectAnamnesis = () => {
		$("body").on("click", ".js-anamnesis-list .form-dropdown-select", (e) => {
			const _this = $(e.currentTarget);
			const _val = _this.attr("data-content");
			const _desc = $(".js-anamnesis-desc").val();
			const _arr = _desc.split("; ");
			let _checkVal = true;
			for (let i = 0; i < _arr.length; i++) {
				const value = _arr[i];
				if (value == _val) {
					_checkVal = false;
				}
			}
			if (_checkVal) {
				if (_desc.length > 0) {
					$(".js-anamnesis-desc").val(_val + "; " + _desc);
				} else {
					$(".js-anamnesis-desc").val(_val);
				}
				$(".js-anamnesis-input").val("");
			} else {
				Toastr.config("Data yang dipilih telah ditambahkan!", "warning");
			}
		});

		$(".js-anamnesis-input").on("input", (e) => {
			const _this = $(e.currentTarget);
			const _val = _this.val();
			handleGetAnamnesis(_val);
		});
	};

	// handleSelectDiagnosis
	const handleSelectDiagnosis = () => {
		$("body").on("click", ".js-diagnosis-list .form-dropdown-select", (e) => {
			const _this = $(e.currentTarget);
			const _val = _this.attr("data-value");
			const _name = _this.attr("data-content");
			const _id = $("#diagnosis").val();
			const _desc = $(".js-diagnosis-desc").val();

			let _checkVal = true;
			if (_desc.length > 0) {
				const _arr = _desc.split(" | ");
				for (let i = 0; i < _arr.length; i++) {
					if (_arr[i] == _name) {
						_checkVal = false;
					}
				}
			}

			if (_checkVal) {
				if (_desc.length > 0) {
					$("#diagnosis").val(_val + "," + _id);
					$(".js-diagnosis-desc").val(_name + " | " + _desc);
				} else {
					$("#diagnosis").val(_val);
					$(".js-diagnosis-desc").val(_name);
				}
				$(".js-diagnosis-input").val("");
			} else {
				Toastr.config("Data yang dipilih telah ditambahkan!", "warning");
			}
		});

		// input diagnosa
		$(".js-diagnosis-input").on("keyup", (e) => {
			const _this = $(e.currentTarget);
			const _val = _this.val();
			handleGetDiagnosis(_val);
		});

		// reset diagnosa
		$(".js-reset-diagnosis").on("click", (e) => {
			$(".js-diagnosis-desc").attr("data-id", "");
			$(".js-diagnosis-desc").val("");
			$(".js-diagnosis-input").val("");
		});
	};

	// handlePostAnamnesis
	const handlePostAnamnesis = () => {
		$("body").on("click", ".js-anamnesis-list .form-dropdown-btn", (e) => {
			const _this = $(e.currentTarget);
			let formData = new FormData();
			let parentId = "";
			if (_this.parents(".js-modal-custom-select").hasClass("show")) {
				parentId = _this.parents(".js-modal-custom-select").attr("id");
			}
			const anamnesis = $("#" + parentId)
				.find(".js-anamnesis-input")
				.val();
			formData.append("anamnesis", anamnesis);

			const data = {
				url: API_URL.anamnesisAdd,
				method: "POST",
				data: formData,
			};

			const beforeSend = () => {
				const _loader = `<div class="spinner-border text-custom m-2" role="status"><span class="sr-only">Loading...</span></div>`;
				$(".js-anamnesis-list .form-dropdown-notif").html(_loader);
			};

			const response = HttpRequest.custom(data, beforeSend);

			if (response.code === 200) {
				$(".js-anamnesis-list .form-dropdown-notif").remove();
				$(".js-anamnesis-list").parent().find(".form-dropdown-input").val("");
				handleGetAnamnesis();
			} else {
				SweetAlert.config(response.message, "error");
			}
		});
	};

	// handleDeleteAnamnesis
	const handleDeleteAnamnesis = () => {
		$("body").on("click", ".js-anamnesis-list .form-dropdown-remove", (e) => {
			const _this = $(e.currentTarget);
			const anamnesisId = _this.attr("data-id");
			let formData = new FormData();
			formData.append("anamnesisId", anamnesisId);

			const data = {
				url: API_URL.anamnesisDelete,
				method: "POST",
				data: formData,
			};

			const response = HttpRequest.custom(data);

			if (response.code === 200) {
				$(".js-anamnesis-list").parent().find(".form-dropdown-input").val("");
				handleGetAnamnesis();
			} else {
				SweetAlert.config(response.message, "error");
			}
		});
	};

	// handleGetOdontogram
	const handleGetOdontogram = (response) => {
		const _code = response.code;
		const _data = response.data;
		if (_code === 200) {
			if (_data.odontogram) {
				$.each(JSON.parse(_data.odontogram), (i, v) => {
					const _arr = v.split(",");
					$.each(_arr, (ia, va) => {
						$(`#k${i}-${va}`).attr("checked", "checked");
					});
				});
			}
		}
	};

	// handleGetMedicalTreatment
	const handleGetMedicalTreatment = (response) => {
		const _code = response.code;
		const _data = response.data;
		if (_code === 200) {
			let _content = "";
			$.each(_data.medicalTreatment, (i, v) => {
				_content += `<tr data-id="${v.medicineId}" data-unit="${v.unit}" data-purchase="${v.purchasePrice}" data-price="${v.price}">
										<td>${v.name}</td>
										<td>
											<div class="qty js-qty">
												<button type="button" class="btn btn-icon btn-custom waves-effect waves-light js-dec-qty"><i class="mdi mdi-minus"></i></button>
												<input type="text" class="js-total-qty" value="${v.qty}" min="1" />
												<button type="button" class="btn btn-icon btn-custom waves-effect waves-light js-inc-qty"><i class="mdi mdi-plus"></i></button>
											</div>
										</td>
										<td>${v.unit}</td>
										<td><input type="text" class="form-control js-treatment-dosis" value="${v.dosis}"></td>
										<td class="text-center">
											<button type="button" data-toggle="tooltip" data-placement="left" title="Delete" class="btn btn-icon waves-effect btn-danger btn-trans js-delete-treatment"><i class="mdi mdi-trash-can-outline"></i></button>
										</td>
									</tr>`;
			});

			$(".js-medical-treatment").html(_content);
		}
	};

	// handleGetMedicine
	const handleGetMedicine = () => {
		$.ajax({
			url: API_URL.medicineGet,
			method: "POST",
			dataType: "JSON",
			data: {
				type: "!3",
			},
			success: (response) => {
				const _data = response.data;
				let _content = `<li class="form-dropdown-item">
													<span class="form-dropdown-select" data-value="">Pilih Obat</span>
												</li>`;

				if (_data.length > 0) {
					$.each(_data, (i, v) => {
						_content += `<li class="form-dropdown-item">
													<div class="form-dropdown-select d-flex justify-content-between align-item-center" data-value="${
														v.name
													}" data-id="${v.medicineId}" data-unit="${
							v.unit
						}" data-purchase="${v.purchasePrice}" data-price="${v.sellPrice}">
														<div>
															<p class="mb-0">${v.name}</p>
															<p class="mb-0 text-muted text-capitalize">${
																v.category !== null ? v.category : "-"
															}</p>
														</div>
														<div>
															<p class="mb-0 text-right">${v.sellPrice}</p>
															<p class="mb-0 text-right text-muted text-capitalize">${
																v.unit !== null ? v.unit : "-"
															}</p>
														</div>
													</div>
												</li>`;
					});
				}

				$(".js-medicine-list").html(_content);
			},
		});
	};

	// handleSelectMedicine
	const handleSelectMedicine = () => {
		$("body").on("click", ".js-medicine-list .form-dropdown-select", (e) => {
			const _this = $(e.currentTarget);
			const _name = _this.attr("data-value");
			const _id = _this.attr("data-id");
			const _unitAttr = _this.attr("data-unit");
			const _unit = _unitAttr !== "null" ? _unitAttr : "-";
			const _purchase = _this.attr("data-purchase");
			const _price = _this.attr("data-price");
			const _notif = $(".js-medical-treatment")
				.find('td[colspan="8"]')
				.parent();
			if ($("#modalExamination").find(".js-grand-total").length) {
				const _total = Currency.remove_rp($(".js-grand-total").val());
				const _grandTotal = Currency.format_rp(_price + _total);
				$(".js-grand-total").val(Currency.format_rp(_grandTotal));
			}
			let _qty = 1;

			if ($(".js-medical-treatment").find("tr").length > 0) {
				$(".js-medical-treatment")
					.find("tr")
					.each((i, e) => {
						const _this = $(e);
						if (_name == _this.find(".js-treatment-name").text()) {
							_qty = _qty + parseInt(_this.find(".js-total-qty").val());
							_this.find(".js-total-qty").val(_qty);
							if ($("#modalExamination").find(".js-grand-total").length) {
								_this
									.find(".js-treatment-subtotal")
									.text(Currency.format_rp(_price * _qty));
							}
						}
					});
			}

			const _content = `<tr data-id="${_id}" data-unit="${_unit}" data-purchase="${_purchase}" data-price="${_price}">
													<td class="js-treatment-name">${_name}</td>
													<td>
														<div class="qty js-qty">
															<button type="button" class="btn btn-icon btn-custom waves-effect waves-light js-dec-qty"><i class="mdi mdi-minus"></i></button>
															<input type="text" class="js-total-qty" value="1" min="1" />
															<button type="button" class="btn btn-icon btn-custom waves-effect waves-light js-inc-qty"><i class="mdi mdi-plus"></i></button>
														</div>
													</td>
													<td>${_unit}</td>
													<td><input type="text" class="form-control js-treatment-dosis"></td>
													<td class="text-center">
														<button type="button" data-toggle="tooltip" data-placement="left" title="Delete" class="btn btn-icon waves-effect btn-danger btn-trans js-delete-treatment"><i class="mdi mdi-trash-can-outline"></i></button>
													</td>
												</tr>`;

			if (_qty === 1) {
				$(".js-medical-treatment").append(_content);
			}
			$(".js-medicine-list").parent().find(".form-dropdown-input").val("");
			if (_notif.length) {
				_notif.remove();
			}
		});

		// handle qty row
		$("body").on("click", ".js-qty button", (e) => {
			const _this = $(e.currentTarget);
			const _val = _this.parents("tr").find(".js-total-qty").val();
			const _price = 0;
			const _grandVal = 0;
			if ($(".js-grand-total").length) {
				_price = Currency.remove_rp(
					_this.parents("tr").find(".js-treatment-price").text()
				);
				_grandVal = Currency.remove_rp($(".js-grand-total").val());
			}

			let _total = 0;
			let _grandTotal = 0;
			if (_this.hasClass("js-inc-qty")) {
				_total = parseFloat(_val) + 1;
				if ($(".js-grand-total").length) {
					_grandTotal = Currency.format_rp(_grandVal + _price);
				}
			} else {
				// Don't allow decrementing below zero
				if ($(".js-grand-total").length) {
					_grandTotal = Currency.format_rp(_grandVal - _price);
				}
				if (_val > 1) {
					_total = parseFloat(_val) - 1;
				} else {
					_total = 1;
				}
			}

			_this.parents("tr").find(".js-total-qty").val(_total);

			if ($(".js-grand-total").length) {
				const _subTotal = Currency.format_rp(_price * _total);
				_this.parents("tr").find(".js-treatment-subtotal").text(_subTotal);
				$(".js-grand-total").val(_grandTotal);
			}
		});

		// handle delete row
		$("body").on("click", ".js-delete-treatment", (e) => {
			const _this = $(e.currentTarget);
			const _content = `<tr class="row-notif"><td colspan="8" class="text-center"><span>Data not found</span></td></tr>`;

			_this.parents("tr").remove();

			if ($(".js-medical-treatment tr").length == 0) {
				$(".js-medical-treatment").html(_content);
			}
		});
	};

	const handleGetLaboratorium = () => {
		$.ajax({
			url: API_URL.medicineGet,
			method: "POST",
			dataType: "JSON",
			data: {
				type: "3",
			},
			success: (response) => {
				const _data = response.data;
				let _content = `<li class="form-dropdown-item">
													<span class="form-dropdown-select" data-value="">Pilih Obat</span>
												</li>`;

				if (_data.length > 0) {
					$.each(_data, (i, v) => {
						_content += `<li class="form-dropdown-item">
													<div class="form-dropdown-select d-flex justify-content-between align-item-center" data-value="${v.name}" data-id="${v.medicineId}" data-unit="${v.unit}" data-purchase="${v.purchasePrice}" data-price="${v.sellPrice}">
														<div>
															<p class="mb-0">${v.name}</p>
														</div>
														<div>
															<p class="mb-0 text-right">${v.sellPrice}</p>
														</div>
													</div>
												</li>`;
					});
				}

				$(".js-laboratorium-list").html(_content);
			},
		});
	};

	const handleSelectLaboratorium = () => {
		$("body").on(
			"click",
			".js-laboratorium-list .form-dropdown-select",
			(e) => {
				const _this = $(e.currentTarget);
				const _parent = $("#modalExaminationLab");
				const _medicineId = _this.attr("data-id");
				const _gender = _parent.find("#genderType").val();
				const _age = parseInt(_parent.find("#age").val());
				const _labName = _parent.find(".js-laboratorium-input").val();

				let _checkVal = true;
				_parent
					.find(".js-treatment-lab")
					.find("tr")
					.each((i, e) => {
						const _this = $(e);
						const _valId = _this.attr("data-lab");
						if (_medicineId == _valId) {
							_checkVal = false;
						}
					});

				if (_checkVal) {
					let genderName = _gender === "1" ? "Laki-laki" : "Perempuan";
					let genderCategory = "1";
					let genderCategoryName = "Bayi";
					if (_age <= 2) {
						genderCategory = "2";
						genderCategoryName = "Anak";
					} else if (_age <= 11) {
						genderCategory = "3";
						genderCategoryName = "Remaja";
					} else {
						genderCategory = "4";
						genderCategoryName = "Dewasa";
					}

					let formData = new FormData();
					formData.append("medicineId", _medicineId);
					formData.append("type", _gender);
					formData.append("category", genderCategory);

					const data = {
						url: API_URL.labGet,
						method: "POST",
						data: formData,
					};

					let response = HttpRequest.custom(data);

					if (response.data.length) {
						let _content = "";
						$.each(response.data, (i, v) => {
							_content += `<tr data-lab="${v.medicineId}">
														<td>${v.name}</td>
														<td>${v.labName}</td>
														<td><input type="text" class="form-control js-result-lab" /></td>
														<td>${v.normal + " " + v.unit}</td>
														<td>
															<div class="dropdown pull-right">
																<a href="#" class="btn dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false" style="font-size: 18px">
																	<i class="mdi mdi-dots-vertical"></i>
																</a>
																<div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end">
																	<a href="javascript:void(0);" class="dropdown-item js-delete-single-lab" data-id=""><i class="mdi mdi-trash-can-outline"></i> Hapus ${
																		v.labName
																	}</a>
																	<a href="javascript:void(0);" class="dropdown-item js-delete-group-lab" data-id="${
																		v.medicineId
																	}"><i class="mdi mdi-trash-can-outline"></i> Hapus ${
								v.name
							}</a>
																</div>
															</div>
														</td>
													</tr>`;
						});

						_parent.find(".js-treatment-lab").find(".row-notif").remove();
						_parent.find(".js-treatment-lab").append(_content);
						_parent.find(".js-laboratorium-input").val("");
					} else {
						const _message = `Pemerikaaan ${_labName} untuk ${genderName} ${genderCategoryName} tidak tersedia!`;
						SweetAlert.config(_message, "warning");
						_parent.find(".js-laboratorium-input").val("");
					}
				} else {
					Toastr.config("Data yang dipilih telah ditambahkan!", "warning");
				}
			}
		);
	};

	// handleDeleteDetailLaboratorium
	const handleDeleteDetailLaboratorium = () => {
		// handle delete single data
		$("body").on("click", ".js-delete-single-lab", (e) => {
			const _this = $(e.currentTarget);
			_this.parents("tr").remove();

			if ($(".js-treatment-lab tr").length === 0) {
				let _content = `<tr class="row-notif">
													<td colspan="8" class="text-center"><span>Data pemeriksaan belum ditambahkan</span></td>
												</tr>`;

				$(".js-treatment-lab").html(_content);
			}
		});

		// handle delete group data
		$("body").on("click", ".js-delete-group-lab", (e) => {
			const _this = $(e.currentTarget);
			const _parent = $("#modalExaminationLab");
			const _medicineId = _this.attr("data-id");

			_parent
				.find(".js-treatment-lab")
				.find("tr")
				.each((i, e) => {
					const _this = $(e);
					const _valId = _this.attr("data-lab");
					if (_medicineId == _valId) {
						_this.remove();
					}
				});

			if ($(".js-treatment-lab tr").length === 0) {
				let _content = `<tr class="row-notif">
													<td colspan="8" class="text-center"><span>Data pemeriksaan belum ditambahkan</span></td>
												</tr>`;

				$(".js-treatment-lab").html(_content);
			}
		});
	};

	// handleGetPolyclinic
	const handleGetPolyclinic = (search = 0) => {
		let formData = new FormData();
		formData.append("search", search);
		const data = {
			url: API_URL.polyclinicGet,
			method: "POST",
			data: formData,
		};

		const response = HttpRequest.custom(data);

		if (response.code === 200) {
			const _data = response.data;
			let _content = `<li class="form-dropdown-item">
				<span class="form-dropdown-select" data-value="0">Pilih Anamnesa</span>
				</li>`;
			if (_data.length > 0) {
				$.each(_data, (i, v) => {
					_content += `<li class="form-dropdown-item">
							<span class="form-dropdown-select" data-value="${v.name}" data-id="${v.polyclinicId}">${v.name}</span>
						</li>`;
				});
			} else {
				_content = `<li class="form-dropdown-notif d-block">Data not found!</li>`;
			}

			$(".js-objective-list").html(_content);
		} else {
			SweetAlert.config(response.message, "error");
		}
	};

	// handleGetReference
	const handleGetReference = (search = 0) => {
		let formData = new FormData();
		formData.append("search", search);
		const data = {
			url: API_URL.referenceGet,
			method: "POST",
			data: formData,
		};

		const response = HttpRequest.custom(data);

		if (response.code === 200) {
			const _data = response.data;
			let _content = `<li class="form-dropdown-item"><span class="form-dropdown-select" data-value="0">Pilih Tujuan</span></li>`;
			if (_data.length > 0) {
				$.each(_data, (i, v) => {
					_content += `<li class="form-dropdown-item">
													<span class="form-dropdown-select" data-value="${v.name}" data-id="${v.referenceId}">${v.name}</span>
													<button type="button" class="form-dropdown-remove" data-id="${v.referenceId}"><i class="mdi mdi-trash-can-outline"></i></button>
												</li>`;
				});
			} else {
				_content = `<li class="form-dropdown-notif d-block">
												<button type="button" class="btn btn-block btn-custom waves-effect waves-light form-dropdown-btn">Tambah <i class="mdi mdi-plus-circle"></i></button>
											</li>`;
			}

			$(".js-objective-list").html(_content);
			$("#referDestination").val("");
		} else {
			SweetAlert.config(response.message, "error");
		}
	};

	// handlePostReference
	const handlePostReference = () => {
		$("body").on("click", ".js-objective-list .form-dropdown-btn", (e) => {
			let formData = new FormData();
			formData.append("referType", $("#referDestination").val());

			const data = {
				url: API_URL.referenceAdd,
				method: "POST",
				data: formData,
			};

			const beforeSend = () => {
				const _loader = `<div class="spinner-border text-custom m-2" role="status"><span class="sr-only">Loading...</span></div>`;
				$(".js-objective-list .form-dropdown-notif").html(_loader);
			};

			const response = HttpRequest.custom(data, beforeSend);

			if (response.code === 200) {
				$(".js-objective-list .form-dropdown-notif").remove();
				$(".js-objective-list").parent().find(".form-dropdown-input").val("");
				handleGetReference();
			} else {
				SweetAlert.config(response.message, "error");
			}
		});

		$("#referDestination").on("input", (e) => {
			const _this = $(e.currentTarget);
			const _val = _this.val();
			if ($("#referType").val() == "rujuk internal") {
				handleGetPolyclinic(_val);
			} else {
				handleGetReference(_val);
			}
		});
	};

	// handleDeleteReference
	const handleDeleteReference = () => {
		$("body").on("click", ".js-objective-list .form-dropdown-remove", (e) => {
			const _this = $(e.currentTarget);
			const referenceId = _this.attr("data-id");
			let formData = new FormData();
			formData.append("referenceId", referenceId);

			const data = {
				url: API_URL.referenceDelete,
				method: "POST",
				data: formData,
			};

			const response = HttpRequest.custom(data);

			if (response.code === 200) {
				$(".js-objective-list").parent().find(".form-dropdown-input").val("");
				handleGetReference();
			} else {
				SweetAlert.config(response.message, "error");
			}
		});
	};

	// handleFinalResult
	const handleFinalResult = () => {
		$("#finalResult").on("change", (e) => {
			const _this = $(e.currentTarget);
			const _val = _this.val();
			if (_val == "Pasien Pulang") {
				$("#referType").attr("disabled", "disabled");
				$("#referType").val("0");

				$("#referDestination").attr("disabled", "disabled");
				$("#referType").val("");
			} else {
				$("#referType").removeAttr("disabled");
				$("#referDestination").removeAttr("disabled");
				if (_val == "Rawat Inap") {
					$("#referType option[value='rujuk internal']").attr(
						"selected",
						"selected"
					);
					if (_this.parents(".form-dropdown").hasClass("form-dropdown-add")) {
						_this.parents(".form-dropdown").removeClass("form-dropdown-add");
					}
					handleGetPolyclinic();
				}
			}
		});

		$("#referType").on("change", (e) => {
			const _this = $(e.currentTarget);
			const _val = _this.val();
			if (_val == "1") {
				if (_this.parents(".form-dropdown").hasClass("form-dropdown-add")) {
					_this.parents(".form-dropdown").removeClass("form-dropdown-add");
				}
				handleGetPolyclinic();
			} else {
				if (!_this.parents(".form-dropdown").hasClass("form-dropdown-add")) {
					_this.parents(".form-dropdown").addClass("form-dropdown-add");
				}
				handleGetReference();
			}
		});

		$("body").on("click", ".js-objective-list .form-dropdown-select", (e) => {
			const _this = $(e.currentTarget);
			const _id = _this.attr("data-id");
			$("#referDestination").attr("data-id", _id);
		});
	};

	// handleCalculateImt
	const handleCalculateImt = () => {
		$.each(ElementModal, (i, v) => {
			$(v)
				.find("#bb")
				.on("keyup", (e) => {
					const _this = $(e.currentTarget);
					const _val = _this.val();
					const _tb = $(v).find("#tb").val() / 100;
					const _kuad = _tb * _tb;
					const _imt = _val / _kuad.toFixed(2);
					$(v).find("#imt").val(_imt.toFixed(1));
				});
		});
	};

	// handleGetHistoryPatient
	const handleGetHistoryPatient = () => {
		$("body").on("click", ".js-medical-record", (e) => {
			const _this = $(e.currentTarget);
			const _noMedicalRecord = _this.attr("data-id");

			$("#modalHistoryPatient").modal("show");

			$.ajax({
				url: API_URL.examinationHistory,
				method: "POST",
				dataType: "JSON",
				data: {
					noMedicalRecord: _noMedicalRecord,
				},
				success: (response) => {
					const _data = response.data;
					const _history = _data.history;
					const _patient = _data.patient;

					// history patient
					let _content = "";
					let _physicalExam = "";
					let _medicalTreatment = "";
					let _odontogram = "";
					if (_history.length > 0) {
						$.each(_history, (i, v) => {
							$.each(v.physicalExam, (ie, ve) => {
								if (ve !== null) {
									_physicalExam += `<li>${ie}: ${ve}</li>`;
								}
							});

							$.each(v.medicalTreatment, (ie, ve) => {
								_medicalTreatment += `${ve.name} ${ve.dosis}, `;
							});

							let _contgram = "";
							if (v.odontogram) {
								$.each(JSON.parse(v.odontogram), (ie, ve) => {
									_odontogram += `<li>${ie}.${ve.replace(",", ", ")}</li>`;
								});
								_contgram = `<td><ul class="list-column">${_odontogram}</ul></td>`;
							} else {
								_contgram = `<td>-</td>`;
							}

							_content += `<tr>
														<td>${v.examineDate}</td>
														<td>
															<p class="mb-0">${v.doctor}<br /><span class="text-muted">${
								v.polyclinicName
							}</span></p>
														</td>
														${_contgram}
														<td>${v.anamnesis}</td>
														<td>
															<ul class="list-column">${_physicalExam}</ul>
														</td>
														<td>${v.diagnosis}</td>
														<td>
																${_medicalTreatment.slice(0, -1)}
														</td>
														<td>${v.konseling}</td>
													</tr>`;
						});
					} else {
						_content = `<tr class="row-notif">
													<td colspan="8" class="text-center"><span>Data not found</span></td>
												</tr>`;
					}

					$(".js-history-patient").html(_content);
					$(".report-profile__img").attr({
						src: _patient.photo,
						alt: _patient.name,
					});
					$("#profileRekmed").text(_patient.noMedicalRecord);
					$("#profileNik").text(_patient.nik);
					$("#profileName").text(_patient.name);
					$("#profileBirthDate").text(_patient.birthDate);
					$("#profileAddress").text(_patient.address);
					$("#profileGender").text(_patient.gender);
					$("#profileStatusBpjs").text(_patient.statusAssurance);
					$("#profileNoBpjs").text(_patient.assuranceNumber);
					$("#profileKK").text(_patient.kkName);
					$("#profileStatus").text(_patient.statusPatient);
				},
			});
		});
	};

	// init
	const init = () => {
		if ($(".js-data-examination").length) {
			handleRunDataTable();
			handleDeleteData();
			handleGetFormData();
			handleGetSingleData();
			handleCheckExamination();
		}
		if ($(".js-nurse-list").length || $(".js-doctor-list").length) {
			handleGetMedicalPersonal();
			handleSelectMedicalPersonel();
		}
		if ($(".js-lab-sender-list").length) {
			handleGetLabSender();
			handleAddLabSender();
			handleDeleteLabSender();
		}
		if ($(".js-form-examination").length) {
			handleRunValidation();
			handleClickValidation();
			handleEmptyInput();
			handleCalculateImt();
		}
		if ($(".js-anamnesis-list").length) {
			handleGetAnamnesis();
			handleSelectAnamnesis();
			handlePostAnamnesis();
			handleDeleteAnamnesis();
		}
		if ($(".js-diagnosis-list").length) {
			handleGetDiagnosis();
			handleSelectDiagnosis();
		}
		if ($(".js-medical-treatment").length) {
			handleSelectMedicine();
		}
		if ($(".js-medicine-list").length) {
			handleGetMedicine();
		}
		if ($(".js-laboratorium-list").length) {
			handleGetLaboratorium();
			handleSelectLaboratorium();
			handleDeleteDetailLaboratorium();
		}
		if ($(".js-objective-list").length) {
			handlePostReference();
			handleDeleteReference();
			handleFinalResult();
		}
		if ($(".js-history-patient").length) {
			handleGetHistoryPatient();
		}
	};

	return {
		init,
	};
})();

export default Examination;
