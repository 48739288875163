/* ------------------------------------------------------------------------------
@name: ReportCashFlow
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";

// --- utilities
import { FormatDate, Currency, HttpRequest } from "utilities";

const ReportCashFlow = (() => {
	// handleRunTableCashFlow
	const handleRunTableCashFlow = () => {
		let formData = new FormData();
		const date = $("#dateRange").val();
		const startDate = FormatDate.range(date).startDate;
		const endDate = FormatDate.range(date).endDate;
		formData.append("startDate", startDate);
		formData.append("endDate", endDate);

		const data = {
			url: API_URL.cashFlowGet,
			method: "POST",
			data: formData,
		};
		let response = HttpRequest.custom(data);
		var _data = response.data;
		if (response.status) {
			if (_data.cash_in.length || _data.cash_out.length) {
				var _contentIncome = "",
					_contentExpense = "",
					_listIncome = _data.cash_in,
					_itemIncome = "",
					_footerIncome = "",
					_listExpense = _data.cash_out,
					_itemExpense = "",
					_footerExpense = "",
					_footerCashTotal = "";

				var _totalIncome = 0,
					_totalExpense = 0;

				$.each(_listIncome, function (i, v) {
					_totalIncome += parseFloat(v.income);
					_itemIncome += `<tr>
                                    <td>${v.category}</td>
                                    <td class="text-right">${Currency.format_rp(
																			Math.floor(v.income)
																		)}</td>
                                  </tr>`;

					_footerIncome = `<tr class="table-custom__footer">
                                    <th>Total Pendapatan</th>
                                    <th class="text-right">${Currency.format_rp(
																			_totalIncome
																		)}</th>
                                  </tr>`;

					_contentIncome = `<table class="table table-custom">
                                      <thead>
                                        <tr>
                                          <th>Pendapatan</th>
                                          <th width="250" class="text-right">Total</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        ${_itemIncome}
                                        ${_footerIncome}
                                      </tbody>
                                    </table>`;
				});

				$.each(_listExpense, function (i, v) {
					_totalExpense += parseFloat(v.expense);
					_itemExpense += `<tr>
                                    <td>${v.category}</td>
                                    <td class="text-right">${Currency.format_rp(
																			Math.floor(v.expense)
																		)}</td>
                                  </tr>`;

					_footerExpense = `<tr class="table-custom__footer">
                                      <th>Total Pengeluaran</th>
                                      <th class="text-right">${Currency.format_rp(
																				_totalExpense
																			)}</th>
                                    </tr>`;

					_contentExpense = `<table class="table table-custom">
                                      <thead>
                                        <tr>
                                          <th>Pengeluaran</th>
                                          <th class="text-right">Total</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        ${_itemExpense}
                                        ${_footerExpense}
                                      </tbody>
                                    </table>`;
				});

				_footerCashTotal += `<div class="footer-report">
                                  <p class="footer-report__label">Total Kas Tersedia</p>
                                  <p class="footer-report__total">${Currency.format_rp(
																		_totalIncome - _totalExpense,
																		"Rp ",
																		true
																	)}</p>
                                </div>`;

				$(".card-box").find(".row").show();
				$(".card-box").find(".not-found").hide();
				$(".js-report-cash-flow").html(
					_contentIncome + _contentExpense + _footerCashTotal
				);
			} else {
				$(".card-box").find(".row").hide();
				$(".card-box").find(".not-found").show();
			}
			$(".card-box").removeClass("skeleton");
		}
	};

	// handleFilterDate
	const handleFilterDate = () => {
		$("body").on("change", ".js-filter-cash", () => {
			handleRunTableCashFlow();
		});
	};

	// init
	const init = () => {
		if ($(".js-report-cash-flow").length) {
			handleRunTableCashFlow();
		}
		if ($(".js-filter-cash").length) {
			handleFilterDate();
		}
	};

	return {
		init,
	};
})();

export default ReportCashFlow;
