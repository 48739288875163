/* ------------------------------------------------------------------------------
@name: Medicine
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";

// --- utilities
import { DataTable, Form, HttpRequest, SweetAlert } from "utilities";

// Form Validation
const ElementSelector = [
	{
		id: "medicineId",
	},
	{
		id: "name",
		validation: {
			required: true,
		},
	},
	{
		id: "category",
		validation: {
			required: true,
		},
	},
	{
		id: "unit",
		validation: {
			required: true,
		},
	},
	{
		id: "sellPrice",
		validation: {
			required: true,
		},
	},
	{
		id: "purchasePrice",
		validation: {
			required: true,
		},
	},
	{
		id: "stockMin",
		validation: {
			required: true,
		},
	},
	{
		id: "stock",
		validation: {
			required: true,
		},
	},
];

const ElementEvents = ["input", "blur"];

const Medicine = (() => {
	// Handle Run DataTable
	const handleRunDataTable = () => {
		const dataSetting = {
			selector: "js-data-medicine",
			url: API_URL.medicineGet,
			method: "POST",
			data: (d) => {
				return $.extend({}, d, {
					category: $("#medicineCategory").val(),
				});
			},
		};

		const filterSetting = [
			{
				id: "medicineCategory",
				event: "change",
			},
			{
				id: "search",
				event: "keyup",
			},
		];

		const columnSetting = [
			{
				targets: [5, 6],
				className: "text-right",
			},
			{
				targets: 7,
				className: "text-center",
			},
			{
				targets: 8,
				className: "text-center",
				render: (dataSetting) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Edit" class="btn btn-icon waves-effect btn-primary btn-trans js-edit-data" data-id="${dataSetting}"><i class="mdi mdi-pencil-outline"></i></button>
					<button type="button" data-toggle="tooltip" data-placement="left" title="Delete" class="btn btn-icon waves-effect btn-danger btn-trans js-delete-data" data-id="${dataSetting}"><i class="mdi mdi-trash-can-outline"></i></button>`;
				},
			},
		];

		const sortSetting = {
			id: "sort",
			event: "change",
		};

		const columnVisbleSetting = {
			target: [7],
			visble: true,
		};

		DataTable.custom(
			dataSetting,
			filterSetting,
			columnSetting,
			sortSetting,
			columnVisbleSetting
		);
	};

	// Handle Run Validation
	const handleRunTableMedicine = () => {
		const dataSetting = {
			selector: "js-data-medicine-list",
			url: API_URL.medicineInGet,
			method: "POST",
			data: (d) => {
				return $.extend({}, d, {
					category: $("#medicineCategory").val(),
				});
			},
		};

		const filterSetting = [
			{
				id: "medicineCategory",
				event: "change",
			},
			{
				id: "search",
				event: "keyup",
			},
		];

		const columnSetting = [
			{
				targets: 5,
				className: "text-right",
			},
			{
				targets: 6,
				className: "text-center",
			},
			{
				targets: 7,
				className: "text-center",
				render: (data) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Edit" class="btn btn-icon waves-effect btn-success btn-trans js-select-medicine" data-id="${data.medicineId}" data-name="${data.name}" data-unit="${data.unit}" data-price="${data.price}"><i class="mdi mdi-plus"></i></button>`;
				},
			},
		];

		const sortSetting = {
			id: "sort",
			event: "change",
		};

		DataTable.custom(dataSetting, filterSetting, columnSetting, sortSetting);
	};

	// Handle Run Validation
	const handleRunValidation = () => {
		Form.validation(ElementEvents, ElementSelector);
	};

	// Handle Click Validation
	const handleClickValidation = () => {
		$('.js-form-medicine button[type="submit"]').on("click", (e) => {
			$.each(ElementSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".error").length === 0) {
				handlePostData();
			}
			e.preventDefault();
		});
	};

	// handleEmptyInput
	const handleEmptyInput = () => {
		$('.js-form-medicine button[data-confirm-close="modal"]').on(
			"click",
			(e) => {
				Form.emptyData(ElementSelector, true);
			}
		);
	};

	// handlePostData
	const handlePostData = () => {
		const formData = Form.dataColletion(ElementSelector);
		let apiUrl = API_URL.medicineAdd;
		if ($("#medicineId").val().length !== 0) {
			apiUrl = API_URL.medicineEdit;
		}

		const data = {
			url: apiUrl,
			method: "POST",
			data: formData,
		};

		HttpRequest.default(data);
		Form.emptyData(ElementSelector);
	};

	const handleGetFormData = () => {
		$("body").on("click", ".js-edit-data", (e) => {
			let formData = new FormData();
			const _this = $(e.currentTarget);
			const medicineId = _this.attr("data-id");
			formData.append("medicineId", medicineId);

			const data = {
				url: API_URL.medicineGet,
				method: "POST",
				data: formData,
				elementSelector: ElementSelector,
			};

			Form.getFormData(data);
		});
	};

	// handleDeleteData
	const handleDeleteData = () => {
		$("body").on("click", ".js-delete-data", (e) => {
			let formData = new FormData();
			const _this = $(e.currentTarget);
			const medicineId = _this.attr("data-id");
			formData.append("medicineId", medicineId);

			const data = {
				url: API_URL.medicineDelete,
				method: "POST",
				data: formData,
			};

			Form.deleteData(data);
		});
	};

	// handleGetCategory
	const handleGetCategory = () => {
		$.ajax({
			url: API_URL.medicineCategoryGet,
			method: "POST",
			dataType: "JSON",
			data: {
				type: "1",
			},
			success: (response) => {
				const _data = response.data;
				let _content = `<li class="form-dropdown-item"><span class="form-dropdown-select" data-value="">Pilih kategori</span></li>`;

				$.each(_data, (i, v) => {
					_content += `<li class="form-dropdown-item">
						<span class="form-dropdown-select" data-value="${v.name}">${v.name}</span>
						<button type="button" class="form-dropdown-remove" data-id="${v.categoryId}">
							<i class="mdi mdi-trash-can-outline"></i>
						</button>
					</li>`;
				});
				$(".js-medicine-category").html(_content);

				let _contentST = `<option value="0">Pilih Kategori</option>`;
				$.each(_data, (i, v) => {
					_contentST += `<option value="${v.name}">${v.name}</option>`;
				});

				$("#medicineCategory").html(_contentST);
			},
		});
	};

	// handlePostCategory
	const handlePostCategory = () => {
		$("body").on("click", ".js-medicine-category .form-dropdown-btn", (e) => {
			let formData = new FormData();
			formData.append("type", "1");
			formData.append("category", $("#category").val());

			const data = {
				url: API_URL.medicineCategoryAdd,
				method: "POST",
				data: formData,
			};

			const beforeSend = () => {
				const _loader = `<div class="spinner-border text-custom m-2" role="status"><span class="sr-only">Loading...</span></div>`;
				$(".js-medicine-category .form-dropdown-notif").html(_loader);
			};

			const response = HttpRequest.custom(data, beforeSend);

			if (response.code === 200) {
				$(".js-medicine-category .form-dropdown-notif").remove();
				$(".js-medicine-category")
					.parent()
					.find(".form-dropdown-input")
					.val("");

				handleGetCategory();
			} else {
				SweetAlert.config(response.message, "error");
			}
		});
	};

	// handleDeleteCategory
	const handleDeleteCategory = () => {
		$("body").on(
			"click",
			".js-medicine-category .form-dropdown-remove",
			(e) => {
				const _this = $(e.currentTarget);
				const categoryId = _this.attr("data-id");
				let formData = new FormData();
				formData.append("categoryId", categoryId);

				const data = {
					url: API_URL.medicineCategoryDelete,
					method: "POST",
					data: formData,
				};

				const response = HttpRequest.custom(data);

				if (response.code === 200) {
					$(".js-medicine-category")
						.parent()
						.find(".form-dropdown-input")
						.val("");

					handleGetCategory();
				} else {
					SweetAlert.config(response.message, "error");
				}
			}
		);
	};

	// handleGetUnit
	const handleGetUnit = () => {
		$.ajax({
			url: API_URL.medicineUnitGet,
			method: "POST",
			dataType: "JSON",
			success: (response) => {
				const _data = response.data;
				let _content = `<li class="form-dropdown-item"><span class="form-dropdown-select" data-value="">Pilih Satuan</span></li>`;
				$.each(_data, (i, v) => {
					_content += `<li class="form-dropdown-item">
						<span class="form-dropdown-select" data-value="${v.name}">${v.name}</span>
						<button type="button" class="form-dropdown-remove" data-id="${v.unitId}">
							<i class="mdi mdi-trash-can-outline"></i>
						</button>
					</li>`;
				});

				$(".js-medicine-unit").html(_content);
			},
		});
	};

	// handlePostUnit
	const handlePostUnit = () => {
		$("body").on("click", ".js-medicine-unit .form-dropdown-btn", (e) => {
			let formData = new FormData();
			formData.append("unit", $("#unit").val());

			const data = {
				url: API_URL.medicineUnitAdd,
				method: "POST",
				data: formData,
			};

			const beforeSend = () => {
				const _loader = `<div class="spinner-border text-custom m-2" role="status"><span class="sr-only">Loading...</span></div>`;
				$(".js-medicine-unit .form-dropdown-notif").html(_loader);
			};

			const response = HttpRequest.custom(data, beforeSend);

			if (response.code === 200) {
				$(".js-medicine-unit .form-dropdown-notif").remove();
				$(".js-medicine-unit").parent().find(".form-dropdown-input").val("");

				handleGetUnit();
			} else {
				SweetAlert.config(response.message, "error");
			}
		});
	};

	// handleDeleteUnit
	const handleDeleteUnit = () => {
		$("body").on("click", ".js-medicine-unit .form-dropdown-remove", (e) => {
			const _this = $(e.currentTarget);
			const unitId = _this.attr("data-id");
			let formData = new FormData();
			formData.append("unitId", unitId);

			const data = {
				url: API_URL.medicineUnitDelete,
				method: "POST",
				data: formData,
			};

			const response = HttpRequest.custom(data);

			if (response.code === 200) {
				$(".js-medicine-unit").parent().find(".form-dropdown-input").val("");

				handleGetUnit();
			} else {
				SweetAlert.config(response.message, "error");
			}
		});
	};

	// init
	const init = () => {
		if ($(".js-data-medicine-list").length) {
			handleRunTableMedicine();
		}
		if ($(".js-data-medicine").length) {
			handleRunDataTable();
			handleDeleteData();
			handleGetFormData();
		}
		if ($(".js-medicine-category").length) {
			handleGetCategory();
			handlePostCategory();
			handleDeleteCategory();
		}
		if ($(".js-medicine-unit").length) {
			handleGetUnit();
			handlePostUnit();
			handleDeleteUnit();
		}
		if ($(".js-form-medicine").length) {
			handleRunValidation();
			handleClickValidation();
			handleEmptyInput();
		}
	};

	return {
		init,
	};
})();

export default Medicine;
