/* ------------------------------------------------------------------------------
@name: PolyClinic
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";

// --- utilities
import { DataTable, Form, HttpRequest, SweetAlert } from "utilities";

// Form Validation
const ElementSelector = [
	{
		id: "polyclinicId",
	},
	{
		id: "name",
		validation: {
			required: true,
			email: true,
		},
	},
	{
		id: "category",
		type: "select",
		validation: {
			required: true,
		},
	},
	{
		id: "specialization",
		type: "select",
		validation: {
			required: true,
		},
	},
	{
		id: "status",
		type: "checkbox",
	},
];

const ElementEvents = ["input", "blur"];

const PolyClinic = (() => {
	// Handle Run DataTable
	const handleRunDataTable = () => {
		const dataSetting = {
			selector: "js-data-polyclinic",
			url: API_URL.polyclinicGet,
			method: "POST",
		};

		const columnSetting = [
			{
				targets: [0, 4, 5],
				className: "text-center",
			},
			{
				targets: 5,
				render: (data) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Edit" class="btn btn-icon waves-effect btn-primary btn-trans js-edit-data" data-id="${data.polyclinicId}" data-poly="${data.name}" data-category="${data.category}"><i class="mdi mdi-pencil-outline"></i></button>
					<button type="button" data-toggle="tooltip" data-placement="left" title="Delete" class="btn btn-icon waves-effect btn-danger btn-trans js-delete-data" data-id="${data.polyclinicId}" data-poly="${data.name}" data-category="${data.category}"><i class="mdi mdi-trash-can-outline"></i></button>`;
				},
			},
		];

		DataTable.default(dataSetting, columnSetting);
	};

	// Handle Run Validation
	const handleRunValidation = () => {
		Form.validation(ElementEvents, ElementSelector);
	};

	// Handle Click Validation
	const handleClickValidation = () => {
		$('button[type="submit"]').on("click", (e) => {
			$.each(ElementSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".error").length === 0) {
				handlePostData();
			}
			e.preventDefault();
		});
	};

	// handleEmptyInput
	const handleEmptyInput = () => {
		$('button[data-dismiss="modal"]').on("click", (e) => {
			Form.emptyData(ElementSelector);
		});
	};

	// handlePostData
	const handlePostData = () => {
		const formData = Form.dataColletion(ElementSelector);
		let apiUrl = API_URL.polyclinicAdd;
		if ($("#polyclinicId").val().length !== 0) {
			apiUrl = API_URL.polyclinicEdit;
		}

		const data = {
			url: apiUrl,
			method: "POST",
			data: formData,
		};

		HttpRequest.default(data);
		Form.emptyData(ElementSelector);
	};

	const handleGetFormData = () => {
		$("body").on("click", ".js-edit-data", (e) => {
			const _this = $(e.currentTarget);
			const polyclinicId = _this.attr("data-id");
			const poliName = _this.attr("data-poly");
			const category = _this.attr("data-category");

			if (category !== "0") {
				let formData = new FormData();
				formData.append("polyclinicId", polyclinicId);

				const data = {
					url: API_URL.polyclinicGet,
					method: "POST",
					data: formData,
					elementSelector: ElementSelector,
				};

				Form.getFormData(data);
			} else {
				SweetAlert.config(poliName + " tidak bisa diubah!", "error");
			}
		});
	};

	// handleDeleteData
	const handleDeleteData = () => {
		$("body").on("click", ".js-delete-data", (e) => {
			const _this = $(e.currentTarget);
			const polyclinicId = _this.attr("data-id");
			const poliName = _this.attr("data-poly");
			const category = _this.attr("data-category");

			if (category !== "0") {
				let formData = new FormData();
				formData.append("polyclinicId", polyclinicId);

				const data = {
					url: API_URL.polyclinicDelete,
					method: "POST",
					data: formData,
				};

				Form.deleteData(data);
			} else {
				SweetAlert.config(poliName + " tidak bisa dihapus!", "error");
			}
		});
	};

	// init
	const init = () => {
		if ($(".js-data-polyclinic").length) {
			handleRunDataTable();
			handleDeleteData();
			handleGetFormData();
		}
		if ($(".js-form-polyclinic").length) {
			handleRunValidation();
			handleClickValidation();
			handleEmptyInput();
		}
	};

	return {
		init,
	};
})();

export default PolyClinic;
