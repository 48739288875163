/* ------------------------------------------------------------------------------
@name: Pharmacy
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL, WEB_URL, WHITESPACE, IMG_URL } from "variables";

// --- utilities
import {
	DataTable,
	Form,
	SweetAlert,
	Currency,
	FormatDate,
	HttpRequest,
	Session,
} from "utilities";

const userData = JSON.parse(Session.get("userData"));

// Form Validation
const ElementSelector = [
	{
		id: "examinationId",
	},
	{
		id: "registrationId",
	},
	{
		id: "registrationCode",
	},
	{
		id: "examinationDate",
	},
	{
		id: "noMedicalRecord",
	},
	{
		id: "name",
	},
	{
		id: "destination",
	},
	{
		id: "age",
	},
	{
		id: "address",
	},
	{
		id: "anamnesis",
	},
	{
		id: "diagnosis",
	},
	{
		id: "saleId",
	},
	{
		id: "saleDate",
	},
	{
		id: "noInvoice",
	},
	{
		id: "priceType",
	},
	{
		id: "paymentType",
	},
	{
		id: "saleDue",
	},
	{
		id: "grandTotal",
	},
];

const Pharmacy = (() => {
	// Handle run datatable pharmacy for puskesmas
	const handleRunDataTablePharmacyPuskes = () => {
		const dataSetting = {
			selector: "js-data-pharmacy-puskes",
			url: API_URL.pharmacyGet,
			method: "POST",
			data: (d) => {
				const _status = $("#status").val();
				const _date = $("#dateRange").val();
				const _startDate = FormatDate.range(_date).startDate;
				const _endDate = FormatDate.range(_date).endDate;
				return $.extend({}, d, {
					startDate: _startDate,
					endDate: _endDate,
					status: _status,
				});
			},
		};

		const filterSetting = [
			{
				id: "dateRange",
				event: "change",
			},
			{
				id: "status",
				event: "change",
			},
			{
				id: "search",
				event: "keyup",
			},
		];

		const columnSetting = [
			{
				targets: [0, 6, 7],
				className: "text-center",
			},
			{
				targets: 1,
				render: (data) => {
					return `<button type="button" class="btn btn-custom btn-rounded w-md waves-effect waves-light m-b-5 p-0 pr-2 pl-2 js-pharmacy-add" data-id="${data.registrationId}">${data.registrationCode}</button>`;
				},
			},
			{
				targets: 3,
				render: (data) => {
					return `<p class="mb-0">${data.name}</p><p class="mb-0 text-muted">${data.id}</p>`;
				},
			},
			{
				targets: 4,
				render: (data) => {
					return `<p class="mb-0">${data.gender}</p><p class="mb-0 text-muted">${data.birth}</p>`;
				},
			},
			{
				targets: 7,
				render: (dataSetting) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Print" class="btn btn-icon waves-effect btn-primary btn-trans js-pharmacy-print" data-id="${dataSetting}"><i class="mdi mdi-printer"></i></button>`;
				},
			},
		];

		const sortSetting = {
			id: "sort",
			event: "change",
		};

		const columnVisbleSetting = {
			target: [6],
			visble: true,
		};

		DataTable.custom(
			dataSetting,
			filterSetting,
			columnSetting,
			sortSetting,
			columnVisbleSetting
		);
	};

	// Handle Run DataTable pharmacy for clinic
	const handleRunDataTablePharmacyClinic = () => {
		const dataSetting = {
			selector: "js-data-pharmacy-clinic",
			url: API_URL.pharmacyGet,
			method: "POST",
			data: (d) => {
				const _date = $("#dateRangeReg").val();
				const _startDate = FormatDate.range(_date).startDate;
				const _endDate = FormatDate.range(_date).endDate;

				return $.extend({}, d, {
					startDate: _startDate,
					endDate: _endDate,
					status: $("#status").val(),
				});
			},
		};

		const filterSetting = [
			{
				id: "dateRange",
				event: "change",
			},
			{
				id: "search",
				event: "keyup",
			},
			{
				id: "status",
				event: "change",
			},
		];

		const columnSetting = [
			{
				targets: [0, 6],
				className: "text-center",
			},
			{
				targets: 1,
				render: (data) => {
					return `<p class="mb-0">${data.invoice}</p><p class="mb-0 text-muted">${data.date}</p>`;
				},
			},
			{
				targets: 2,
				render: (data) => {
					return `<p class="mb-0">${data.name}</p><p class="mb-0 text-muted">${data.id}</p>`;
				},
			},
			{
				targets: 4,
				render: (data) => {
					return `<p class="mb-0">${data.payment}</p>${data.status}`;
				},
			},
			{
				targets: 5,
				className: "text-right",
				render: (data) => {
					return `<p class="mb-0">${data.total}</p>${data.receivable}`;
				},
			},
			{
				targets: 6,
				render: (saleId) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Edit" class="btn btn-icon waves-effect btn-primary btn-trans js-edit-pharmacy-clinic" data-id="${saleId}"><i class="mdi mdi-pencil-outline"></i></button>
					<button type="button" class="btn btn-icon waves-effect btn-success btn-trans js-receive-receivable" data-id="${saleId}"><i class="mdi mdi-cash"></i></button>
					<button type="button" class="btn btn-icon waves-effect btn-purple btn-trans" data-toggle="modal" data-target="#modalRecipe"><i class="mdi mdi-printer"></i></button>
					<button type="button" data-toggle="tooltip" data-placement="left" title="Delete" class="btn btn-icon waves-effect btn-danger btn-trans js-delete-data" data-id="${saleId}"><i class="mdi mdi-trash-can-outline"></i></button>`;
				},
			},
		];

		const sortSetting = {
			id: "sort",
			event: "change",
		};

		const columnVisbleSetting = {
			target: [6],
			visble: true,
		};

		DataTable.custom(
			dataSetting,
			filterSetting,
			columnSetting,
			sortSetting,
			columnVisbleSetting
		);
	};

	// Handle Run DataTable Modal Examination
	const handleRunDataTableExamination = () => {
		const dataSetting = {
			selector: "js-list-examination-pharmacy",
			url: API_URL.registrationGet,
			method: "POST",
			data: (d) => {
				const _date = $("#dateRangeReg").val();
				const _startDate = FormatDate.range(_date).startDate;
				const _endDate = FormatDate.range(_date).endDate;

				return $.extend({}, d, {
					startDate: _startDate,
					endDate: _endDate,
					status: "Apotek",
				});
			},
		};

		const filterSetting = [
			{
				id: "dateRangeReg",
				event: "change",
			},
			{
				id: "searchReg",
				event: "keyup",
			},
		];

		const columnSetting = [
			{
				targets: [0, 6, 7],
				className: "text-center",
			},
			{
				targets: 1,
				render: (data) => {
					return `<p class="mb-0">${data.id}</p><p class="mb-0 text-muted">${data.time}</p>`;
				},
			},
			{
				targets: 2,
				render: (data) => {
					return `<p class="mb-0">${data.name}</p><p class="mb-0 text-muted">${data.id}</p>`;
				},
			},
			{
				targets: 3,
				render: (data) => {
					return `<p class="mb-0">${data.gender}</p><p class="mb-0 text-muted">${data.birth}</p>`;
				},
			},
			{
				targets: 7,
				render: (data) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Select" class="btn btn-icon waves-effect btn-success btn-trans js-select-examination-patient" data-id="${data.registrationId}"><i class="mdi mdi-plus"></i></button>`;
				},
			},
		];

		const sortSetting = {
			id: "sort",
			event: "change",
		};

		const columnVisbleSetting = {
			target: [6],
			visble: false,
		};

		DataTable.custom(
			dataSetting,
			filterSetting,
			columnSetting,
			sortSetting,
			columnVisbleSetting
		);
	};

	// Handle Run DataTable
	const handleRunDataTablepatient = () => {
		const dataSetting = {
			selector: "js-data-patient-pharmacy",
			url: API_URL.patientGet,
			method: "POST",
		};

		const columnSetting = [
			{
				targets: [0, 7],
				className: "text-center",
			},
			{
				targets: 1,
				render: (data) => {
					return `<p class="mb-0">${data.name}</p><p class="mb-0 text-muted">${data.id}</p>`;
				},
			},
			{
				targets: 2,
				render: (data) => {
					return `<p class="mb-0">${data.gender}</p><p class="mb-0 text-muted">${data.age}</p>`;
				},
			},
			{
				targets: 3,
				render: (data) => {
					return `<p class="mb-0">${data.nik}</p><p class="mb-0 text-muted">${data.bpjs}</p>`;
				},
			},
			{
				targets: 7,
				render: (data) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Select" class="btn btn-icon waves-effect btn-success btn-trans js-select-patient" data-id="${data.patientId}" data-rme="${data.noMedicalRecord}" data-name="${data.name}"><i class="mdi mdi-plus"></i></button>`;
				},
			},
		];

		const columnVisbleSetting = {
			target: [3, 6],
			visble: false,
		};

		DataTable.default(dataSetting, columnSetting, columnVisbleSetting);
	};

	const handleShowForm = () => {
		$('button[data-target="#modalPharmacy"]').on("click", (e) => {
			handleEmptyFormData();
			$(".js-date-picker").val(moment().format("D/MM/YYYY"));
		});
	};

	const handleEmpyCart = () => {
		const content = `<tr class="row-notif">
												<td colspan="8" class="text-center">
													<div class="empty-cart">
														<img
															src="${IMG_URL + "/empty-cart.png"}"
															alt="Empty cart"
														/>
														<p class="text-muted">
															Data obat belum ditambahkan
														</p>
													</div>
												</td>
											</tr>`;
		return content;
	};

	// handleEmptyInput
	const handleEmptyInput = () => {
		$('#modalPharmacy button[data-confirm-close="modal"]').on("click", (e) => {
			swal({
				title: "Apakah Anda yakin?",
				text: "Tindakan ini tidak dapat diurungkan!",
				type: "warning",
				showCancelButton: true,
				confirmButtonText: "Ya",
				cancelButtonText: "Batal",
				confirmButtonClass: "btn btn-success",
				cancelButtonClass: "btn btn-danger m-l-10",
				buttonsStyling: false,
			}).then(
				() => {
					// Run Empty data
					handleEmptyFormData();
					// hide modal
					$(".modal").modal("hide");
				},
				(dismiss) => {
					if (dismiss === "cancel") {
						swal("Batal", "Data Anda aman :)", "error");
					}
				}
			);
		});
	};

	// handleEmptyInputPayment
	const handleEmptyInputPayment = () => {
		$('#modalPayment button[data-dismiss="modal"]').on("click", (e) => {
			$(".js-payment-type").val("1");
			$(".js-payment").val("");
			$(".js-due-date").addClass("d-none");
			$(".js-label-payment").text("Bayar");
			$(".js-label-cashback").text("Kembali");
			$(".js-cash-back").val("Rp 0");
		});
	};

	const handleEmptyFormData = () => {
		Form.emptyData(ElementSelector);
		const _content = handleEmpyCart();
		$(".js-pharmacy-treatment").html(_content);
		$(".js-payment-type").val("1");
		$(".js-payment").val("");
		$(".js-due-date").addClass("d-none");
		$(".js-label-payment").text("Bayar");
		$(".js-label-cashback").text("Kembali");
		$(".js-cash-back").val("Rp 0");
	};

	// handleAddDataPharmacyPuskes
	const handleAddDataPharmacyPuskes = () => {
		$('.js-form-pharmacy button[type="submit"]').on("click", (e) => {
			// app type for puskesmas
			const appType = userData.appType;
			const apiUrl = API_URL.pharmacyAdd;
			handleAddData(apiUrl, appType);
		});
	};

	// handleAddDataPharmacyClinic
	const handleAddDataPharmacyClinic = () => {
		$('.js-form-payment button[type="submit"]').on("click", (e) => {
			// app type for clinic
			const appType = userData.appType;
			let apiUrl = API_URL.pharmacyAdd;
			const saleId = $("#saleId").val();

			if (saleId.length !== 0) {
				apiUrl = API_URL.pharmacyEdit;
			}

			handleAddData(apiUrl, appType);
		});
	};

	// handleAddData
	const handleAddData = (apiUrl, appType) => {
		const _examinationId = $("#examinationId").val();
		const _registrationId = $("#registrationId").val();
		const _noMedicalRecord = $("#noMedicalRecord").val();
		const _appType = appType;

		// add medical treatment
		let _medicalTreatment = [];

		$(".js-pharmacy-treatment")
			.find("tr")
			.each((i, e) => {
				const _this = $(e),
					_medicineId = _this.attr("data-id"),
					_unit = _this.attr("data-unit"),
					_qty = _this.find(".js-total-qty").val(),
					_price = _this.attr("data-price"),
					_purchasePrice = _this.attr("data-purchase"),
					_dosis = _this.find(".js-pharmacy-dosis").val();

				_medicalTreatment[i] = {
					medicineId: _medicineId,
					unit: _unit,
					qty: _qty,
					purchasePrice: _purchasePrice,
					price: _price,
					dosis: _dosis,
				};
			});

		// data post
		let _data = {
			examinationId: _examinationId !== "" ? _examinationId : 0,
			registrationId: _registrationId !== "" ? _registrationId : 0,
			applicationType: _appType,
			medicalTreatment: _medicalTreatment,
		};

		if (appType === "2") {
			// data transaction
			const _saleId = $("#saleId").val();
			const _noInvoice = $("#noInvoice").val();
			const _saleDate = $("#saleDate").val();
			const _patientName = $("#name").val();
			const _priceType = "1";
			const _paymentType = $("#paymentType").val();
			const _saleDue = $("#saleDue").val();
			const _discount = 0;
			const _total = $("#grandTotal").val();
			const _payment = $("#payment").val();
			const _cashBack = $("#cashBack").val();
			const _receivable =
				_paymentType === "1" ? 0 : Currency.remove_rp(_cashBack);
			const _status = _receivable > 0 ? "2" : "1";

			let _dataTransaction = {
				saleId: _saleId,
				noInvoice: _noInvoice,
				saleDate: FormatDate.dash(_saleDate),
				noMedicalRecord: _noMedicalRecord,
				patientName: _patientName,
				priceType: _priceType,
				paymentType: _paymentType,
				saleDue: FormatDate.dash(_saleDue),
				discount: _discount,
				total: Currency.remove_rp(_total),
				payment: Currency.remove_rp(_payment),
				receivable: _receivable,
				status: _status,
			};

			// merge data
			_data = { ..._data, ..._dataTransaction };
		}

		if (!WHITESPACE.test(_noMedicalRecord)) {
			if (!$(".js-pharmacy-treatment").find("tr").hasClass("row-notif")) {
				if (!$(".js-cash-back").hasClass("error")) {
					$.ajax({
						url: apiUrl,
						method: "POST",
						dataType: "JSON",
						data: _data,
						success: (response) => {
							let status = "success";
							if (response.code !== 200) {
								status = "error";
							}
							$(".modal").modal("hide");
							SweetAlert.config(response.message, status);
							handleEmptyFormData();
							handleReloadData();
						},
					});
				} else {
					const message = $(".js-cash-back").val();
					SweetAlert.config(message, "warning");
				}
			} else {
				SweetAlert.config("Data layanan/obat masih kosong!", "warning");
			}
		} else {
			SweetAlert.config("Data Pasien masih kosong!", "warning");
		}
	};

	// handleEditData
	const handleEditData = () => {
		const _pharmacySelector = [
			{
				selector: "js-pharmacy-add",
			},
			{
				selector: "js-select-examination-patient",
			},
			{
				selector: "js-edit-pharmacy-clinic",
			},
		];

		$.each(_pharmacySelector, (i, v) => {
			$("body").on("click", `.${v.selector}`, (e) => {
				const _this = $(e.currentTarget);
				const id = _this.attr("data-id");

				let formData = new FormData();
				formData.append("id", id);
				formData.append("appType", userData.appType);
				if ($("#noInvoice").length && !WHITESPACE.test($("#noInvoice").val())) {
					formData.append("noInvoice", $("#noInvoice").val());
				}

				const data = {
					url: API_URL.pharmacyGet,
					method: "POST",
					data: formData,
					elementSelector: ElementSelector,
				};

				const formShow = "#modalPharmacy";

				const response = Form.getFormData(data, formShow);
				// get medical treatment
				handleGetMedicalTreatment(response);

				$("#destination").text(response.data.destination);
				$("#status").attr("value", response.data.status);
				if (v.selector === "js-select-examination-patient") {
					$("#modalExamination").modal("hide");
					$(".modal-backdrop").removeClass("show");
					$(".modal-backdrop").removeAttr("style");
				}
			});
		});
	};

	// handleDeleteData
	const handleDeleteData = () => {
		$("body").on("click", ".js-delete-data", (e) => {
			const _this = $(e.currentTarget);
			const id = _this.attr("data-id");
			let formData = new FormData();
			formData.append("id", id);

			const data = {
				url: API_URL.pharmacyDelete,
				method: "POST",
				data: formData,
			};

			swal({
				title: "Apakah Anda yakin?",
				text: "Tindakan ini tidak dapat diurungkan!",
				type: "warning",
				showCancelButton: true,
				confirmButtonText: "Ya, Hapus",
				cancelButtonText: "Batal",
				confirmButtonClass: "btn btn-success",
				cancelButtonClass: "btn btn-danger m-l-10",
				buttonsStyling: false,
			}).then(
				() => {
					// Run API Delete Data
					HttpRequest.default(data);
					handleEmptyFormData();
					handleReloadData();
				},
				(dismiss) => {
					if (dismiss === "cancel") {
						swal("Batal", "Data Anda aman :)", "error");
					}
				}
			);
		});
	};

	// handleTableMedicine
	const handleTableMedicine = (data) => {
		const tableContent = `<tr data-id="${data.medicineId}" data-unit="${
			data.unit
		}" data-purchase="${data.purchasePrice}" data-price="${data.price}">
										<td class="js-pharmacy-name">${data.name}</td>
										<td>
											<select class="form-control">
												<option value="${data.unit}">${data.unit}</option>
											</select>
										</td>
										<td>
											<input type="text" class="form-control js-pharmacy-dosis" value="${data.dosis}">
										</td>
										<td class="text-center">
											<div class="qty js-qty">
												<button type="button" class="btn btn-icon btn-success btn-trans waves-effect waves-light js-dec-qty"><i class="mdi mdi-minus"></i></button>
												<input type="text" class="js-total-qty" value="${data.qty}" min="1" />
												<button type="button" class="btn btn-icon btn-success btn-trans waves-effect waves-light js-inc-qty"><i class="mdi mdi-plus"></i></button>
											</div>
										</td>
										<td class="text-right">
											<p class="mb-0 js-price">${Currency.format_rp(data.price)}</p>
											<p class="mb-0 js-subtotal text-muted">${Currency.format_rp(
												data.price * data.qty
											)}</p>
										</td>
										<td class="text-center">
											<button type="button" data-toggle="tooltip" data-placement="left" title="Delete" class="btn btn-icon waves-effect btn-danger btn-trans js-medicine-delete"><i class="mdi mdi-trash-can-outline"></i></button>
										</td>
									</tr>`;

		return tableContent;
	};

	// handleGetMedicalTreatment
	const handleGetMedicalTreatment = (response) => {
		if (response.code === 200) {
			let _content = "";
			let _grandTotal = 0;
			$.each(response.data.medicalTreatment, (i, v) => {
				let _subTotal = v.price * v.qty;
				_grandTotal += _subTotal;

				_content += handleTableMedicine(v);
			});

			$(".js-pharmacy-treatment").html(_content);
			if ($("#modalPharmacy").find(".js-grand-total").length) {
				$(".js-grand-total").val(Currency.format_rp(_grandTotal));
			}
		}
	};

	// handleAddMedicine
	const handleAddMedicine = () => {
		$("body").on("click", ".js-medicine-list .form-dropdown-select", (e) => {
			const _this = $(e.currentTarget);
			const _name = _this.attr("data-value");
			const _id = _this.attr("data-id");
			const _unit = _this.attr("data-unit");
			const _notif = $(".js-pharmacy-treatment")
				.find('td[colspan="8"]')
				.parent();
			const _purchase = Currency.remove_rp(_this.attr("data-purchase"));
			const _price = Currency.remove_rp(_this.attr("data-price"));
			const _total = Currency.remove_rp($(".js-grand-total").val());
			const _grandTotal = Currency.format_rp(_price + _total);
			$(".js-grand-total").val(Currency.format_rp(_grandTotal));
			let _qty = 1;

			const dataTable = {
				medicineId: _id,
				name: _name,
				unit: _unit,
				qty: _qty,
				purchasePrice: _purchase,
				price: _price,
				dosis: "",
			};

			if ($(".js-pharmacy-treatment").find("tr").length > 0) {
				$(".js-pharmacy-treatment")
					.find("tr")
					.each((i, e) => {
						const _this = $(e);
						if (_name == _this.find(".js-pharmacy-name").text()) {
							_qty = _qty + parseInt(_this.find(".js-total-qty").val());

							_this.find(".js-total-qty").val(_qty);
							_this
								.find(".js-subtotal")
								.text(Currency.format_rp(_price * _qty));
						}
					});
			}

			const _content = handleTableMedicine(dataTable);

			if (_qty === 1) {
				$(".js-pharmacy-treatment").append(_content);
			}

			$(".js-medicine-list").parent().find(".form-dropdown-input").val("");
			if (_notif.length) {
				_notif.remove();
			}
		});
	};

	// handleSetQtyByClick
	const handleSetQtyByClick = () => {
		$("body").on("click", ".js-qty button", (e) => {
			const _this = $(e.currentTarget);
			const _parent = _this.parents("tr");
			const _qty = _parent.find(".js-total-qty").val();
			const _price = Currency.remove_rp(_parent.find(".js-price").text());

			let _totalQty = 0;
			let _subTotal = 0;
			if (_this.hasClass("js-inc-qty")) {
				_totalQty = parseFloat(_qty) + 1;
			} else {
				if (_qty > 1) {
					_totalQty = parseFloat(_qty) - 1;
				} else {
					_totalQty = 1;
				}
			}

			_parent.find(".js-total-qty").val(_totalQty);
			_subTotal = Currency.format_rp(_price * _totalQty);
			_parent.find(".js-subtotal").text(_subTotal);

			handleSetGrandTotal();
		});
	};

	const handleSetQtyByInput = () => {
		$("body").on("input", ".js-total-qty", (e) => {
			const _this = $(e.currentTarget);
			const _parent = _this.parents("tr");
			const _qty = _this.val();
			const _price = Currency.remove_rp(_parent.find(".js-price").text());

			if (_qty > 0) {
				const _subTotal = Currency.format_rp(_price * _qty);
				_parent.find(".js-subtotal").text(_subTotal);

				handleSetGrandTotal();
			}
		});
	};

	const handleSetGrandTotal = () => {
		let _grandTotal = 0;
		if ($(".js-pharmacy-treatment").find("tr").length > 0) {
			$(".js-pharmacy-treatment")
				.find("tr")
				.each((i, e) => {
					const _this = $(e);
					let _qty = parseInt(_this.find(".js-total-qty").val());
					let _price = parseInt(_this.attr("data-price"));
					let _subTotal = _price * _qty;

					_grandTotal += _subTotal;
				});
		}

		$(".js-grand-total").val(Currency.format_rp(_grandTotal));
	};

	// handleDeleteMedicine
	const handleDeleteMedicine = () => {
		$("body").on("click", ".js-medicine-delete", (e) => {
			const _this = $(e.currentTarget);
			const _content = handleEmpyCart();

			if ($("#modalPharmacy").find(".js-grand-total").length) {
				const _subTotal = Currency.remove_rp(
					_this.parents("tr").find(".js-subtotal").text()
				);
				const _grandTotal = Currency.remove_rp($(".js-grand-total").val());
				const _total = Currency.format_rp(_grandTotal - _subTotal);
				$(".js-grand-total").val(_total);
			}

			_this.parents("tr").remove();

			if ($(".js-pharmacy-treatment tr").length == 0) {
				$(".js-grand-total").val(Currency.format_rp(0));
				$(".js-pharmacy-treatment").html(_content);
			}
		});
	};

	// handlePayment
	const handlePayment = () => {
		// handle payment type
		$(".js-payment-type").on("change", (e) => {
			const _this = $(e.currentTarget);
			const _val = _this.val();
			if (_val === "2") {
				$(".js-due-date").removeClass("d-none");
				$(".js-label-payment").text("DP");
				$(".js-label-cashback").text("Piutang");
			} else {
				$(".js-due-date").addClass("d-none");
				$(".js-label-payment").text("Bayar");
				$(".js-label-cashback").text("Kembali");
			}
		});

		// handle input payment
		$(".js-payment").on("input", (e) => {
			const _this = $(e.currentTarget);
			const _paymentType = $(".js-payment-type").val();
			const _val = Currency.remove_rp(_this.val());
			const _grandTotal = Currency.remove_rp($(".js-grand-total").val());
			let _cashBack = 0;

			if (_grandTotal > 0) {
				if (_paymentType === "1") {
					if (_val < _grandTotal) {
						_cashBack = "Pembayaran belum cukup!";
						$(".js-cash-back").addClass("error");
					} else {
						_cashBack = Currency.format_rp(_grandTotal - _val);
						if ($(".js-cash-back").hasClass("error")) {
							$(".js-cash-back").removeClass("error");
						}
					}
				} else {
					if (_val > _grandTotal) {
						_cashBack = "Pembayaran melebihi total piutang!";
						$(".js-cash-back").addClass("error");
					} else {
						_cashBack = Currency.format_rp(_grandTotal - _val);
						if ($(".js-cash-back").hasClass("error")) {
							$(".js-cash-back").removeClass("error");
						}
					}
				}
			} else {
				_cashBack = "Keranjang masih kosong!";
			}
			$(".js-cash-back").val(_cashBack);
		});
	};

	// handleSelectPatient
	const handleSelectPatient = () => {
		$("body").on("click", ".js-select-patient", (e) => {
			const _this = $(e.currentTarget);
			const _parent = $(".js-form-pharmacy");
			const _noMedicalRecord = _this.attr("data-rme");
			const _patientName = _this.attr("data-name");

			_parent.find("#noMedicalRecord").val(_noMedicalRecord);
			_parent.find("#name").val(_patientName);

			$("#modalPatientPharmacy").modal("hide");
			$(".modal-backdrop").removeClass("show");
			$(".modal-backdrop").removeAttr("style");
		});
	};

	// handleGetDesignPrint
	const handleGetDesignPrint = () => {
		$("body").on("click", ".js-pharmacy-print", (e) => {
			const _this = $(e.currentTarget);
			const registrationId = _this.attr("data-id");
			const appType = userData.appType;

			let formData = new FormData();
			formData.append("id", registrationId);
			formData.append("appType", appType);

			const data = {
				url: API_URL.pharmacyGet,
				method: "POST",
				data: formData,
				elementSelector: ElementSelector,
			};

			const formShow = "#modalPharmacyPrint";

			const response = Form.getFormData(data, formShow);
			const _data = response.data;
			if (response.code == 200) {
				let _content = "";
				$.each(response.data.medicalTreatment, (i, v) => {
					_content += `<tr>
												<td>${v.name}</td>
												<td class="text-center">${v.qty + " " + v.unit}</td>
												<td class="text-center">${v.dosis ? v.dosis : "-"}</td>
											</tr>`;
				});

				const _parent = $("#modalPharmacyPrint");
				_parent.find(".js-print-reg").text(_data.registrationCode);
				_parent
					.find(".js-print-bpjs")
					.text(_data.bpjs !== null ? _data.bpjs : "-");
				_parent.find(".js-print-medical-treatment").html(_content);
				_parent
					.find(".js-print-medical-personal")
					.text(response.data.doctorName);
				_parent
					.find(".js-print-anamnesis")
					.text(_data.anamnesis !== null ? _data.anamnesis : "-");
				_parent
					.find(".js-print-diagnosis")
					.text(_data.diagnosis !== null ? _data.diagnosis : "-");
				_parent.find(".js-print-name").text(_data.name);
				_parent
					.find(".js-print-nik")
					.text(_data.kkName !== null ? _data.kkName : "-");
				_parent.find(".js-print-age").text(_data.age);
				_parent.find(".js-print-status").text(_data.status);
				_parent.find(".js-print-address").text(_data.address);
				_parent
					.find(".js-print-td")
					.text(
						_data.sistole !== null && _data.diastole !== null
							? _data.sistole + "/" + _data.diastole + " mmHg"
							: "-"
					);
				_parent
					.find(".js-print-rr")
					.text(_data.rp !== null ? _data.rp + " x/Menit" : "-");
				_parent
					.find(".js-print-bb")
					.text(_data.bb !== null ? _data.bb + " kg" : "-");
				_parent
					.find(".js-print-tb")
					.text(_data.tb !== null ? _data.tb + " cm" : "-");
				_parent
					.find(".js-print-nd")
					.text(_data.nd !== null ? _data.nd + " bpm" : "-");
				_parent
					.find(".js-print-sh")
					.html(_data.sh !== null ? _data.sh + " C" : "-");
			}
		});
	};

	// handleGetFaskes
	const handleGetFaskes = () => {
		$.ajax({
			url: API_URL.profileFaskes,
			method: "POST",
			dataType: "JSON",
			success: (response) => {
				const _data = response.data;
				$(".js-faskes-city").text(`PEMERINTAH KABUPATEN ${_data.cityName}`);
				$(".js-faskes-name").text(_data.organizationName);
				$(".js-faskes-address").text(
					_data.address + " Kode Pos " + _data.postalCode
				);
				$(".js-print-date").html(
					`<strong>${_data.districtName}</strong>` +
						", " +
						$(".js-print-date").attr("data-date")
				);
				$(".js-faskes-contact").text(
					"Telp" + " " + _data.phone + " Email : " + _data.email
				);
				$(".js-logo-city").attr("src", _data.logoCity);
				$(".js-logo-faskes").attr("src", _data.logoFaskes);
			},
		});
	};

	// handleTotalExamination
	const handleTotalExamination = () => {
		let apiUrl = API_URL.totalExam;
		let formData = new FormData();
		formData.append("status", "Apotek");

		const data = {
			url: apiUrl,
			method: "POST",
			data: formData,
		};

		const response = HttpRequest.custom(data);
		if (response.code === 200) {
			$(".js-count-examination").text(`(${response.data})`);
		}
	};

	// handleGetNoInvoice
	const handleGetNoInvoice = () => {
		const saleDate = FormatDate.dash($("#saleDate").val());

		$.ajax({
			url: API_URL.noInvoice,
			method: "POST",
			dataType: "JSON",
			data: {
				saleDate: saleDate,
			},
			success: (response) => {
				const _data = response.data;
				$("#noInvoice").val(_data);
				$("#noInvoice").attr("value", _data);
			},
		});
	};

	// handleGetNoInvoiceByClick
	const handleGetNoInvoiceByClick = () => {
		$('button[data-target="#modalPharmacy"]').on("click", (e) => {
			if ($(".js-change-invoice").length) {
				handleGetNoInvoice();
			}
		});
	};

	// handleChangeNoInvoiceByDate
	const handleChangeNoInvoiceByDate = () => {
		$("body").on("change", ".js-change-invoice", () => {
			handleGetNoInvoice();
		});
	};

	// handleButtonReload
	const handleButtonReload = () => {
		$(".js-reload-pharmacy").on("click", () => {
			handleReloadData();
		});
	};

	const handleReloadData = () => {
		if (userData.appType === "1") {
			$("body").find(".js-data-pharmacy-puskes").DataTable().ajax.reload();
		} else {
			$("body").find(".js-data-pharmacy-clinic").DataTable().ajax.reload();
		}
	};

	const handleReceiveReceivable = () => {
		$("body").on("click", ".js-receive-receivable", (e) => {
			const _this = $(e.currentTarget);
			const _saleId = _this.attr("data-id");
			let formData = new FormData();
			formData.append("id", _saleId);
			const data = {
				url: API_URL.receivableGet,
				method: "POST",
				data: formData,
			};

			let response = HttpRequest.custom(data);
			if (response.code === 200) {
				const _data = response.data;
				resultDataReceivable(_data);
				$("#modalReceivable").modal("show");
			}
		});
	};

	const resultDataReceivable = (data) => {
		const _parent = $(".js-form-receivable");
		_parent.find("#saleId").val(data.saleId);
		_parent.find("#receivableCode").val(data.receivableCode);
		_parent.find("#totalReceivable").val(data.totalReceivable);
		_parent.find("#totalPayment").val(data.totalPayment);
		_parent.find("#totalPayment").val(data.totalPayment);
		_parent.find("#totalRemaining").val(data.totalRemaining);
		const receivableList = data.list;
		let _content = "";
		if (receivableList.length > 0) {
			$.each(receivableList, (i, v) => {
				_content += `<tr>
													<td>${v.receivableCode}</td>
													<td>${v.receivableDate}</td>
													<td>${v.total}</td>
													<td>${v.note}</td>
												</tr>`;
			});
		} else {
			_content = `<tr class="row-notif">
												<td colspan="8" class="text-center">
													<div class="empty-cart">
														<p class="text-muted">Belum ada pembayaran</p>
													</div>
												</td>
											</tr>`;
		}

		$(".js-recive-receivable").html(_content);

		$("#modalReceivable").modal("show");
	};

	const handleAddReceivable = () => {
		$('.js-form-receivable button[type="submit"]').on("click", (e) => {
			const _parent = $(".js-form-receivable");
			const _saleId = _parent.find("#saleId").val();
			const _receivableCode = _parent.find("#receivableCode").val();
			const _receivableDate = _parent.find("#receivableDate").val();
			const _total = _parent.find("#total").val();
			const _note = _parent.find("#note").val();

			const dataReceivable = {
				saleId: _saleId,
				receivableCode: _receivableCode,
				receivableDate: FormatDate.dash(_receivableDate),
				total: Currency.remove_rp(_total),
				note: _note,
			};

			if (!WHITESPACE.test(_total)) {
				$.ajax({
					url: API_URL.receivableAdd,
					method: "POST",
					dataType: "JSON",
					data: dataReceivable,
					success: (response) => {
						if (response.code === 200) {
							// const _data = response.data;
							// resultDataReceivable(_data);
							const _parent = $(".js-form-receivable");
							_parent.find("#total").val("");
							_parent.find("#note").val("");
							$("body")
								.find(".js-data-pharmacy-clinic")
								.DataTable()
								.ajax.reload();
							$(".modal").modal("hide");
						}
						let status = response.code === 200 ? "success" : "error";
						SweetAlert.config(response.message, status);
					},
				});
			} else {
				SweetAlert.config("Jumlah pembayaran masih kosong!", "warning");
			}
		});
	};

	// init
	const init = () => {
		if ($(".js-form-pharmacy").length || $(".js-form-payment").length) {
			handleAddDataPharmacyPuskes();
			handleAddDataPharmacyClinic();
			handleEditData();
			handleEmptyInput();
			handleShowForm();
			handleEmptyInputPayment();
			handleTotalExamination();
			handleGetNoInvoiceByClick();
			handleSelectPatient();
			handleRunDataTablepatient();
			handlePayment();
			handleGetDesignPrint();
		}
		if ($(".js-data-pharmacy-puskes").length) {
			handleRunDataTablePharmacyPuskes();
		}
		if ($(".js-data-pharmacy-clinic").length) {
			handleRunDataTablePharmacyClinic();
			handleDeleteData();
			handleReceiveReceivable();
		}
		if ($(".js-list-examination-pharmacy").length) {
			handleRunDataTableExamination();
		}
		if ($(".js-pharmacy-treatment").length) {
			handleAddMedicine();
			handleSetQtyByClick();
			handleSetQtyByInput();
			handleDeleteMedicine();
		}
		if ($(".js-change-invoice").length) {
			handleChangeNoInvoiceByDate();
		}
		if ($(".js-head-letter").length) {
			handleGetFaskes();
		}
		if ($(".js-form-receivable").length) {
			handleAddReceivable();
		}
		handleButtonReload();
	};

	return {
		init,
	};
})();

export default Pharmacy;
