/* ------------------------------------------------------------------------------
@name: StockOut
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";

// --- utilities
import { DataTable, Form, Currency, SweetAlert } from "utilities";

// Form Validation
const ElementSelector = [
	{
		id: "stockOutId",
	},
	{
		id: "date",
		validation: {
			required: true,
		},
	},
	{
		id: "note",
		validation: {
			required: true,
		},
	},
];

const ElementEvents = ["input", "blur"];

const StockOut = (() => {
	// Handle Run DataTable
	const handleRunDataTable = () => {
		const dataSetting = {
			selector: "js-data-stock-out",
			url: API_URL.stockOutGet,
			method: "POST",
		};

		const columnSetting = [
			{
				targets: 5,
				className: "text-center",
				render: (dataSetting) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Edit" class="btn btn-icon waves-effect btn-primary btn-trans js-edit-data" data-id="${dataSetting}"><i class="mdi mdi-pencil-outline"></i></button>
					<button type="button" data-toggle="tooltip" data-placement="left" title="Delete" class="btn btn-icon waves-effect btn-danger btn-trans js-delete-data" data-id="${dataSetting}"><i class="mdi mdi-trash-can-outline"></i></button>`;
				},
			},
		];

		DataTable.default(dataSetting, columnSetting);
	};

	// Handle Run Validation
	const handleRunValidation = () => {
		Form.validation(ElementEvents, ElementSelector);
	};

	// Handle Click Validation
	const handleClickValidation = () => {
		$('button[type="submit"]').on("click", (e) => {
			$.each(ElementSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".error").length === 0) {
				handlePostData();
			}
			e.preventDefault();
		});
	};

	// handleEmptyRow
	const handleEmptyRow = () => {
		const _content = `<tr><td colspan="8" class="text-center"><span>Data not found</span></td></tr>`;
		$(".js-medical-stock-out").html(_content);
		$(".js-grand-total").val(Currency.format_rp(0));
	};

	// handleEmptyInput
	const handleEmptyInput = () => {
		$('#modalStockOut button[data-dismiss="modal"]').on("click", (e) => {
			Form.emptyData(ElementSelector);
			handleEmptyRow();
		});
	};

	// handlePostData
	const handlePostData = () => {
		let _medicineList = [];
		let _total = 0;
		const _stockOutId = $("#stockOutId").val();
		const _date = $("#date").val().split("/").reverse().join("/");
		const _note = $("#note").val();

		$(".js-check-row:checked").each((i, e) => {
			const _this = $(e);
			const _parent = _this.parents("tr");
			const _medicineId = _this.val();
			let _qty = _parent.find(".js-qty-row").val();
			let _price = Currency.remove_rp(_parent.find(".js-price-row").val());
			let _subTotal = Currency.remove_rp(_parent.find(".js-total-row").val());
			_total += _subTotal;

			_medicineList[i] = {
				medicineId: _medicineId,
				qty: parseFloat(_qty),
				price: _price,
			};
		});

		if (_medicineList.length > 0) {
			let apiUrl = API_URL.stockOutAdd;
			if ($("#stockOutId").val().length !== 0) {
				apiUrl = API_URL.stockOutEdit;
			}

			const _data = {
				stockOutId: _stockOutId,
				date: _date,
				note: _note,
				medicineList: _medicineList,
			};

			$.ajax({
				url: apiUrl,
				method: "POST",
				dataType: "JSON",
				data: _data,
				success: (response) => {
					let status = "success";
					if (response.code !== 200) {
						status = "error";
					}
					handleEmptyRow();
					$(".modal").modal("hide");
					SweetAlert.config(response.message, status);
					$(".js-data-stock-out").DataTable().ajax.reload();
				},
			});
		} else {
			SweetAlert.config("Produk belum diisi!", "warning");
		}
	};

	const handleGetFormData = () => {
		$("body").on("click", ".js-edit-data", (e) => {
			const _this = $(e.currentTarget);
			const _stockOutId = _this.attr("data-id");

			handleEmptyRow();
			$("#modalStockOut").modal("show");
			$.ajax({
				url: API_URL.stockOutGet,
				method: "POST",
				dataType: "JSON",
				data: {
					stockOutId: _stockOutId,
				},
				success: (response) => {
					const _data = response.data;
					const _code = response.code;
					if (_code === 200) {
						$("#stockOutId").val(_data.stockOutId);
						$("#date").val(_data.createdDate);
						$("#note").text(_data.note);
						let _content = "";
						let _total = 0;
						if (_data.items.length) {
							$.each(_data.items, (i, v) => {
								_total += v.price * v.qty;
								_content += `<tr>
																<td class="hide-col">
																	<input class="js-check-row" type="checkbox" value="${
																		v.medicineId
																	}" checked="checked" />
																</td>
																<td class="js-name-row">${v.medicineName}</td>
																<td>
																	<div class="qty js-qty">
																		<button type="button" class="btn btn-icon btn-custom waves-effect waves-light js-dec-qty"><i class="mdi mdi-minus"></i></button>
																		<input type="text" class="js-qty-row" value="${v.qty}" min="1" />
																		<button type="button" class="btn btn-icon btn-custom waves-effect waves-light js-inc-qty"><i class="mdi mdi-plus"></i></button>
																	</div>
																</td>
																<td class="text-center js-unit-row">${v.unitName}</td>
																<td>
																	<input type="text" class="form-control text-right js-format-number js-price-row" value="${Currency.format_rp(
																		v.price
																	)}">
																</td>
																<td>
																	<input type="text" class="form-control text-right js-format-number js-total-row" value="${Currency.format_rp(
																		v.price * v.qty
																	)}" readonly="readonly"/>
																</td>
																<td class="text-center">
																	<button type="button" class="btn btn-icon btn-danger btn-trans js-delete-row" data-id="${
																		v.medicineId
																	}"><i class="mdi mdi-trash-can-outline"></i></button>
																</td>
															</tr>`;
							});

							$(".js-medical-stock-out").html(_content);
							$(".js-grand-total").val(Currency.format_rp(_total));
						}
					}
				},
			});
		});
	};

	// handleDeleteData
	const handleDeleteData = () => {
		$("body").on("click", ".js-delete-data", (e) => {
			const _this = $(e.currentTarget);
			const stockOutId = _this.attr("data-id");
			let formData = new FormData();

			formData.append("stockOutId", stockOutId);

			const data = {
				url: API_URL.stockOutDelete,
				method: "POST",
				data: formData,
			};

			Form.deleteData(data, ".js-data-stock-out");
		});
	};

	const handleSelectMedicine = () => {
		// handle select row
		$("body").on(
			"click",
			".js-medicine-list-buy .form-dropdown-select",
			(e) => {
				const _this = $(e.currentTarget);
				const _name = _this.attr("data-value");
				const _id = _this.attr("data-id");
				const _unit = _this.attr("data-unit");
				const _price = Currency.remove_rp(_this.attr("data-price"));
				const _notif = $(".js-medical-stock-out")
					.find('td[colspan="8"]')
					.parent();
				const _total = Currency.remove_rp($(".js-grand-total").val());
				const _grandTotal = Currency.format_rp(_price + _total);
				let _qty = 1;

				if ($(".js-medical-stock-out").find("tr").length > 0) {
					$(".js-medical-stock-out")
						.find("tr")
						.each((i, e) => {
							const _this = $(e);
							if (_name == _this.find(".js-name-row").text()) {
								_qty = _qty + parseInt(_this.find(".js-qty-row").val());
								_this.find(".js-qty-row").val(_qty);
								_this
									.find(".js-total-row")
									.val(Currency.format_rp(_price * _qty));
							}
						});
				}

				$(".js-grand-total").val(_grandTotal);

				const _content = `<tr>
                      <td class="hide-col">
                        <input class="js-check-row" type="checkbox" value="${_id}" checked="checked" />
                      </td>
                      <td class="js-name-row align-middle">${_name}</td>
                      <td>
												<div class="qty js-qty">
													<button type="button" class="btn btn-icon btn-custom waves-effect waves-light js-dec-qty"><i class="mdi mdi-minus"></i></button>
													<input type="text" class="js-qty-row" value="${_qty}" min="1" />
													<button type="button" class="btn btn-icon btn-custom waves-effect waves-light js-inc-qty"><i class="mdi mdi-plus"></i></button>
												</div>
                      </td>
                      <td class="text-center js-unit-row align-middle">${_unit}</td>
                      <td>
                        <input type="text" class="form-control text-right js-format-number js-price-row" value="${Currency.format_rp(
													_price
												)}" readonly="readonly" />
                      </td>
                      <td>
                        <input type="text" class="form-control text-right js-format-number js-total-row" value="${Currency.format_rp(
													_price * _qty
												)}" readonly="readonly" />
                      </td>
                      <td class="text-center">
                        <button type="button" class="btn btn-icon btn-danger btn-trans js-delete-row" data-id="${_id}"><i class="mdi mdi-trash-can-outline"></i></button>
                      </td>
                    </tr>`;

				if (_qty === 1) {
					$(".js-medical-stock-out").append(_content);
				}
				if (_notif.length) {
					_notif.remove();
				}
			}
		);

		// handle qty row
		$("body").on("click", ".js-qty button", (e) => {
			const _this = $(e.currentTarget);
			const _qty = _this.parents("tr").find(".js-qty-row").val();
			const _price = Currency.remove_rp(
				_this.parents("tr").find(".js-price-row").val()
			);
			const _grandVal = Currency.remove_rp($(".js-grand-total").val());

			let _total = 0;
			let _grandTotal = 0;
			if (_this.hasClass("js-inc-qty")) {
				_total = parseFloat(_qty) + 1;
				_grandTotal = Currency.format_rp(_grandVal + _price);
			} else {
				// Don't allow decrementing below zero
				_grandTotal = Currency.format_rp(_grandVal - _price);
				if (_qty > 1) {
					_total = parseFloat(_qty) - 1;
				} else {
					_total = 1;
				}
			}

			const _subTotal = Currency.format_rp(_price * _total);
			// subtotal & qty
			_this.parents("tr").find(".js-qty-row").val(_total);
			_this.parents("tr").find(".js-total-row").val(_subTotal);
			// grand total
			$(".js-grand-total").val(_grandTotal);
		});

		// handle delete row
		$("body").on("click", ".js-delete-row", (e) => {
			const _this = $(e.currentTarget);
			const _total = Currency.remove_rp(
				_this.parents("tr").find(".js-total-row").val()
			);
			const _grandTotal = Currency.remove_rp($(".js-grand-total").val());

			_this.parents("tr").remove();
			$(".js-grand-total").val(Currency.format_rp(_grandTotal - _total));

			if ($(".js-medical-stock-out tr").length == 0) {
				handleEmptyRow();
			}
		});

		// handle set total by input qty
		$("body").on("input", ".js-qty-row", (e) => {
			const _this = $(e.currentTarget);
			const _parent = _this.parents("tr");
			const _qty = _this.val();
			const _price = Currency.remove_rp(_parent.find(".js-price-row").val());

			if (_qty > 0) {
				const _subTotal = Currency.format_rp(_price * _qty);
				_parent.find(".js-total-row").val(_subTotal);
				_parent.find(".js-total-row").attr("value", _subTotal);
				handleSetGrandTotal();
			}
		});
	};

	// handleSetGrandTotal
	const handleSetGrandTotal = () => {
		let _grandTotal = 0;
		if ($(".js-medical-stock-out").find("tr").length > 0) {
			$(".js-medical-stock-out")
				.find("tr")
				.each((i, e) => {
					const _this = $(e);
					let _qty = parseInt(_this.find(".js-qty-row").val());
					let _price = Currency.remove_rp(_this.find(".js-price-row").val());
					let _subTotal = _price * _qty;

					_grandTotal += _subTotal;
				});
		}

		$(".js-grand-total").val(Currency.format_rp(_grandTotal));
	};

	// init
	const init = () => {
		if ($(".js-data-stock-out").length) {
			handleRunDataTable();
			handleDeleteData();
			handleGetFormData();
			handleSelectMedicine();
		}
		if ($(".js-form-stock-out").length) {
			handleRunValidation();
			handleClickValidation();
			handleEmptyInput();
		}
		if ($(".js-get-product input").length) {
			handleGetMedicine();
		}
	};

	return {
		init,
	};
})();

export default StockOut;
