/* ------------------------------------------------------------------------------
@name: Header
@description: Header
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL, WEB_URL, IMG_URL } from "variables";

// --- utilities
import { Session, Scrolllable, HttpRequest, SweetAlert } from "utilities";

// --- Header
const Header = (() => {
	const _userData = JSON.parse(Session.get("userData"));

	// -- handleCheckSession
	const handleCheckSession = () => {
		Session.timeout(() => {
			Session.remove("userData");
			location.reload();
		}, 3600);
	};

	// -- handleCheckLogin
	const handleCheckLogin = () => {
		if (_userData) {
			const photo =
				_userData.photo !== null ? _userData.photo : IMG_URL + "default.jpg";
			$(".user-profile__img").attr({
				src: photo,
				alt: _userData.fullName,
			});
		} else {
			if ($(".auth").length == 0) {
				location.href = WEB_URL.login;
			}
		}
	};

	// -- handleLogout
	const handleLogout = () => {
		$(".js-logout").on("click", (e) => {
			e.preventDefault();
			const data = {
				url: API_URL.logout,
				method: "POST",
			};

			let response = HttpRequest.custom(data);
			if (response.code === 200) {
				Session.remove("userData");
				location.href = WEB_URL.login;
			} else {
				SweetAlert.config(response.message, "error");
			}
		});
	};

	// --- handle mobile menu
	const handleMobileMenu = () => {
		$(".js-mobile-menu").on("click", (e) => {
			if ($("body").hasClass("hide-menu")) {
				Scrolllable.enable();
				$("body").removeClass("hide-menu");
				if ($(window).width() <= 1200) {
					$("#navigation").slideUp(300);
				}
			} else {
				Scrolllable.disable();
				$("body").addClass("hide-menu");
				if ($(window).width() <= 1200) {
					$("#navigation").slideDown(300);
				}
			}
		});
	};

	// --- init
	const init = () => {
		handleCheckLogin();
		handleCheckSession();
		handleLogout();
		handleMobileMenu();
	};

	// --- return
	return {
		init,
	};
})();

export default Header;
