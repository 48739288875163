/* ------------------------------------------------------------------------------
@name: ReportExam
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";

// --- utilities
import { DataTable, FormatDate } from "utilities";

const ReportExam = (() => {
	// Handle Run DataTable
	const handleRunDataTableMedicalRecord = () => {
		const dataSetting = {
			selector: "js-report-medical-record",
			url: API_URL.patientGet,
			method: "POST",
		};

		const columnSetting = [
			{
				targets: [0, 7],
				className: "text-center",
			},
			{
				targets: 1,
				render: (data) => {
					return `<p class="mb-0">${data.name}</p><p class="mb-0 text-muted">${data.id}</p>`;
				},
			},
			{
				targets: 2,
				render: (data) => {
					return `<p class="mb-0">${data.gender}</p><p class="mb-0 text-muted">${data.age}</p>`;
				},
			},
			{
				targets: 3,
				render: (data) => {
					return `<p class="mb-0">${data.nik}</p><p class="mb-0 text-muted">${data.bpjs}</p>`;
				},
			},
			{
				targets: 7,
				render: (data) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Edit" class="btn btn-icon waves-effect btn-primary btn-trans js-medical-record" data-id="${data.noMedicalRecord}"><i class="mdi mdi-eye"></i></button>`;
				},
			},
		];

		const columnVisbleSetting = {
			target: [5],
			visble: true,
		};

		DataTable.default(dataSetting, columnSetting, columnVisbleSetting);
	};

	const handleFilterDate = () => {
		$("body").on("change", ".js-filter-date", () => {
			handleGetDataSummary();
		});
	};

	const handleGetDataSummary = () => {
		const _date = $("#dateRange").val();
		const _startDate = FormatDate.range(_date).startDate;
		const _endDate = FormatDate.range(_date).endDate;
		$.ajax({
			url: API_URL.dashboardGet,
			method: "POST",
			dataType: "JSON",
			data: {
				startDate: _startDate,
				endDate: _endDate,
			},
			beforeSend: function () {
				$(".js-daily-visits-summary").find(".card-box").addClass("skeleton");
			},
			success: (response) => {
				const _data = response.data;
				const _summary = _data.summary;

				$(".js-total-registred").text(
					_summary.totalRegistred ? _summary.totalRegistred : 0
				);
				$(".js-total-men").text(_summary.totalMen ? _summary.totalMen : 0);
				$(".js-total-women").text(
					_summary.totalWomen ? _summary.totalWomen : 0
				);

				$(".js-daily-visits-summary").find(".card-box").removeClass("skeleton");
			},
		});
	};

	// Handle Run DataTableDailyVisit
	const handleRunDataTableDailyVisit = () => {
		const dataSetting = {
			selector: "js-report-daily-visit",
			url: API_URL.registrationGet,
			method: "POST",
			data: (d) => {
				const _date = $("#dateRange").val();
				const _startDate = FormatDate.range(_date).startDate;
				const _endDate = FormatDate.range(_date).endDate;
				const _gender = $("#gender").val();
				const _destination = $("#destination").val();
				return $.extend({}, d, {
					startDate: _startDate,
					endDate: _endDate,
					gender: _gender,
					destination: _destination,
				});
			},
		};

		const filterSetting = [
			{
				id: "dateRange",
				event: "change",
			},
			{
				id: "gender",
				event: "change",
			},
			{
				id: "destination",
				event: "change",
			},
			{
				id: "search",
				event: "keyup",
			},
		];

		const columnSetting = [
			{
				targets: [0],
				className: "text-center",
			},
			{
				targets: 1,
				render: (data) => {
					return `<p class="mb-0">${data.id}</p><p class="mb-0 text-muted">${data.time}</p>`;
				},
			},
			{
				targets: 2,
				render: (data) => {
					return `<p class="mb-0">${data.name}</p><p class="mb-0 text-muted">${data.id}</p>`;
				},
			},
			{
				targets: 3,
				render: (data) => {
					return `<p class="mb-0">${data.gender}</p><p class="mb-0 text-muted">${data.birth}</p>`;
				},
			},
		];

		DataTable.custom(dataSetting, filterSetting, columnSetting);
	};

	const handleGetFaskes = () => {
		$.ajax({
			url: API_URL.profileFaskes,
			method: "POST",
			dataType: "JSON",
			success: (response) => {
				const _data = response.data;
				$(".js-faskes-city").text(`PEMERINTAH KABUPATEN ${_data.cityName}`);
				$(".js-faskes-name").text(_data.organizationName);
				$(".js-faskes-address").text(
					_data.address + " Kode Pos " + _data.postalCode
				);
				$(".js-print-date").text(
					_data.cityName + ", " + $(".js-print-date").attr("data-date")
				);
				$(".js-faskes-contact").text(
					"Telp" + " " + _data.phone + " Email : " + _data.email
				);
				$(".js-logo-faskes").attr("src", _data.logoFaskes);
				$(".js-logo-city").attr("src", _data.logoCity);
			},
		});
	};

	// init
	const init = () => {
		if ($(".js-report-medical-record").length) {
			handleRunDataTableMedicalRecord();
			handleGetFaskes();
		}
		if ($(".js-report-daily-visit").length) {
			handleRunDataTableDailyVisit();
			handleGetFaskes();
		}
		handleFilterDate();
	};

	return {
		init,
	};
})();

export default ReportExam;
