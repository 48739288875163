/* ------------------------------------------------------------------------------
@name: Laboratorium
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL, WHITESPACE } from "variables";

// --- utilities
import { DataTable, Form, HttpRequest, SweetAlert } from "utilities";

// Form Validation
const ElementSelector = [
	{
		id: "medicineId",
	},
	{
		id: "name",
		validation: {
			required: true,
		},
	},
	{
		id: "sellPrice",
		validation: {
			required: true,
		},
	},
	{
		id: "stock",
		validation: {
			required: true,
		},
	},
	{
		id: "unit",
		validation: {
			required: true,
		},
	},
];

const ElementEvents = ["input", "blur"];

const Laboratorium = (() => {
	// Handle Run DataTable
	const handleRunDataTable = () => {
		const dataSetting = {
			selector: "js-data-laboratorium",
			url: API_URL.labGet,
			method: "POST",
		};

		const columnSetting = [
			{
				targets: [0, 2, 3],
				className: "text-center",
			},
			{
				targets: 1,
				render: (data) => {
					return `<p class="mb-0">${data.name}</p><p class="mb-0 text-custom">${data.price}</p>`;
				},
			},
			{
				targets: 3,
				render: (dataSetting) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Detail" class="btn btn-icon waves-effect btn-purple btn-trans js-detail-data" data-id="${dataSetting}"><i class="mdi mdi-clipboard-text-outline"></i></button>
					<button type="button" data-toggle="tooltip" data-placement="left" title="Edit" class="btn btn-icon waves-effect btn-primary btn-trans js-edit-data" data-id="${dataSetting}"><i class="mdi mdi-pencil-outline"></i></button>
					<button type="button" data-toggle="tooltip" data-placement="left" title="Delete" class="btn btn-icon waves-effect btn-danger btn-trans js-delete-data" data-id="${dataSetting}"><i class="mdi mdi-trash-can-outline"></i></button>`;
				},
			},
		];

		DataTable.default(dataSetting, columnSetting);
	};

	// Handle Run Validation
	const handleRunValidation = () => {
		Form.validation(ElementEvents, ElementSelector);
	};

	// Handle Click Validation
	const handleClickValidation = () => {
		$('.js-form-laboratorium button[type="submit"]').on("click", (e) => {
			$.each(ElementSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".error").length === 0) {
				handlePostData();
			}
			e.preventDefault();
		});
	};

	// handleCloseModal
	const handleCloseModal = () => {
		$('.js-form-laboratorium button[data-confirm-close="modal"]').on(
			"click",
			(e) => {
				swal({
					title: "Apakah Anda yakin?",
					text: "Tindakan ini tidak dapat diurungkan!",
					type: "warning",
					showCancelButton: true,
					confirmButtonText: "Ya",
					cancelButtonText: "Batal",
					confirmButtonClass: "btn btn-success",
					cancelButtonClass: "btn btn-danger m-l-10",
					buttonsStyling: false,
				}).then(
					() => {
						// Run Empty data
						handleEmptyFormData();
						// hide modal
						$(".modal").modal("hide");
					},
					(dismiss) => {
						if (dismiss === "cancel") {
							swal("Batal", "Data Anda aman :)", "error");
						}
					}
				);
			}
		);
	};

	const handleEmptyFormData = () => {
		Form.emptyData(ElementSelector);
		$(".js-lab-category-tabs").html("");
		$(".js-lab-category-content").html("");
		$(".tab-footer").addClass("d-none");
		$(".tab-empty").show();
	};

	// handlePostData
	const handlePostData = () => {
		let _labList = [];
		let _category = [];
		let formData = new FormData();
		formData = Form.dataColletion(ElementSelector);

		$(".js-lab-category-tabs")
			.find(".nav-item")
			.each((i, e) => {
				const _this = $(e);
				_category[i] = _this.find(".nav-link").attr("href");
			});

		$.each(_category, (idx, val) => {
			let _labData = [];
			const _type = $(".js-lab-category-content").find(val).attr("data-type");
			const _category = $(".js-lab-category-content")
				.find(val)
				.attr("data-category");

			$(".js-lab-category-content")
				.find(val)
				.find(".js-list-lab tr")
				.each((i, e) => {
					const _this = $(e),
						_name = _this.find(".js-lab-name").val(),
						_min = _this.find(".js-lab-min").val(),
						_max = _this.find(".js-lab-max").val(),
						_normal = _this.find(".js-lab-normal").val(),
						_unit = _this.find(".js-lab-unit").val(),
						_note = _this.find(".js-lab-note").val();

					if (!WHITESPACE.test(_name)) {
						_labData[i] = {
							type: _type,
							category: _category,
							name: _name,
							min: _min,
							max: _max,
							normal: _normal,
							unit: _unit,
							note: _note,
						};
					}
				});
			_labList[idx] = _labData;
		});

		formData.append("labList", JSON.stringify(_labList));

		let apiUrl = API_URL.labAdd;
		if ($("#medicineId").val().length !== 0) {
			apiUrl = API_URL.labEdit;
		}

		const _data = {
			url: apiUrl,
			method: "POST",
			data: formData,
		};

		HttpRequest.default(_data);
		handleEmptyFormData();
	};

	// handleDeleteData
	const handleDeleteData = () => {
		$("body").on("click", ".js-delete-data", (e) => {
			let formData = new FormData();
			const _this = $(e.currentTarget);
			const medicineId = _this.attr("data-id");
			formData.append("medicineId", medicineId);

			const data = {
				url: API_URL.labDelete,
				method: "POST",
				data: formData,
			};

			Form.deleteData(data);
			$(".table-laboratorium__no-data").show();
		});
	};

	const handleAddDetailExamination = () => {
		$("body").on("click", ".js-add-detail-examination", () => {
			const type = $("#type").val();
			const category = $("#category").val();
			const typeName = $("#type option:selected").text();
			const categoryName =
				typeName + " " + $("#category option:selected").text();
			const tabId = "tab" + categoryName.replace(" ", "").replace("-", "");

			const data = {
				type: type,
				category: category,
				name: categoryName,
				tabId: tabId,
			};

			if ($(`#${tabId}`).length === 0) {
				handleTabbing(data);
			} else {
				SweetAlert.config("Data sudah ditambahkan!", "warning");
			}

			const contentLength = $(".js-lab-category-content").find(
				".tab-pane"
			).length;

			// handle empty data
			if (contentLength > 0) {
				$(".tab-footer").removeClass("d-none");
				$(".tab-empty").hide();
			} else {
				$(".tab-footer").addClass("d-none");
				$(".tab-empty").show();
			}

			if (contentLength > 1) {
				$(".js-modal-copy-data").removeClass("d-none");
			}
		});
	};

	// handleTabbing
	const handleTabbing = (data) => {
		const tabNav = $(".js-lab-category-tabs");
		const tabContent = $(".js-lab-category-content");

		tabNav.find(".nav-link").removeClass("active");
		tabContent.find(".tab-pane").removeClass("active show");

		const categoryTab = `<li class="nav-item">
													<a href="#${data.tabId}" data-toggle="tab" class="nav-link active">${data.name}
													</a>
													<button class="tab-close-button js-close-tab"><i class="mdi mdi-close"></i></button>
												</li>`;

		const categoryContent = `<div class="tab-pane m-t-10 fade active show" id="${data.tabId}" data-type="${data.type}" data-category="${data.category}">
												<table class="table table-borderless">
													<thead>
														<tr>
															<th>Jenis Pemeriksaan</th>
															<th width="120">Minimal</th>
															<th width="120">Maksimal</th>
															<th>Nilai Normal</th>
															<th width="120">Satuan</th>
															<th>Keterangan</th>
														</tr>
													</thead>
													<tbody class="js-list-lab">
														<tr>
															<td>
																<input type="text" class="form-control form-control__custom js-lab-name" value="" />
															</td>
															<td>
																<input type="text" class="form-control form-control__custom js-lab-min" value="" />
															</td>
															<td>
																<input type="text" class="form-control form-control__custom js-lab-max" value="" />
															</td>
															<td>
																<input type="text" class="form-control form-control__custom js-lab-normal" value="" />
															</td>
															<td>
																<input type="text" class="form-control form-control__custom js-lab-unit" value="" />
															</td>
															<td>
																<input type="text" class="form-control form-control__custom js-lab-note" value="" />
															</td>
															<td>
																<button type="button" class="btn btn-icon btn-danger btn-trans js-delete-row" data-id="1"><i class="mdi mdi-trash-can-outline"></i></button>
															</td>
														</tr>
													</tbody>
												</table>
											</div>`;

		tabNav.append(categoryTab);
		tabContent.append(categoryContent);
	};

	// handleCloseTab
	const handleCloseTab = () => {
		$("body").on("click", ".js-close-tab", (e) => {
			e.preventDefault();
			const _this = $(e.currentTarget);
			const _parent = _this.parents(".nav-item");
			const _id = _parent.find(".nav-link").attr("href").replace("#", "");
			const _tabNav = $(".js-lab-category-tabs");
			const _tabContent = $(".js-lab-category-content");
			const _prevEl = _parent.prev();
			const _nextEl = _parent.next();
			const _prevElId = _prevEl.find(".nav-link").attr("href");
			const _nextElId = _nextEl.find(".nav-link").attr("href");

			_tabNav.find(".nav-link").removeClass("active");
			_tabContent.find(".tab-pane").removeClass("active show");

			// handle change tab after remove
			if (_prevEl.length !== 0 && _nextEl === 0) {
				_prevEl.find(".nav-link").addClass("active");
				_tabContent.find(`${_prevElId}`).addClass("active show");
			} else if (_prevEl.length === 0 && _nextEl !== 0) {
				_nextEl.find(".nav-link").addClass("active");
				_tabContent.find(_nextElId).addClass("active show");
			} else {
				_prevEl.find(".nav-link").addClass("active");
				_tabContent.find(`${_prevElId}`).addClass("active show");
			}

			// remove tab content
			_tabContent.find(`#${_id}`).remove();
			// remove navigasi tab
			_parent.remove();

			// handle empty content
			const contentLength = _tabContent.find(".tab-pane").length;
			if (contentLength === 0) {
				$(".tab-footer").addClass("d-none");
				$(".tab-empty").show();
			}

			// handle show copy button
			if (contentLength > 1) {
				$(".js-modal-copy-data").removeClass("d-none");
			} else {
				$(".js-modal-copy-data").addClass("d-none");
			}
		});
	};

	// handle set value input detail laboratorium
	const handleSetValue = () => {
		const elClass = [
			".js-lab-name",
			".js-lab-min",
			".js-lab-max",
			".js-lab-normal",
			".js-lab-unit",
			".js-lab-note",
		];

		$.each(elClass, (i, v) => {
			$("body").on("input", v, (e) => {
				const _this = $(e.currentTarget);
				const _val = _this.val();
				_this.attr("value", _val);
			});
		});
	};

	// handleShowModalCopyData
	const handleShowModalCopyData = () => {
		$(".js-modal-copy-data").on("click", () => {
			const dataNav = $(".js-lab-category-tabs").find(".nav-link");
			let listCategory = "";

			$(dataNav).each(function () {
				const id = $(this).attr("href");
				const text = $(this).text();
				const active = $(this).hasClass("active");

				if (!active) {
					listCategory += `<div class="modal-item">
														<button type="button" class="modal-link" data-id="${id}">${text}</button>
													</div>`;
				}
			});

			$(".js-lab-category-list").html(listCategory);
			$("#modalLaboratoriumCategory").modal("show");
		});
	};

	// handleCopyDataLab
	const handleCopyDataLab = () => {
		$("body").on("click", ".js-lab-category-list .modal-link", (e) => {
			const _this = $(e.currentTarget);
			const id = _this.attr("data-id");
			const content = $(id).find(".js-list-lab").html();
			const idActive = $(".nav-link.active").attr("href");

			$(idActive).find(".js-list-lab").html(content);
			$("#modalLaboratoriumCategory").modal("hide");
		});
	};

	// handleAddRow
	const handleAddRow = () => {
		$(".js-add-row").on("click", () => {
			let _number = $(".tab-pane.active.show").find(".js-list-lab tr").length;
			let _content = `<tr>
												<td>
													<input type="text" class="form-control form-control__custom js-lab-name" />
												</td>
												<td>
													<input type="text" class="form-control form-control__custom js-lab-min" />
												</td>
												<td>
													<input type="text" class="form-control form-control__custom js-lab-max" />
												</td>
												<td>
													<input type="text" class="form-control form-control__custom js-lab-normal" />
												</td>
												<td>
													<input type="text" class="form-control form-control__custom js-lab-unit" />
												</td>
												<td>
													<input type="text" class="form-control form-control__custom js-lab-note" />
												</td>
												<td>
													<button type="button" class="btn btn-icon btn-danger btn-trans js-delete-row" data-id="${
														_number !== 0 ? _number + 1 : "1"
													}"><i class="mdi mdi-trash-can-outline"></i></button>
												</td>
											</tr>`;

			$(".tab-pane.active.show").find(".js-list-lab").append(_content);
		});
	};

	// handleDeleteRow
	const handleDeleteRow = () => {
		$("body").on("click", ".js-delete-row", (e) => {
			const _this = $(e.currentTarget);
			if ($(".tab-pane.active.show").find(".js-list-lab tr").length > 1) {
				_this.parents("tr").remove();
			}
		});
	};

	// handleNormalValue
	const handleNormalValue = () => {
		$("body").on("input", ".js-lab-min", (e) => {
			const _this = $(e.currentTarget);
			const _val = _this.val();
			const _input = _this.parents("tr").find(".js-lab-normal");

			_input.val(_val);
			_input.attr("value", _val);
		});

		$("body").on("input", ".js-lab-max", (e) => {
			const _this = $(e.currentTarget);
			const _max = _this.val();
			const _min = _this.parents("tr").find(".js-lab-min").val();
			const _val = _min + " - " + _max;
			const _input = _this.parents("tr").find(".js-lab-normal");

			_input.val(_val);
			_input.attr("value", _val);
		});
	};

	// handleEditFormData
	const handleEditFormData = () => {
		$("body").on("click", ".js-edit-data", (e) => {
			let formData = new FormData();
			const _this = $(e.currentTarget);
			const medicineId = _this.attr("data-id");
			const modalShow = "#modalLaboratorium";
			formData.append("medicineId", medicineId);

			const data = {
				url: API_URL.labGet,
				method: "POST",
				data: formData,
				elementSelector: ElementSelector,
			};

			const response = Form.getFormData(data, modalShow);
			const listCategory = response.data.category;

			const tabNav = $(".js-lab-category-tabs");
			const tabContent = $(".js-lab-category-content");
			let _categoryTab = "";
			let _categoryContent = "";

			$.each(listCategory, (i, v) => {
				const tabId = `tab${v.name.replace(" ", "").replace("-", "")}`;
				const activeClassNav = i === 0 ? "active" : "";
				const activeClassContent = i === 0 ? "active show" : "";
				let _detailInputContent = "";

				_categoryTab += `<li class="nav-item">
													<a href="#${tabId}" data-toggle="tab" class="nav-link ${activeClassNav}">${v.name}
													</a>
													<button class="tab-close-button js-close-tab"><i class="mdi mdi-close"></i></button>
												</li>`;

				$.each(v.detail, (iDet, vDet) => {
					_detailInputContent += `<tr>
																	<td>
																		<input type="text" class="form-control form-control__custom js-lab-name" value="${vDet.name}" />
																	</td>
																	<td>
																		<input type="text" class="form-control form-control__custom js-lab-min" value="${vDet.min}" />
																	</td>
																	<td>
																		<input type="text" class="form-control form-control__custom js-lab-max" value="${vDet.max}" />
																	</td>
																	<td>
																		<input type="text" class="form-control form-control__custom js-lab-normal" value="${vDet.normal}" />
																	</td>
																	<td>
																		<input type="text" class="form-control form-control__custom js-lab-unit" value="${vDet.unit}" />
																	</td>
																	<td>
																		<input type="text" class="form-control form-control__custom js-lab-note" value="${vDet.note}" />
																	</td>
																	<td>
																		<button type="button" class="btn btn-icon btn-danger btn-trans js-delete-row" data-id="${vDet.laboratoriumId}"><i class="mdi mdi-trash-can-outline"></i></button>
																	</td>
																</tr>`;
				});

				_categoryContent += `<div class="tab-pane m-t-10 fade ${activeClassContent}" id="${tabId}" data-type="${v.type}" data-category="${v.category}">
												<table class="table table-borderless">
													<thead>
														<tr>
															<th>Jenis Pemeriksaan</th>
															<th width="120">Minimal</th>
															<th width="120">Maksimal</th>
															<th>Nilai Normal</th>
															<th width="120">Satuan</th>
															<th>Keterangan</th>
														</tr>
													</thead>
													<tbody class="js-list-lab">
														${_detailInputContent}
													</tbody>
												</table>
											</div>`;
			});

			tabNav.html(_categoryTab);
			tabContent.html(_categoryContent);
			const contentLength = tabContent.find(".tab-pane").length;

			$(".tab-footer").removeClass("d-none");
			$(".tab-empty").hide();
			if (contentLength > 1) {
				$(".js-modal-copy-data").removeClass("d-none");
			}
		});
	};

	// handleGetDetail
	const handleGetDetail = () => {
		$("body").on("click", ".js-detail-data", (e) => {
			const _this = $(e.currentTarget);
			const medicineId = _this.attr("data-id");
			let formData = new FormData();
			formData.append("medicineId", medicineId);

			const data = {
				url: API_URL.labGet,
				method: "POST",
				data: formData,
			};

			const response = HttpRequest.custom(data);
			const code = response.code;
			const category = response.data.category;

			if (code === 200) {
				if (category !== undefined) {
					$(".table-laboratorium__no-data").hide();
					let _contentCategory = `<option>Pilih Pemeriksaan</option>`;
					let _contentDetail = "";

					$.each(category, (i, v) => {
						_contentCategory += `<option data-type="${v.type}" data-category="${v.category}">${v.name}</option>`;
						// set detail
						$.each(v.detail, (iDet, vDet) => {
							_contentDetail += `<tr>
																	<td>${vDet.name}</td>
																	<td class="text-center">${vDet.normal}</td>
																	<td class="text-center">${vDet.unit}</td>
																	<td>${vDet.note}</td>
																</tr>`;
						});
					});

					$(".js-list-category").html(_contentCategory);
					$(".js-lab-detail tr").remove();
					$(".js-lab-detail").html(_contentDetail);
					handleSelectCategory(category);
				} else {
					const _content = `<tr>
															<td class="text-center" colspan="4">Data tidak ditemukan!</td>
														</tr>`;
					$(".js-lab-detail").html(_content);
				}
			} else {
				$(".table-laboratorium__no-data").show();
			}
		});
	};

	const handleSelectCategory = (jsonData) => {
		$(".js-list-category").on("change", (e) => {
			const _this = $(e.currentTarget);
			const _type = _this.find(":selected").attr("data-type");
			const _category = _this.find(":selected").attr("data-category");

			var _allData = [];
			$.each(jsonData, (i, v) => {
				$.each(v.detail, (iDet, vDet) => {
					_allData.push(vDet);
				});
			});

			const _filterData = _allData.filter(
				(a) => a.type == _type && a.category == _category
			);

			let _contentData =
				_type !== undefined && _category !== undefined ? _filterData : _allData;

			let _content = "";
			$.each(_contentData, (i, v) => {
				_content += `<tr>
											<td>${v.name}</td>
											<td class="text-center">${v.normal}</td>
											<td class="text-center">${v.unit}</td>
											<td>${v.note}</td>
										</tr>`;
			});

			$(".js-lab-detail").html(_content);
		});
	};

	// handleCloseDetail
	const handleCloseDetail = () => {
		$(".js-close-detail").on("click", () => {
			$(".table-laboratorium__no-data").show();
			$("#categoryLab").val("");
			$(".js-title-lab span").text("");
		});
	};

	// init
	const init = () => {
		if ($(".js-data-laboratorium").length) {
			handleRunDataTable();
			handleDeleteData();
			handleGetDetail();
			handleCloseDetail();
			handleEditFormData();
		}
		if ($(".js-form-laboratorium").length) {
			handleRunValidation();
			handleClickValidation();
			handleCloseModal();
			handleAddDetailExamination();
			handleCloseTab();
			handleSetValue();
		}
		if ($(".js-lab-category-list").length) {
			handleCopyDataLab();
		}
		if ($(".js-list-category").length) {
			handleSelectCategory();
		}
		if ($(".js-modal-copy-data").length) {
			handleShowModalCopyData();
		}
		if ($(".js-add-row").length) {
			handleAddRow();
			handleDeleteRow();
			handleNormalValue();
		}
	};

	return {
		init,
	};
})();

export default Laboratorium;
