/* ------------------------------------------------------------------------------
@name: SSEncounter

@description: SSEncounter
 Activate
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";

// --- utilities
import { DataTable, FormatDate, SweetAlert } from "utilities";

const SSEncounter = (() => {
	// Handle Run DataTable
	const handleRunDataTable = () => {
		const dataSetting = {
			selector: "js-ss-encounter",
			url: API_URL.ssEncounter,
			method: "POST",
			data: (d) => {
				const _date = $("#dateRange").val();
				const _startDate = FormatDate.range(_date).startDate;
				const _endDate = FormatDate.range(_date).endDate;
				return $.extend({}, d, {
					startDate: _startDate,
					endDate: _endDate,
				});
			},
		};

		const filterSetting = [
			{
				id: "dateRange",
				event: "change",
			},
			{
				id: "search",
				event: "keyup",
			},
		];

		const columnSetting = [
			{
				targets: [0, 5],
				className: "text-center",
			},
			{
				targets: 1,
				render: (data) => {
					return `<p class="mb-0">${data.date}</p><p class="mb-0 text-muted">${data.time}</p>`;
				},
			},
			{
				targets: 2,
				render: (data) => {
					return `<p class="mb-0">${data.name}</p><p class="mb-0 text-muted">${data.id}</p>`;
				},
			},
			{
				targets: 3,
				render: (data) => {
					return `<p class="mb-0">${data.name}</p><p class="mb-0 text-muted">${data.polyclinic}</p>`;
				},
			},
			{
				targets: 5,
				render: (examinationId) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Sync" class="btn btn-icon waves-effect btn-primary btn-trans js-sync-encounter" data-id="${examinationId}"><i class="mdi mdi-sync"></i></button>`;
				},
			},
		];

		const sortSetting = {
			id: "sort",
			event: "change",
		};

		DataTable.custom(dataSetting, filterSetting, columnSetting, sortSetting);
	};

	const syncEncounter = () => {
		$("body").on("click", ".js-sync-encounter", (e) => {
			swal({
				title: "Apakah Anda yakin?",
				text: "Pastikan data yang dikirim benar!",
				type: "warning",
				showCancelButton: true,
				confirmButtonText: "Ya, Kirim",
				cancelButtonText: "Batal",
				confirmButtonClass: "btn btn-success",
				cancelButtonClass: "btn btn-danger m-l-10",
				buttonsStyling: false,
			}).then(
				() => {
					const _this = $(e.currentTarget);
					const registrationCode = _this.attr("data-id");

					$.ajax({
						url: API_URL.ssEncounterSync,
						method: "POST",
						dataType: "JSON",
						data: {
							registrationCode: registrationCode,
						},
						success: (response) => {
							if (response.code === 200) {
								SweetAlert.config(response.message, response.status);
							} else {
								if (response.message["resourceType"] !== "OperationOutcome") {
									SweetAlert.config(response.message, "error");
								} else {
									SweetAlert.config(
										response.message["issue"][0].details.text,
										"error"
									);
								}
							}
						},
					});
				},
				(dismiss) => {
					if (dismiss === "cancel") {
						swal("Batal", "Data Anda tidak akan dikirm :)", "error");
					}
				}
			);
		});
	};

	// init
	const init = () => {
		if ($(".js-ss-encounter").length || $(".js-sync-encounter").length) {
			handleRunDataTable();
			syncEncounter();
		}
	};

	return {
		init,
	};
})();

export default SSEncounter;
