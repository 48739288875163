/* ------------------------------------------------------------------------------
@name: Format Rupiah
@description: Format Rupiah
--------------------------------------------------------------------------------- */

const Currency = (() => {
	// --- idr_format
	const idr_format = (number, prefix = "Rp ", isMinus = false) => {
		let _number_string = number.toString().replace(/[^,\d]/g, ""),
			_split = _number_string.split(","),
			_mod = _split[0].length % 3,
			_idr = _split[0].substr(0, _mod),
			_thousands = _split[0].substr(_mod).match(/\d{3}/gi),
			_separator = "",
			_isMinus = String(number)[0],
			_result;

		// if thousands
		if (_thousands) {
			_separator = _mod ? "." : "";
			_idr += _separator + _thousands.join(".");
		}

		if (isMinus) {
			_idr = _split[1] != undefined ? _idr + "," + _split[1] : _idr;
			if (_isMinus == "-") {
				prefix = "RP -";
			}
		}
		_result = prefix != false ? prefix + _idr : _idr;
		return _result;
	};

	// remove_idr_format
	const remove_idr_format = (idr) => {
		let _result = idr
			.split(".")
			.join("")
			.split(" ")
			.join("")
			.split("Rp")
			.join("");
		return Number(_result);
	};

	return {
		format_rp: idr_format,
		remove_rp: remove_idr_format,
	};
})();

export default Currency;
