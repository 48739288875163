/* ------------------------------------------------------------------------------
@name: API_URL
@description: API_URL
--------------------------------------------------------------------------------- */

const URL_BASE = "https://app.rzfmedis.com/";
const URL_API = "https://rzfmedis.com/api/v1/";

// const URL_BASE = "http://192.168.100.86/rzf-medis/";
// const URL_API = "http://192.168.100.86/rzf-medis-api/v1/";

export const API_URL = {
	/* ---- APP API ---- */
	// -- login
	login: `${URL_BASE}login/process`,
	logout: `${URL_BASE}logout`,
	// -- register
	register: `${URL_BASE}register/process`,
	// -- patient
	patientGet: `${URL_BASE}patient/get_data`,
	patientAdd: `${URL_BASE}patient/add_data`,
	patientEdit: `${URL_BASE}patient/edit_data`,
	patientDelete: `${URL_BASE}patient/delete_data`,
	patientSync: `${URL_BASE}patient/sync_data`,
	patientCode: `${URL_BASE}patient/get_code`,
	// -- medicine
	medicineGet: `${URL_BASE}medicine/get_data`,
	medicineAdd: `${URL_BASE}medicine/add_data`,
	medicineEdit: `${URL_BASE}medicine/edit_data`,
	medicineDelete: `${URL_BASE}medicine/delete_data`,
	medicineCategoryGet: `${URL_BASE}medicine/get_category`,
	medicineCategoryAdd: `${URL_BASE}medicine/add_category`,
	medicineCategoryEdit: `${URL_BASE}medicine/edit_category`,
	medicineCategoryDelete: `${URL_BASE}medicine/delete_category`,
	medicineUnitGet: `${URL_BASE}medicine/get_unit`,
	medicineUnitAdd: `${URL_BASE}medicine/add_unit`,
	medicineUnitEdit: `${URL_BASE}medicine/edit_unit`,
	medicineUnitDelete: `${URL_BASE}medicine/delete_unit`,
	// -- account
	accountGet: `${URL_BASE}account/get_data`,
	accountAdd: `${URL_BASE}account/add_data`,
	accountDelete: `${URL_BASE}account/delete_data`,
	accountEdit: `${URL_BASE}account/edit_data`,
	profileEdit: `${URL_BASE}account/edit_profile`,
	changePassword: `${URL_BASE}account/change_password`,
	profileFaskes: `${URL_BASE}account/get_faskes`,
	profileFaskesAdd: `${URL_BASE}account/add_faskes`,
	profileFaskesEdit: `${URL_BASE}account/edit_faskes`,
	accountLogin: `${URL_BASE}account/get_login_account`,
	// -- assurance
	assuranceGet: `${URL_BASE}assurance/get_data`,
	assuranceAdd: `${URL_BASE}assurance/add_data`,
	assuranceEdit: `${URL_BASE}assurance/edit_data`,
	assuranceDelete: `${URL_BASE}assurance/delete_data`,
	// -- services
	servicesGet: `${URL_BASE}services/get_data`,
	servicesAdd: `${URL_BASE}services/add_data`,
	servicesEdit: `${URL_BASE}services/edit_data`,
	servicesDelete: `${URL_BASE}services/delete_data`,
	// -- polyclinic
	polyclinicGet: `${URL_BASE}polyclinic/get_data`,
	polyclinicAdd: `${URL_BASE}polyclinic/add_data`,
	polyclinicEdit: `${URL_BASE}polyclinic/edit_data`,
	polyclinicDelete: `${URL_BASE}polyclinic/delete_data`,
	// -- medicalPersonel
	medicalPersonelCode: `${URL_BASE}medicalpersonel/get_code`,
	medicalPersonelGet: `${URL_BASE}medicalpersonel/get_data`,
	medicalPersonelAdd: `${URL_BASE}medicalpersonel/add_data`,
	medicalPersonelEdit: `${URL_BASE}medicalpersonel/edit_data`,
	medicalPersonelDelete: `${URL_BASE}medicalpersonel/delete_data`,
	medicalPersonelNik: `${URL_BASE}medicalpersonel/get_nik`,
	// -- Inventory
	medicineInGet: `${URL_BASE}inventory/get_medicine`,
	// stockIn
	stockInGet: `${URL_BASE}inventory/get_stock_in`,
	stockInAdd: `${URL_BASE}inventory/add_stock_in`,
	stockInDelete: `${URL_BASE}inventory/delete_stock_in`,
	stockInEdit: `${URL_BASE}inventory/edit_stock_in`,
	// stockOut
	stockOutGet: `${URL_BASE}inventory/get_stock_out`,
	stockOutAdd: `${URL_BASE}inventory/add_stock_out`,
	stockOutDelete: `${URL_BASE}inventory/delete_stock_out`,
	stockOutEdit: `${URL_BASE}inventory/edit_stock_out`,
	// -- registration
	registrationCode: `${URL_BASE}registration/get_code`,
	registrationGet: `${URL_BASE}registration/get_data`,
	registrationAdd: `${URL_BASE}registration/add_data`,
	registrationDelete: `${URL_BASE}registration/delete_data`,
	registrationEdit: `${URL_BASE}registration/edit_data`,
	// -- examination
	referenceGet: `${URL_BASE}examination/get_reference`,
	referenceAdd: `${URL_BASE}examination/add_reference`,
	referenceDelete: `${URL_BASE}examination/delete_reference`,
	anamnesisGet: `${URL_BASE}examination/get_anamnesis`,
	anamnesisAdd: `${URL_BASE}examination/add_anamnesis`,
	anamnesisDelete: `${URL_BASE}examination/delete_anamnesis`,
	examinationHistory: `${URL_BASE}examination/get_history`,
	examinationGet: `${URL_BASE}examination/get_data`,
	examinationAdd: `${URL_BASE}examination/add_data`,
	examinationDelete: `${URL_BASE}examination/delete_data`,
	examinationEdit: `${URL_BASE}examination/edit_data`,
	labSenderGet: `${URL_BASE}examination/get_lab_sender`,
	labSenderAdd: `${URL_BASE}examination/add_lab_sender`,
	labSenderDelete: `${URL_BASE}examination/delete_lab_sender`,
	// -- Region
	province: `${URL_BASE}region/province`,
	city: `${URL_BASE}region/city`,
	district: `${URL_BASE}region/district`,
	village: `${URL_BASE}region/village`,
	// pharmacy
	pharmacyGet: `${URL_BASE}pharmacy/get_data`,
	pharmacyClinicGet: `${URL_BASE}pharmacy/data_table_transaction`,
	pharmacyAdd: `${URL_BASE}pharmacy/add_data`,
	pharmacyDelete: `${URL_BASE}pharmacy/delete_data`,
	pharmacyEdit: `${URL_BASE}pharmacy/edit_data`,
	totalExam: `${URL_BASE}pharmacy/count_examination`,
	noInvoice: `${URL_BASE}pharmacy/get_code`,
	// receivable
	receivableGet: `${URL_BASE}receivable/get`,
	receivableAdd: `${URL_BASE}receivable/add`,
	// dashboard
	dashboardGet: `${URL_BASE}dashboard/get_data`,
	countDiagnosis: `${URL_BASE}dashboard/count_diagnosis`,
	// laboratorium
	labGet: `${URL_BASE}laboratorium/get_data`,
	labAdd: `${URL_BASE}laboratorium/add_data`,
	labEdit: `${URL_BASE}laboratorium/edit_data`,
	labDelete: `${URL_BASE}laboratorium/delete_data`,
	// financial record
	financialRecordGet: `${URL_BASE}financial-record/get`,
	financialRecordAdd: `${URL_BASE}financial-record/add`,
	financialRecordEdit: `${URL_BASE}financial-record/edit`,
	financialRecordDelete: `${URL_BASE}financial-record/delete`,
	financialCategoryGet: `${URL_BASE}financial-record/category/get`,
	financialCategoryAdd: `${URL_BASE}financial-record/category/add`,
	financialCategoryDelete: `${URL_BASE}financial-record/category/delete`,
	financialRecordCode: `${URL_BASE}financial-record/code`,
	// report
	salesPeriodeGet: `${URL_BASE}sales-periode/get`,
	salesSummaryGet: `${URL_BASE}sales/summary/get`,
	salesPatientGet: `${URL_BASE}sales-patient/get`,
	cashFlowGet: `${URL_BASE}cash-flow/get`,
	cashNetprofitGet: `${URL_BASE}cash-netprofit/get`,
	/* ---- END APP API ---- */

	/* API OUTSIDE */
	diagnosis: `${URL_API}icds`,

	/* ---- local API Satu Sehat ---- */
	// -- credential
	credentialGet: `${URL_BASE}satu-sehat/credential/get`,
	credentialAdd: `${URL_BASE}satu-sehat/credential/add`,
	credentialEdit: `${URL_BASE}satu-sehat/credential/edit`,
	// -- location
	ssLocationlGet: `${URL_BASE}satu-sehat/location/get`,
	ssLocationlGet: `${URL_BASE}satu-sehat/location/get`,
	ssLocationlSync: `${URL_BASE}satu-sehat/location/sync`,
	// -- encounter
	ssEncounter: `${URL_BASE}satu-sehat/encounter/get`,
	ssEncounterSync: `${URL_BASE}satu-sehat/encounter/sync`,
	// -- patient observation
	ssObservationGet: `${URL_BASE}satu-sehat/observation/get`,
	// --- KYC
	ssKYC: `${URL_BASE}satu-sehat/kyc`,

	/* ---- End local API Satu Sehat ---- */
};
