/* ------------------------------------------------------------------------------
@name: SSLocation

@description: SSLocation
 Activate
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";

// --- utilities
import { DataTable, SweetAlert } from "utilities";

const SSLocation = (() => {
	// Handle Run DataTable
	const handleRunDataTable = () => {
		const dataSetting = {
			selector: "js-ss-location",
			url: API_URL.ssLocationlGet,
			method: "POST",
		};

		const columnSetting = [
			{
				targets: [0, 2],
				className: "text-center",
			},
			{
				targets: 2,
				render: (data) => {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Sync" class="btn btn-icon waves-effect btn-primary btn-trans js-sync-location" data-id="${data.id}" data-name="${data.name}"><i class="mdi mdi-sync"></i></button>`;
				},
			},
		];

		DataTable.default(dataSetting, columnSetting);
	};

	const sycnLocation = () => {
		$("body").on("click", ".js-sync-location", (e) => {
			swal({
				title: "Apakah Anda yakin?",
				text: "Pastikan data yang dikirim benar!",
				type: "warning",
				showCancelButton: true,
				confirmButtonText: "Ya, Kirim",
				cancelButtonText: "Batal",
				confirmButtonClass: "btn btn-success",
				cancelButtonClass: "btn btn-danger m-l-10",
				buttonsStyling: false,
			}).then(
				() => {
					const _this = $(e.currentTarget);
					const polyclinicId = _this.attr("data-id");
					const polyclinicName = _this.attr("data-name");

					$.ajax({
						url: API_URL.ssLocationlSync,
						method: "POST",
						dataType: "JSON",
						data: {
							polyclinicId: polyclinicId,
							polyclinicName: polyclinicName,
						},
						success: (response) => {
							if (response.code === 200) {
								SweetAlert.config(response.message, response.status);
							} else {
								if (response.message["resourceType"] !== "OperationOutcome") {
									SweetAlert.config(response.message, "error");
								} else {
									SweetAlert.config(
										response.message["issue"][0].details.text,
										"error"
									);
								}
							}
						},
					});
				},
				(dismiss) => {
					if (dismiss === "cancel") {
						swal("Batal", "Data Anda tidak akan dikirm :)", "error");
					}
				}
			);
		});
	};

	// init
	const init = () => {
		if ($(".js-ss-location").length || $(".js-sync-location").length) {
			handleRunDataTable();
			sycnLocation();
		}
	};

	return {
		init,
	};
})();

export default SSLocation;
