/* ------------------------------------------------------------------------------
@name: Toastr
--------------------------------------------------------------------------------- */

const Toastr = (() => {
	const handleRunToastr = (message, status = "success") => {
		switch (status) {
			case "warning":
				toastr["warning"](message, "Peringatan!");
				break;
			case "error":
				toastr["error"](message, "Gagal!");
				break;
			default:
				toastr["success"](message, "Berhasil!");
				break;
		}

		// setting option
		toastr.options = {
			closeButton: true,
			debug: false,
			newestOnTop: true,
			progressBar: false,
			positionClass: "toast-top-right",
			preventDuplicates: false,
			onclick: null,
			showDuration: "300",
			hideDuration: "300",
			timeOut: "300",
			extendedTimeOut: "300",
			showEasing: "swing",
			hideEasing: "linear",
			showMethod: "fadeIn",
			hideMethod: "fadeOut",
		};
	};

	return {
		config: handleRunToastr,
	};
})();

export default Toastr;
