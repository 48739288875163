/* ------------------------------------------------------------------------------
@name: FormWizard
@description: FormWizard Activate
--------------------------------------------------------------------------------- */

const FormWizard = (() => {
	// Handle Run FormWizard
	const handleRunFormWizard = () => {
		$("#basicwizard").bootstrapWizard({
			tabClass: "nav nav-tabs navtab-wizard nav-justified",
		});
	};

	// init
	const init = () => {
		handleRunFormWizard();
	};

	return {
		init,
	};
})();

export default FormWizard;
