/* ------------------------------------------------------------------------------
@name: SatuSehat

@description: SatuSehat
 Activate
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";
import { SS_API_URL } from "variables";

// --- utilities
import { Form, HttpRequest, Session, SweetAlert } from "utilities";

// Form Validation
const ElementSelector = [
	{
		id: "credentialId",
	},
	{
		id: "organizationId",
		validation: {
			required: true,
		},
	},
	{
		id: "clientKey",
		validation: {
			required: true,
		},
	},
	{
		id: "secretKey",
		validation: {
			required: true,
		},
	},
	{
		id: "agentNik",
		validation: {
			required: true,
			minimum: true,
			minimumChar: 16,
		},
	},
];

const ElementEvents = ["input", "blur"];

const SatuSehat = (() => {
	// Handle Run Validation
	const handleRunValidation = () => {
		Form.validation(ElementEvents, ElementSelector);
	};

	// Handle Click Validation
	const handleValidationCredential = () => {
		$('.js-form-cred-satusehat button[type="submit"]').on("click", (e) => {
			e.preventDefault();
			$.each(ElementSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".js-form-cred-satusehat .error").length === 0) {
				handlePostData();
			}
		});
	};

	// handleEmptyInput
	const handleEmptyInput = () => {
		$('button[data-dismiss="modal"]').on("click", (e) => {
			Form.emptyData(ElementSelector);
		});
	};

	const handleClickCredential = () => {
		$(".js-get-cred-satusehat").on("click", (e) => {
			const userData = JSON.parse(Session.get("userData"));
			let sendData = { credentialId: 1 };
			if (userData.branchId !== null) {
				sendData = { branchId: userData.branchId };
			}

			const data = {
				url: API_URL.credentialGet,
				method: "POST",
				data: sendData,
				elementSelector: ElementSelector,
			};

			Form.getFormData(data, "#modalCredential");
		});
	};

	const handleVerificationProfile = () => {
		$(".js-verification-profile").on("click", (e) => {
			$.ajax({
				url: API_URL.profileFaskes,
				method: "POST",
				dataType: "JSON",
				success: (response) => {
					const _data = response.data;
					if (_data.organizationName === null) {
						SweetAlert.config(
							"Silahkan update profil faskes terlebil dahulu!",
							"warning"
						);
					} else {
						$("#modalVerification").modal("show");
						$("#agentName").val(_data.organizationName);
					}
				},
			});
		});
	};

	const handleValidationKYC = () => {
		$('.js-form-kyc button[type="submit"]').on("click", (e) => {
			e.preventDefault();
			$.each(ElementSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".js-form-kyc .error").length === 0) {
				handleSendKYC();
			}
		});
	};

	const handleSendKYC = () => {
		const agentName = $("#agentName").val();
		const agentNik = $("#agentNik").val();

		$.ajax({
			url: API_URL.ssKYC,
			method: "POST",
			dataType: "JSON",
			data: {
				agentName: agentName,
				agentNik: agentNik,
			},
			success: (response) => {
				if (response.code === 200) {
					window.open(response.data, "_blank");
				} else {
					SweetAlert.config(response.message, "warning");
				}
			},
		});
	};

	// handlePostData
	const handlePostData = () => {
		const _parent = $(".js-form-cred-satusehat");
		const _credentialId = _parent.find("#credentialId").val();
		const _organizationId = _parent.find("#organizationId").val();
		const _clientKey = _parent.find("#clientKey").val();
		const _secretKey = _parent.find("#secretKey").val();

		let formData = new FormData();
		formData.append("credentialId", _credentialId);
		formData.append("organizationId", _organizationId);
		formData.append("clientKey", _clientKey);
		formData.append("secretKey", _secretKey);

		let apiUrl = API_URL.credentialAdd;
		if (_credentialId !== null) {
			apiUrl = API_URL.credentialEdit;
		}

		const data = {
			url: apiUrl,
			method: "POST",
			data: formData,
		};

		HttpRequest.default(data);
	};

	// init
	const init = () => {
		if ($(".js-get-cred-satusehat").length) {
			handleClickCredential();
			handleEmptyInput();
		}
		if ($(".js-form-cred-satusehat").length) {
			handleValidationCredential();
			handleRunValidation();
		}
		if ($(".js-verification-profile").length) {
			handleVerificationProfile();
		}
		if ($(".js-form-kyc").length) {
			handleValidationKYC();
			handleRunValidation();
		}
	};

	return {
		init,
	};
})();

export default SatuSehat;
