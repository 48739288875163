/* ------------------------------------------------------------------------------
@name: WEB_URL
@description: WEB_URL
--------------------------------------------------------------------------------- */

const URL_BASE_WEB = "https://app.rzfmedis.com/";

// const URL_BASE_WEB = "http://192.168.100.86/rzf-medis/";

export const WEB_URL = {
	home: `${URL_BASE_WEB}`,
	login: `${URL_BASE_WEB}login`,
	accountLogin: `${URL_BASE_WEB}account/login`,
	dashboard: `${URL_BASE_WEB}dashboard`,
	registration: `${URL_BASE_WEB}registration`,
	medicine: `${URL_BASE_WEB}medicine`,
	pharmacy: `${URL_BASE_WEB}pharmacy`,
	satusehat: `${URL_BASE_WEB}satu-sehat/encounter`,
};

export const IMG_URL = `${URL_BASE_WEB}public/assets/img/dummy/`;
