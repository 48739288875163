/* ------------------------------------------------------------------------------
@name: FullScreen
@description: FullScreen Activate
--------------------------------------------------------------------------------- */

const FullScreen = (() => {
	// Handle Run FullScreen
	const handleRunFullScreen = () => {
		$(".js-btn-fullscreen").on("click", () => {
			const elem = document.body;

			if (
				(document.fullScreenElement !== undefined &&
					document.fullScreenElement === null) ||
				(document.msFullscreenElement !== undefined &&
					document.msFullscreenElement === null) ||
				(document.mozFullScreen !== undefined && !document.mozFullScreen) ||
				(document.webkitIsFullScreen !== undefined &&
					!document.webkitIsFullScreen)
			) {
				if (elem.requestFullScreen) {
					elem.requestFullScreen();
				} else if (elem.mozRequestFullScreen) {
					elem.mozRequestFullScreen();
				} else if (elem.webkitRequestFullScreen) {
					elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
				} else if (elem.msRequestFullscreen) {
					elem.msRequestFullscreen();
				}
			} else {
				if (document.cancelFullScreen) {
					document.cancelFullScreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.webkitCancelFullScreen) {
					document.webkitCancelFullScreen();
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
				}
			}
		});
	};

	// init
	const init = () => {
		handleRunFullScreen();
	};

	return {
		init,
	};
})();

export default FullScreen;
