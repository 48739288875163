/* ------------------------------------------------------------------------------
@name: ReportCashNetprofit
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";

// --- utilities
import { FormatDate, Currency, HttpRequest } from "utilities";

const ReportCashNetprofit = (() => {
	// handleRunTableCashNetprofit
	const handleRunTableCashNetprofit = () => {
		let formData = new FormData();
		const date = $("#dateRange").val();
		const startDate = FormatDate.range(date).startDate;
		const endDate = FormatDate.range(date).endDate;
		formData.append("startDate", startDate);
		formData.append("endDate", endDate);

		const data = {
			url: API_URL.cashNetprofitGet,
			method: "POST",
			data: formData,
		};
		const response = HttpRequest.custom(data);
		const _data = response.data;
		if (response.status) {
			let _contentIncome = "",
				_contentExpense = "",
				_listIncome = _data.revenue,
				_itemIncome = "",
				_footerIncome = "",
				_listExpense = _data.expense,
				_itemExpense = "",
				_footerExpense = "",
				_footerCashTotal = "";

			let _totalExpense = 0;

			$.each(_listIncome, function (i, v) {
				_itemIncome += `<tr>
													<td>${v.note}</td>
													<td class="text-right">${Currency.format_rp(Math.floor(v.total))}</td>
												</tr>`;
			});
			_footerIncome = `<tr class="table-custom__footer">
												<th>Laba Kotor / Gross Profit</th>
												<th class="text-right">${Currency.format_rp(parseInt(_data.grossprofit))}</th>
											</tr>`;

			_contentIncome = `<table class="table table-custom">
													<thead>
														<tr>
															<th>Pendapatan</th>
															<th width="250" class="text-right">Total</th>
														</tr>
													</thead>
													<tbody>
														${_itemIncome}
														${_footerIncome}
													</tbody>
												</table>`;

			$.each(_listExpense, function (i, v) {
				_totalExpense += parseFloat(v.total);
				_itemExpense += `<tr>
													<td>${v.category}</td>
													<td class="text-right">${Currency.format_rp(Math.floor(v.total))}</td>
												</tr>`;
			});

			_footerExpense = `<tr class="table-custom__footer">
													<th>Biaya Operasional</th>
													<th class="text-right">${Currency.format_rp(_totalExpense)}</th>
												</tr>`;

			_contentExpense = `<table class="table table-custom">
													<thead>
														<tr>
															<th>Pengeluaran</th>
															<th class="text-right">Total</th>
														</tr>
													</thead>
													<tbody>
														${_itemExpense}
														${_footerExpense}
													</tbody>
												</table>`;

			_footerCashTotal += `<div class="footer-report">
														<p class="footer-report__label">Laba Bersih / Net Profit</p>
														<p class="footer-report__total">${Currency.format_rp(
															_data.netprofit,
															"Rp ",
															true
														)}</p>
													</div>`;

			$(".card-box").find(".row").show();
			$(".card-box").find(".not-found").hide();
			$(".js-report-cash-netprofit").html(
				_contentIncome + _contentExpense + _footerCashTotal
			);
			$(".card-box").removeClass("skeleton");
		}
	};

	// handleFilterDate
	const handleFilterDate = () => {
		$("body").on("change", ".js-filter-cash", () => {
			handleRunTableCashNetprofit();
		});
	};

	// init
	const init = () => {
		if ($(".js-report-cash-netprofit").length) {
			handleRunTableCashNetprofit();
		}
		if ($(".js-filter-cash").length) {
			handleFilterDate();
		}
	};

	return {
		init,
	};
})();

export default ReportCashNetprofit;
