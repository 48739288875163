/* ------------------------------------------------------------------------------
@name: MedicineCategory
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL } from "variables";

// --- utilities
import { DataTable, Form, HttpRequest } from "utilities";

// Form Validation
const ElementSelector = [
	{
		id: "categoryId",
	},
	{
		id: "category",
		validation: {
			required: true,
		},
	},
	{
		id: "type",
		validation: {
			required: true,
			selectOption: true,
		},
	},
];

const ElementEvents = ["input", "blur"];

const MedicineCategory = (() => {
	// Handle Run DataTable
	const handleRunDataTable = () => {
		const dataSetting = {
			selector: "js-data-medicine-category",
			url: API_URL.medicineCategoryGet,
			method: "POST",
		};

		const columnSetting = [
			{
				targets: 3,
				className: "text-center",
				render: function (dataSetting) {
					return `<button type="button" data-toggle="tooltip" data-placement="left" title="Edit" class="btn btn-icon waves-effect btn-primary btn-trans js-edit-data" data-id="${dataSetting}"><i class="mdi mdi-pencil-outline"></i></button>
					<button type="button" data-toggle="tooltip" data-placement="left" title="Delete" class="btn btn-icon waves-effect btn-danger btn-trans js-delete-data" data-id="${dataSetting}"><i class="mdi mdi-trash-can-outline"></i></button>`;
				},
			},
		];

		DataTable.default(dataSetting, columnSetting);
	};

	// Handle Run Validation
	const handleRunValidation = () => {
		Form.validation(ElementEvents, ElementSelector);
	};

	// Handle Click Validation
	const handleClickValidation = () => {
		$('button[type="submit"]').on("click", (e) => {
			$.each(ElementSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".error").length === 0) {
				handlePostData();
			}
			e.preventDefault();
		});
	};

	// handleEmptyInput
	const handleEmptyInput = () => {
		$('button[data-dismiss="modal"]').on("click", (e) => {
			Form.emptyData(ElementSelector);
		});
	};

	// handlePostData
	const handlePostData = () => {
		let formData = new FormData();
		formData.append("type", "1");
		formData.append("category", $("#category").val());

		let apiUrl = API_URL.medicineCategoryAdd;
		if ($("#categoryId").val().length !== 0) {
			apiUrl = API_URL.medicineCategoryEdit;
			formData.append("categoryId", $("#categoryId").val());
		}

		const data = {
			url: apiUrl,
			method: "POST",
			data: formData,
		};

		HttpRequest.default(data);
		Form.emptyData(ElementSelector);
	};

	const handleGetFormData = () => {
		$("body").on("click", ".js-edit-data", (e) => {
			const formData = new FormData();
			const _this = $(e.currentTarget);
			const categoryId = _this.attr("data-id");
			formData.append("categoryId", categoryId);
			formData.append("type", "1");

			const data = {
				url: API_URL.medicineCategoryGet,
				method: "POST",
				data: formData,
				elementSelector: ElementSelector,
			};

			Form.getFormData(data);
		});
	};

	// handleDeleteData
	const handleDeleteData = () => {
		$("body").on("click", ".js-delete-data", (e) => {
			const formData = new FormData();
			const _this = $(e.currentTarget);
			const categoryId = _this.attr("data-id");
			formData.append("categoryId", categoryId);

			const data = {
				url: API_URL.medicineCategoryDelete,
				method: "POST",
				data: formData,
			};

			Form.deleteData(data);
		});
	};

	// init
	const init = () => {
		if ($(".js-data-medicine-category").length) {
			handleRunDataTable();
			handleDeleteData();
			handleGetFormData();
		}
		if ($(".js-form-medicine-category").length) {
			handleRunValidation();
			handleClickValidation();
			handleEmptyInput();
		}
	};

	return {
		init,
	};
})();

export default MedicineCategory;
